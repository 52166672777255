export abstract class Product {
	readonly code: string;
	readonly name: string;
	readonly basis?: string;

	constructor(code: string, name: string, basis?: string) {
		this.code = code;
		this.name = name;
		this.basis = basis;
	}

	toString() {
		return this.name+' ('+this.code+')';
	}

	abstract get_punit(price: number): number;

	ceil_price(price: number): number {
		const punit = this.get_punit(price);
		return Math.ceil(price / punit) * punit;
	}

	floor_price(price: number): number {
		const punit = this.get_punit(price);
		return Math.floor(price / punit) * punit;
	}

	get sell_tax_rate(): number {
		return 0;
	}

	get margin_tax_rate(): number {
		return 0;
	}

	get ex_dividend_tax_rate(): number {
		return 0.154;
	}

	get dunit(): number {
		return 1;
	}

	abstract get market_code(): string;
}
