import s from '../../xlsx-styles';
import { Workbook, Fill } from 'exceljs';
import { Plan } from '../plans/Plan';
import { PlanSheetsBased } from '../plans/PlanSheetBased';
import { PlanComposite } from '../plans/PlanComposite';

export class XlsxPlanTableSheet {

	static write(wb: Workbook, p: Plan): any {
		const ws = wb.addWorksheet('매매테이블');

		const font = s.font;
		const right = s.right;
		const f = s.fills0;
		const columns: {width: number, style: any}[] = [
		/* A */ { width: 7, style: {font: font, fill: f[0]} },
		/* B */ { width: 8, style: {font: font, fill: f[0]} },
		/* C */ { width: 8, style: {font: font, fill: f[0]} },

		/* D */ { width: 6, style: {font: font, alignment: right, fill: f[1]} },
		/* E */ { width: 8, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
		/* F */ { width: 7, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
		/* G */ { width: 8, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
		/* H */ { width: 7, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },

		/* I */ { width: 8, style: {font: font, numFmt: '+#,##0', alignment: right, fill: f[0]} },
		/* J */ { width: 8, style: {font: font, numFmt: '+#,##0', alignment: right, fill: f[0]} },
		/* K */ { width: 7, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]} },
		];
		columns.forEach((column, idx) => {
			column.style.border = s.border.tb;
			ws.getColumn(idx+1).width = column.width;
			ws.getColumn(idx+1).style = column.style;
		});

		const add_row = function (f: Fill[] | undefined, values: any[]) {
			const row = ws.addRow(values);

			if (f) {
				let i = 1;
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
			}

			return row;
		};

		add_row(s.fills1, [
			'구분','', '',
			'매매테이블', '', '', '', '',
			'2차조건 적용', '', '']);
		ws.mergeCells('A1:C1'); ws.getCell('A1').alignment = s.center;
		ws.mergeCells('D1:H1'); ws.getCell('D1').alignment = s.center;
		ws.mergeCells('I1:K1'); ws.getCell('I1').alignment = s.center;

		add_row(s.fills1, [
			'종목','시작일', '종료일',
			'번호', '매수가격', '매수수량', '매도가격', '매도수량',
			'매수증가량', '매도증가량', '적용횟수']);

		const write_plan_row = function (p: PlanSheetsBased, f?: Fill[]) {
			const term = p.term;
			const sheets = p.get_sheets();

			if (sheets) {
				sheets.list().forEach((sheet) => {
					const org_bvol_size = sheet.org_bvol_size || sheet.bvol_size;
					const org_svol_size = sheet.org_svol_size || sheet.svol_size;
					add_row(f, [
						p.product.code, term.begin, term.end,
						sheet.no, sheet.bprc, org_bvol_size, sheet.sprc, org_svol_size,
						sheet.bvol_size - org_bvol_size, sheet.svol_size - org_svol_size,
						sheet.cumul_occur || 0,
					]);
				});
			}
		};


		if (p instanceof PlanSheetsBased) {
			write_plan_row(p);

		} else if (p instanceof PlanComposite) {
			let idx = 0;
			const write_leaf_plan_row = function (p: Plan) {
				if (p instanceof PlanSheetsBased) {
					write_plan_row(p, (idx++) % 2 ? s.fills1 : undefined);

				} else if (p instanceof PlanComposite){
					p.subplans.forEach(write_leaf_plan_row);
				}
			};

			write_leaf_plan_row(p);
		}
	}
}
