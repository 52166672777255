import axios from "axios";
import { ItemInfo, ItemsResponse } from "./query-items";

export async function searchItems(params: {query: string, includesVirtual: boolean}): Promise<ItemInfo[]> {
	try {
		const resp = await axios.get('/data/items/search', { params })
		return (resp.data as ItemsResponse).items;

	} catch (err: any) {
		const response = err?.response;
		if (response) {
			if (response.data?.message) {
				throw new Error(response.data?.message);
			}
			if (response.data) {
				throw new Error(response.data);
			}
		}
		console.log("error", err);
		throw err;
	}
}
