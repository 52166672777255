import { Fill, Workbook, Worksheet } from 'exceljs';
import { flatten, uniq } from 'lodash';
import { Daily } from '.';
import { MatrixPlans } from '../matrix';
import { Plan } from '../plans/Plan';
import { Product } from '../product';
import { Term } from '../terms';

import s from '../../xlsx-styles';

const prepare_columns = (ws: Worksheet, products: Product[], plans: Plan[]) => {
	const font = s.font;
	const right = s.right;
	const f0 = s.fills0;
	const f1 = s.fills1;

	const columns: {width: number, style: any}[] = [
		{ width: 8, style: {font: font, fill: f0[0], border: s.border.tb} },
	];

	products.forEach(() => {
		columns.push({ width: 8, style: {font: font, fill: f0[0], border: s.border.tb, numFmt: '#,##0', alignment: right} });
	});

	plans.forEach((_, idx) => {
		columns.push({ width: 8, style: {font: font, fill: (idx % 2 ? f0 : f1)[0], border: s.border.tb, numFmt: '0.0%', alignment: right} });
		columns.push({ width: 8, style: {font: font, fill: (idx % 2 ? f0 : f1)[0], border: s.border.tb, numFmt: '0.0%', alignment: right} });
	});

	columns.forEach((column, idx) => {
		ws.getColumn(idx+1).width = column.width;
		ws.getColumn(idx+1).style = column.style;
	});
}


interface DateStream {
	(): Date | undefined;
}


const gen_month_stream = (dailies: Daily[], start_date: Date): DateStream => {
	const last_i = dailies.length - 1;
	let last_date: Date | undefined = dailies[last_i].date;
	let month_index = 0;

	return (): Date | undefined => {
		let next_date = start_date.clone().add_months(month_index++);
		if (last_date) {
			if (next_date >= last_date) {
				next_date = last_date.clone();
				last_date = undefined;
			}
			return next_date;
		} else {
			return undefined;
		}
	};
};


const write_date_column = (ws: Worksheet, c: number, term: Term, dailies: Daily[], f: Fill[]) => {
	let r = 1;

	let row = ws.getRow(r++);
	let cell = row.getCell(c);
	cell.value = '';
	cell.fill = f[1];

	row = ws.getRow(r++);
	cell = row.getCell(c);
	cell.value = '일자';
	cell.fill = f[1];

	const month_stream = gen_month_stream(dailies, Date.from_ymd(term.begin));
	let next_date = month_stream();
	dailies.forEach((d) => {
		if (next_date && d.date >= next_date) {
			const row = ws.getRow(r++);
			const cell = row.getCell(c);
			cell.value = d.date.format_ymd();

			next_date = month_stream();
		}
	});

	return c + 1;
};


const write_product_column = (ws: Worksheet, c: number, product: Product, term: Term, dailies: Daily[], f: Fill[]) => {
	const pcode = product.code;
	let r = 1;

	let row = ws.getRow(r++);
	let cell = row.getCell(c);
	cell.value = '';
	cell.fill = f[1];

	row = ws.getRow(r++);
	cell = row.getCell(c);
	cell.value = pcode;
	cell.fill = f[1];

	const month_stream = gen_month_stream(dailies, Date.from_ymd(term.begin));
	let next_date = month_stream();
	dailies.forEach(d => {
		if (next_date && d.date >= next_date) {
			const row = ws.getRow(r++);
			const cell = row.getCell(c);
			cell.value = d.ticks[pcode];

			next_date = month_stream();
		}
	});

	return c + 1;
};


const write_plan_column = (ws: Worksheet, c: number, title: string | undefined, term: Term, dailies: Daily[], f: Fill[]) => {
	let r = 1;

	let row = ws.getRow(r++);

	let cell = row.getCell(c);
	cell.value = title;
	cell.fill = f[1];
	cell.alignment = s.center;

	ws.mergeCells(1, c, 1, c+1);

	row = ws.getRow(r++);
	cell = row.getCell(c);
	cell.value = '수익률';
	cell.fill = f[1];

	cell = row.getCell(c+1);
	cell.value = '구간수익률';
	cell.fill = f[1];

	const month_stream = gen_month_stream(dailies, Date.from_ymd(term.begin));
	let next_date = month_stream();
	let last_month_value: number;
	dailies.forEach(d => {
		if (next_date && d.date >= next_date) {
			const volume_estimated_value = (d.volume_estimated_value === undefined ? (d.volume * d.tick) : d.volume_estimated_value);
			const value = d.money + d.interest_ernings - d.oper_cost - (d.applied_other_money || 0) + volume_estimated_value;

			const row = ws.getRow(r++);
			let cell = row.getCell(c);
			cell.value = value / d.invest - 1;

			cell = row.getCell(c+1);
			cell.value = last_month_value ? (value / last_month_value - 1) : '';

			last_month_value = value;
			next_date = month_stream();
		}
	});

	return c + 2;
};


export class XlsxMatrixMonthlySheet {

	static write(wb: Workbook, matrix_plans: MatrixPlans): any {
		const flatten_plans = flatten(matrix_plans.plans);

		const ws = wb.addWorksheet('월별수익률');

		const all_products = uniq(flatten(flatten_plans.map(p =>  p.products())));

		prepare_columns(ws, all_products, flatten_plans);

		let c = 2 + all_products.length,
			pi = 0;

		flatten_plans.forEach((p, idx) =>{
			const dailies = p.get_dailies();

			if (idx === 0) write_date_column(ws, 1, p.term, dailies, s.fills0);

			const products = p.products();
			while (products.includes(all_products[pi])) {
				write_product_column(ws, pi + 2, all_products[pi], p.term, dailies, s.fills0);
				pi++;
			}

			c = write_plan_column(ws, c, p.id, p.term, dailies, idx % 2 ? s.fills0 : s.fills1);
		});
	}
}
