import { Workbook } from 'exceljs';
import fileSaver from 'file-saver';

export interface Written {
	type: string;
	path?: string;
	filename?: string;
}

export async function writeWorkbookAsFile(wb: Workbook, filename: string): Promise<Written> {
	if (typeof window === "undefined") {
		throw new Error("Possible only in browsers!")
		// const path = 'out/'+filename;
		// await wb.xlsx.writeFile(path);
		// return {path: path, type: 'fs'};

	} else {
		const buffer = await wb.xlsx.writeBuffer();
		fileSaver(new Blob([buffer],{type:"application/octet-stream"}), filename);
		return {filename: filename, type: 'stream'};
	}
}
