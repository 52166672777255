export interface FundInfo {
	code: string;
	name: string;
	list_date: string;
}

// type FundsResponse = { funds: FundInfo[] };

export async function queryFunds(): Promise<FundInfo[]> {
	try {
		// const resp = await axios.get('/data/funds');
		// return (resp.data as FundsResponse).funds;

		return mockQueryFunds();

	} catch (err: any) {
		const response = err?.response;
		if (response) {
			if (response.data?.message) {
				throw new Error(response.data?.message);
			}
			if (response.data) {
				throw new Error(response.data);
			}
		}
		console.log("error", err);
		throw err;
	}
}

async function mockQueryFunds(): Promise<FundInfo[]> {
	return [
		{
			code: "kbslvrwb",
			name: "KB실버웰빙연금저축신탁",
			list_date: '2013-01-02',
		},
		{
			code: "hdcatst1",
			name: "HDC오토스탁증권투자신탁1호",
			list_date: '2023-09-25',
		},
	];
}
