import { find, findLast, first, includes, last, remove, reverse, sortBy } from 'lodash';

import { DayTick, isBaseTick, isOpeningTick, SingleTick } from '../../query-ticks';
import { SimulationEnv } from '../SimulationEnv';
import { AutochaInput, AutochaOutput, AutochaSheet, AutochaStub } from './autocha-stub';
import { PlanSheetsBased, PlanSheetsBasedOptions } from './PlanSheetBased';
import { PlanInfo } from './PlanSingle';
import { Deal, Sheet, Sheets } from './sheets';
import assert from 'assert';


export class PlanAutochaSheets implements Sheets {
	protected _sheets: Sheet[];
	protected _sell_ordered: Sheet[] | undefined;
	protected _buy_ordered: Sheet[] | undefined;

	constructor(sheets: Sheet[]) {
		this._sheets = sheets;
	}

	reset_order() {
		const sell_ordering = findLast(this._sheets, sheet => { return sheet.svol > 0; });
		this._sell_ordered = sell_ordering ? [sell_ordering] : [];

		const buy_ordering = find(this._sheets, sheet => { return sheet.bvol > 0; });
		this._buy_ordered = buy_ordering ? [buy_ordering] : [];
	}

	add_order() {
		const sell_ordering = findLast(this._sheets, sheet => { return sheet.svol > 0; });
		if (!includes(this._sell_ordered, sell_ordering)) {
			this._sell_ordered!.push(sell_ordering!);
			this._sell_ordered = sortBy(this._sell_ordered, 'sprc');
		}

		const buy_ordering = find(this._sheets, sheet => { return sheet.bvol > 0; });
		this._buy_ordered = buy_ordering ? [buy_ordering] : [];
		if (!includes(this._buy_ordered, buy_ordering)) {
			this._buy_ordered.push(buy_ordering!);
			this._buy_ordered = reverse(sortBy(this._buy_ordered, 'bprc'));
		}
	}

	nearest_sell(): Sheet | undefined {
		return first(this._sell_ordered);
	}

	nearest_buy(): Sheet | undefined {
		return first(this._buy_ordered);
	}

	list(): Sheet[] {
		return this._sheets;
	}

	on_matched(deal: Deal, cvol: number, sheet: Sheet) {
		if (deal === Deal.BUY) {
			if (sheet.bvol === cvol) {
				sheet.bvol = 0;
				sheet.svol = sheet.svol_size;
			} else {
				sheet.bvol = Math.max(sheet.bvol - cvol, 0);
				sheet.svol = Math.min(sheet.svol + cvol, sheet.svol_size);
			}

			if (sheet.bvol < 1) remove(this._buy_ordered!, sheet);
			this.add_order();

		} else if (deal === Deal.SELL) {
			if (sheet.svol === cvol) {
				sheet.bvol = sheet.bvol_size;
				sheet.svol = 0;
			} else {
				sheet.bvol = Math.min(sheet.bvol + cvol, sheet.bvol_size);
				sheet.svol = Math.max(sheet.svol - cvol, 0);
			}

			if (sheet.svol < 1) remove(this._sell_ordered!, sheet);
			this.add_order();
		}
	}
}


export interface Range {
	low: number;
	high: number;
}

export interface PlanAutochaOptions extends PlanSheetsBasedOptions {
	type: string;
	suppress_insufficent_first_money?: boolean;
}

export class PlanAutocha extends PlanSheetsBased {
	type: string;
	first_price: number | undefined;
	first_rate: number | undefined;
	first_rate_vol: number | undefined;
	first_deal: Deal | undefined;
	first_deal_vol: number | undefined;
	range: Range | undefined;
	margin_rate: number | undefined;
	suppress_insufficent_first_money?: boolean;
	protected _sheets: PlanAutochaSheets | undefined;

	constructor(o: PlanAutochaOptions, env: SimulationEnv) {
		super(o, env);
		this.type = o.type.toLowerCase();
		this.suppress_insufficent_first_money = o.suppress_insufficent_first_money;
	}

	get_sheets(): Sheets | undefined {
		return this._sheets;
	}

	deferredAutochaOutput: Promise<AutochaOutput> | undefined;

	async beforeFeedDayTicks(dayticks: DayTick[]): Promise<void> {
		await super.beforeFeedDayTicks(dayticks);

		const firstDaytick = first(dayticks)!;
		const ticks = firstDaytick.ticksByCodeMap[this.product.code];
		const firstDayClosing = {
			value: last(ticks)!,
			pos: {date: Date.from_ymd(firstDaytick.date), idx_in_date: ticks.length - 1, cnt_in_date: ticks.length}
		};
		assert(firstDayClosing.value);

		const input = this.createAutochaInput(firstDayClosing.value);
		this.deferredAutochaOutput = AutochaStub.deferRequestSheets(input);
	}

	protected onBeginOfDay(date: string, lastClosing: SingleTick): void {
		super.onBeginOfDay(date, lastClosing);

		if (this._sheets) {
			this._sheets.reset_order();
		}
	}

	async onSingleTick(single: SingleTick): Promise<void> {
		if (isBaseTick(single.pos)) return;

		if (this.first_rate_vol === undefined) {
			await this.dealFirstVol(single)
		}
		this.match_tick(single);
	}

	private async dealFirstVol(single: SingleTick): Promise<PlanAutocha> {
		const price = single.value;
		this.first_price = price;

		const output = await (() => {
			if (this.deferredAutochaOutput) {
				return this.deferredAutochaOutput;
			} else {
				const input = this.createAutochaInput(price);
				return AutochaStub.makeManySheets([input]).then(outputs => outputs[0]);
			}
		})();
		this.makeSheets(output);

		let volume = this.first_deal_vol!;
		let tradeoff = this.compute_tradeoff(price, volume);

		if (this.money.value < -tradeoff.money) {
			if (this.suppress_insufficent_first_money) {
				const tradeoff_one = this.compute_buy_tradeoff(price, 1);
				volume = Math.floor(this.money.value / -tradeoff_one.money);
				if (volume > 0) {
					tradeoff = this.compute_buy_tradeoff(price, volume);
				} else {
					volume = 0;
				}
			} else {
				console.error(single.pos.date, 'having vs need', this.money.value, -tradeoff.money);
				throw new Error("Insufficient money for buying the first volume");
			}
		}

		if (volume !== 0) this.tradeoff_assets(tradeoff, single.pos.date);
		this._sheets!.reset_order();
		return this;
	}

	private static sheet_mapper(src: AutochaSheet): Sheet {
		return {
			no: src.sheet_num,
			bprc: src.i_amot,
			bvol_size: src.i_volume,
			org_bvol_size: src.i_volume,
			bvol: src.i_remain,
			sprc: src.o_amot,
			svol_size: src.o_volume,
			org_svol_size: src.o_volume,
			svol: src.o_remain,
			cumul_occur: 0
		};
	}

	private makeSheets(output: AutochaOutput): PlanAutocha {
		switch (output.first_deal) {
		case 'I':
			this.first_deal = Deal.BUY;
			break;
		case 'O':
			this.first_deal = Deal.SELL;
			break;
		}
		this.first_rate = output.first_rate;
		this.first_rate_vol = output.first_rate_vol;
		this.first_deal_vol = output.first_deal_vol;
		this.range = {
			low: Math.floor(output.range_min / output.price * 100) / 100,
			high: Math.ceil(output.range_max / output.price * 100) / 100,
		};
		this.margin_rate = output.margin_rate;
		this._sheets = new PlanAutochaSheets(output.sheets.map(PlanAutocha.sheet_mapper));
		return this;
	}

	private createAutochaInput(firstPrice: number): AutochaInput {
		return {
			type: this.type,
			init_janak: this.money.value,
			init_vol: this.volume.amount,
			price: firstPrice,
			market: this.product.market_code,
			dunit: this.product.dunit,
			highest_price: this.tickBounds?.highest,
			lowest_price: this.tickBounds?.lowest,
		};
	}

	protected match_tick(single: SingleTick): void {
		let matched = true;

		while (matched) {
			matched = false;
			if (this.match_buy_tick(single)) matched = true;
			if (this.match_sell_tick(single)) matched = true;
		}
	}

	protected match_buy_tick(single: SingleTick): boolean {
		const sheet = this._sheets!.nearest_buy();
		if (!sheet || sheet.bprc < single.value) return false;

		const money = this.money;
		const bprc = isOpeningTick(single.pos) ? single.value : sheet.bprc; // 시초가 체결이면 시초가로 체결, 그 외에는 주문가로 체결
		let bvol = sheet.bvol;

		let tradeoff = this.compute_buy_tradeoff(bprc, bvol);
		if (money.value < -tradeoff.money) {
			const tradeoff_one = this.compute_buy_tradeoff(bprc, 1);
			bvol = Math.floor(money.value / -tradeoff_one.money);
			if (bvol < 1) return false;
			tradeoff = this.compute_buy_tradeoff(bprc, bvol);
		}

		this.tradeoff_assets(tradeoff, single.pos.date);
		this._sheets!.on_matched(Deal.BUY, bvol, sheet);

		return true;
	}

	protected match_sell_tick(single: SingleTick): boolean {
		const sheet = this._sheets!.nearest_sell();
		if (!sheet || sheet.sprc > single.value) return false;

		const sprc = isOpeningTick(single.pos) ? single.value : sheet.sprc; // 시초가 체결이면 시초가로 체결, 그 외에는 주문가로 체결
		const svol = Math.min(this.volume.amount, sheet.svol);
		if (svol < 1) return false;

		const tradeoff = this.compute_sell_tradeoff(sprc, svol);
		this.tradeoff_assets(tradeoff, single.pos.date);
		this._sheets!.on_matched(Deal.SELL, svol, sheet);
		return true;
	}


	get_plan_info(): PlanInfo {
		return  {
			type: this.type.toUpperCase(),
			first_price: this.first_price,
			first_rate: this.first_rate,
			range_low: this.range!.low,
			range_high: this.range!.high,
			margin_rate: this.margin_rate,
			invest_value: this.all_invest_value
		};
	}
}
