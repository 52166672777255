import axios from 'axios';
import { first, last, slice } from 'lodash';

interface DailyTick {
	date: string;
	tick: number[];
}
interface TickResponse {
	data: DailyTick[];
	next_key?: string;
}

interface TickCache {
	begin_date: string;
	end_date: string;
	dailyTicks: DailyTick[];
}

let _cache: {[code: string]: TickCache}= {};

export async function queryTicks(code: string, begin_date: string, end_date: string, list: DailyTick[]): Promise<DailyTick[]> {
	try {
		const resp = await axios.get('/data/item/daily', {
			params: { item_cd: code, start_date: begin_date, end_date }
		})
		const r = resp.data as TickResponse;
	
		const merged_list = list.concat(r.data);
		if (r.next_key) {
			//console.log('next_key = ', res.next_key);
			return queryTicks(code, r.next_key, end_date, merged_list);
		} else {
			//console.log('fech end');
			return merged_list;
		}

	} catch (err: any) {
		const response = err?.response;
		if (response) {
			if (response.data?.message) {
				throw new Error(response.data?.message);
			}
			if (response.data) {
				throw new Error(response.data);
			}
		}
		console.log("error", err);
		throw err;
	}
}

function bsearch(dailyTicks: DailyTick[], date: string): number[] {
	let a = 0;
	let b = dailyTicks.length - 1;

	while (a < b) {
		const m = a + Math.floor((b - a) / 2);
		if (a === m) break;

		const mv = dailyTicks[m];
		if (mv.date <= date) {
			a = m;
		} else {
			b = m;
		}
	}

	return [a, b];
}


function find_begin_index(dailyTicks: DailyTick[], date: string): number {
	const indexes = bsearch(dailyTicks, date);
	const a = indexes[0];
	if (a < 0) return -1;

	const av = dailyTicks[a];
	if (date <= av.date) {
		return a;
	} else {
		return indexes[1];
	}
}


function find_end_index(tick_list: DailyTick[], date: string): number {
	const indexes = bsearch(tick_list, date);
	const b = indexes[1];
	if (b < 0) return -1;

	const bv = tick_list[b];
	if (bv.date <= date) {
		return b;
	} else {
		return indexes[0];
	}
}


async function _fetch_one(code: string, begin_date: string, end_date: string): Promise<DailyTick[]> {
	const cache = _cache[code];
	if (cache && cache.begin_date <= begin_date && cache.end_date >= end_date) {
		if (!cache.dailyTicks.length) throw new Error(`종목 ${code}의 ${begin_date} ~ ${end_date}일의 가격이 없습니다`);

		const begin_index = find_begin_index(cache.dailyTicks, begin_date);
		if (begin_index < 0) throw new Error(`종목 ${code}의 ${begin_date}일의 가격이 없습니다`);

		const end_index = find_end_index(cache.dailyTicks, end_date);
		if (end_index < 0) throw new Error(`종목 ${code}의 ${end_date}일의 가격이 없습니다`);
		return slice(cache.dailyTicks, begin_index, end_index+1);

	} else {
		return queryTicks(code, begin_date, end_date, []);
	}
}


export interface DayTick {
	date: string;
	ticksByCodeMap: { [code: string]: number[] };
}

export async function fetchTicks(codes: string[], begin_date: string, end_date: string): Promise<DayTick[]> {
	const dailyTicksList = await Promise.all(codes.map(code => _fetch_one(code, begin_date, end_date)));
	const dates = makeSortedDateList(dailyTicksList);
	return dates.reduce((dayTicks, date): DayTick[] => {
		const ticksByCodeMap: { [code: string]: number[] } = {};
		codes.forEach((code, codeIdx) => {
			const dailyTicks = dailyTicksList[codeIdx];
			const firstDailyTick = first(dailyTicks);

			if (firstDailyTick?.date === date) {
				ticksByCodeMap[code] = dailyTicks.shift()!.tick;

			} else {
				const lastDayTick = last(dayTicks);
				if (!lastDayTick) throw new Error(`ERROR no data of ${code} on ${date}`);

				const lastClosing = last(lastDayTick.ticksByCodeMap[code])!;
				ticksByCodeMap[code] = [lastClosing, lastClosing];
			}

			dayTicks.push({date, ticksByCodeMap});
		});

		return dayTicks;
	}, [] as DayTick[]);
}

function makeSortedDateList(dailyTicksList: DailyTick[][]): string[] {
	const dates: string[] = [];
	dailyTicksList.forEach(dailyTicks => {
		dates.push(...dailyTicks.map(dailyTick => dailyTick.date));
	})
	return dates.sort();
}

export async function prepareTicks(code: string, begin_date: string, end_date: string): Promise<void> {
	const cache = _cache[code];
	if (cache && cache.begin_date <= begin_date && cache.end_date >= end_date) {
		// Nothing to do

	} else {
		const dailyTicks = await queryTicks(code, begin_date, end_date, []);
		_cache[code] = { begin_date, end_date, dailyTicks: dailyTicks };
	}
}

export function clearAllTickCaches() {
	_cache = {};
}

export interface SingleTickPosition {
	date: Date;
	idx_in_date: number;
	cnt_in_date: number;
}

export function isBaseTick(pos: SingleTickPosition): boolean {
	return (pos.idx_in_date === 0);
}

export function isOpeningTick(pos: SingleTickPosition): boolean {
	return (pos.idx_in_date === 1);
}

export interface SingleTick {
	value: number;
	pos: SingleTickPosition;
}
