import { last } from 'lodash';
import { DateDuration } from '../../date';
import { DailyRollingTermsSpec } from '../request';
import { fetchTicks } from '../../query-ticks';
import { Term, TermsProvider } from './types';


export class DailyRollingTerms implements TermsProvider {
	protected begin: Date;
	protected end: Date;
	protected duration: DateDuration;
	protected step: DateDuration;

	constructor(spec: DailyRollingTermsSpec) {
		this.begin = Date.from_ymd(spec.begin);
		this.end = Date.from_ymd(spec.end);
		this.duration = spec.duration;
		this.step = spec.step;
	}

	getTermList(): Promise<Term[]> {
		const latest_of_begin = Date.subtract_duration(this.end, {
			years: this.duration.years,
			months: this.duration.months,
			days: (this.duration.days || 0) - 1
		});
		const latest_of_begin_ymd = latest_of_begin.format_ymd();
		return fetchTicks(['069500'], this.begin.format_ymd(), latest_of_begin_ymd)
			.then(ticks => {
				const beginDates = Date.range(this.begin, latest_of_begin, this.step).map(d => d.format_ymd());

				return beginDates
					.reduce((result, begin) => {
						let tick;
						do {
							tick = ticks.shift();
						} while (tick && begin > tick.date);

						if (tick) {
							if (begin == tick.date) {
								const prev = last(result);
								if (!prev || begin > prev) {
									result.push(begin);
								}
							} else if (tick.date <= latest_of_begin_ymd){
								result.push(tick.date);
							}
						}
						return result;
					}, [] as string[])
					.map(begin => ({
						begin,
						end: Date.from_ymd(begin)
							.add_duration(this.duration)
							.add_days(-1)
							.format_ymd()
					}));
			});
	}
}
