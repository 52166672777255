import { Workbook } from 'exceljs';

import { TermResult } from '../matrix';
import { SimulationEnv } from '../SimulationEnv';
import { writeWorkbookAsFile, Written } from '../../xlsx-writer';
import { XlsxMultitermsInfoSheet } from './XlsxMultitermsInfoSheet';
import { XlsxMultitermsProfitSheet } from './XlsxMultitermsProfitSheet';

export class XlsxMultitermsWriter {
	static async write(multiterms: TermResult[], filename: string, env: SimulationEnv): Promise<Written> {
		const p_stime = new Date().getTime();
		const wb = new Workbook();
		
		XlsxMultitermsInfoSheet.write(wb, multiterms, env);
		XlsxMultitermsProfitSheet.write(wb, multiterms);
		
		const written = await writeWorkbookAsFile(wb, filename)
		if (written.type === 'fs') {
			console.log('> ' + written.path + ' written. ' + ((new Date().getTime() - p_stime)/1000) + '초 소요됨.');
		}
		return written;
	}
}
