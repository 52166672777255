import s from '../../xlsx-styles';
import { Workbook, Fill } from 'exceljs';
import { Plan } from '../plans/Plan';
import { PlanSingle } from '../plans/PlanSingle';
import { PlanComposite } from '../plans/PlanComposite';

export class XlsxPlanProfitSheet {

	static write(wb: Workbook, p: Plan): any {
		const ws = wb.addWorksheet('수익률분석');

		const font = s.font;
		const right = s.right;
		const f = s.fills0;
		const columns: {width: number, style: any}[] = [
			{ width: 7, style: {font: font, fill: f[0]} },
			{ width: 8, style: {font: font, fill: f[0]} },
			{ width: 8, style: {font: font, fill: f[0]} },
			{ width: 8, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
			{ width: 8, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
			{ width: 8, style: {font: font, numFmt: '0.0%', alignment: right, fill: f[1]} },
			{ width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]} },
			{ width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]} },
			{ width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]} },
			{ width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]} },
			{ width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]} },
			{ width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]} },
			{ width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]} },
			{ width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]} },
			{ width:10, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
			{ width:10, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
			{ width: 8, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
			{ width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
			{ width:10, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
			{ width:10, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
			{ width:10, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
			{ width:9, style: {font: font, numFmt: '#,##0.00', alignment: right, fill: f[1]} },
		];
		columns.forEach((info, idx) => {
			const c = ws.getColumn(idx+1);
			const style = info.style;
			style.border = s.border.tb;
			c.width = info.width;
			c.style = style;
			// if (style.font) c.font = info.font;
			// if (style.numFmt) c.numFmt = info.numFmt;
			// if (style.alignment) c.alignment = info.alignment;
			// if (style.fill) c.fill = info.fill;
			// if (style.border) c.border = info.border;
		});

		const add_row = (f: Fill[] | undefined, values: any[]) => {
			const row = ws.addRow(values);

			if (f) {
				let i = 1;
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
			}

			return row;
		};

		let r = 1;

		add_row(s.fills1, [
			'구분','', '',
			'가격', '', '',
			'수익/비용', '', '', '', '', '', '', '',
			'비고', '', '', '', '', '', '']);
		ws.mergeCells('A1:C1'); ws.getCell('A1').alignment = s.center;
		ws.mergeCells('D1:F1'); ws.getCell('D1').alignment = s.center;
		ws.mergeCells('G1:N1'); ws.getCell('G1').alignment = s.center;
		ws.mergeCells('O1:V1'); ws.getCell('O1').alignment = s.center;
		r++;


		add_row(s.fills1, [
			'종목','시작일', '종료일',
			'시작일 가격', '종료일 가격', '가격 변동률',
			'수익', '매매수익', '평가수익', '이자수익', '배당수익', '매매수수료', '세금', '운영보수료',
			'매도분 원매입금', '총거래금액',
			'최종 보유수량', '평균단가', '최종 보유현금', '최종평가금액', '투자금액', '최종펀드기준가']);
		r++;

		const write_plan_row = function (p: PlanSingle, f?: Fill[]) {
		if (p.use_shared_money) {
				if (p.has_tag('shared_limit_slave')) {
					add_row(f, [
						p.product.code, p.term.begin, p.term.end,
						p.first_tick?.value,
						p.last_tick?.value,
						{formula: '(E'+r+ '-D'+r+')/D'+r},
						'(공유)',
						p.sold_profit, p instanceof PlanComposite ? p.volume.profit : {formula: '(E'+r+'-R'+r+')*Q'+r},
						p.interest_ernings, p.dividend_ernings, p.fee, p.tax, p.oper_cost,
						p.sold_cost, p.trade_amount, p.volume.amount, p.volume.price || 0,
						'(공유)',
						{formula: 'E'+r+'*Q'+r},
						'(공유)',
						'(공유)',
					]);
					r++;
				} else {
					add_row(f, [
						p.product.code, p.term.begin, p.term.end,
						p.first_tick?.value,
						p.last_tick?.value,
						{formula: '(E'+r+ '-D'+r+')/D'+r},
						'(공유)',
						p.sold_profit, p instanceof PlanComposite ? p.volume.profit : {formula: '(E'+r+'-R'+r+')*Q'+r},
						p.interest_ernings, p.dividend_ernings, p.fee, p.tax, p.oper_cost,
						p.sold_cost, p.trade_amount, p.volume.amount, p.volume.price || 0,
						p.pure_money_value,
						{formula: 'E'+r+'*Q'+r+'+S'+r},
						'(공유)',
						'(공유)',
					]);
					r++;
				}
			} else {
				add_row(f, [
					p.product.code, p.term.begin, p.term.end,
					p.first_tick?.value,
					p.last_tick?.value,
					{formula: '(E'+r+ '-D'+r+')/D'+r},
					{formula: 'T'+r+'-U'+r},
					p.sold_profit, p instanceof PlanComposite ? p.volume.profit : {formula: '(E'+r+'-R'+r+')*Q'+r},
					p.interest_ernings, p.dividend_ernings, p.fee, p.tax, p.oper_cost,
					p.sold_cost, p.trade_amount, p.volume.amount, p.volume.price || 0,
					p.pure_money_value,
					{formula: 'E'+r+'*Q'+r+'+S'+r},
					p.initial_invest.value + p.post_invest.value,
					p.right_shares_price(),
				]);
				r++;
			}
		};

		const write_composite_plan_row = function (p: PlanComposite, f?: Fill[]) {
			const products = p.products();
			if (products.length === 1) {
				const single_product = p.for_single_product()!;
				const sum_volume_amount = single_product.sum_volume_amount();
				const sum_volume_cost = single_product.sum_volume_cost();
				add_row(f, [
					products[0].code, p.term.begin, p.term.end,
					single_product.first_tick()?.value,
					single_product.last_tick()?.value,
					{formula: '(E'+r+ '-D'+r+')/D'+r},
					{formula: 'T'+r+'-U'+r},
					p.sum_sold_profit(), p.sum_volume_profit(),
					p.sum_interest_ernings(), p.sum_dividend_ernings(), p.sum_fee(), p.sum_tax(), p.sum_oper_cost(),
					p.sum_sold_cost(), p.sum_trade_amount(),
					sum_volume_amount,
					sum_volume_cost / sum_volume_amount,
					p.sum_pure_money_value(),
					p.sum_value(),
					p.sum_all_invest_value(),
					p.right_shares_price(),
				]);
			} else {
				add_row(f, [
					products.length+'종목',
					p.term.begin, p.term.end, '---', '---', '---',
					{formula: 'T'+r+'-U'+r},
					p.sum_sold_profit(), p.sum_volume_profit(),
					p.sum_interest_ernings(), p.sum_dividend_ernings(), p.sum_fee(), p.sum_tax(), p.sum_oper_cost(),
					p.sum_sold_cost(), p.sum_trade_amount(), '---', '---',
					p.sum_pure_money_value(),
					p.sum_value(),
					p.sum_all_invest_value(),
					p.right_shares_price(),
				]);
			}
			r++;
		}

		if (p instanceof PlanSingle) {
			write_plan_row(p);

		} else if (p instanceof PlanComposite) {
			write_composite_plan_row(p, s.fills2);

			const row = add_row(undefined, [
					'', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '' ,'', '', '', '', ''
				]);
			row.height = 4;
			r++;

			const write_leaf_plan_row = function (p: Plan) {
				if (p instanceof PlanSingle) {
					write_plan_row(p);

				} else if (p instanceof PlanComposite) {
					p.subplans.forEach(write_leaf_plan_row);
				}
			};

			write_leaf_plan_row(p);
		}

		return ws;
	}
}
