import { uniq } from 'lodash';
import '../date';

import { Matrix, ProgressReporter } from './matrix';
import { createPlanFactories, PlanFactory } from './plans/PlanFactory';
import { createReporters, Reporters } from './reports';
import { SimulationRequest } from './request';
import { SimulationEnv } from './SimulationEnv';
import { createTerm, TermsProvider } from './terms';

export class Simulation {
	protected env: SimulationEnv;
	protected terms: TermsProvider;
	protected factories: PlanFactory[];
	protected productCodes: string[];
	protected reporters: Reporters;

	constructor(req: SimulationRequest) {
		this.env = new SimulationEnv(req.env);
		this.terms = createTerm(req.terms);
		this.factories = createPlanFactories(req.plans, this.env);
		this.productCodes = uniq(req.plans.map(plan => {
			if (plan.type === 'money') return 'v:KOSPI';
			else return plan.product_code;
		}));
		this.reporters = createReporters(req.name, req.report);

		if (!this.factories.length) {
			throw new Error('조건이 선택되지 않았습니다.');
		}
	}

	run(progress_reporter?: ProgressReporter): {promise: Promise<void>, control: {cancel: () => void}} {
		const matrix = new Matrix()
			.terms(this.terms)
			.factories([this.factories])
			.set_plan_reporter(this.reporters.plan_reporter)
			.set_matrix_reporter(this.reporters.matrix_reporter)
			.set_terms_reporter(this.reporters.terms_reporter)
			.set_progress_reporter(progress_reporter)
			.set_product_codes_to_prepare(this.productCodes);
		return {
			promise: matrix.start(this.env),
			control: {cancel: () => matrix.cancel()}
		};
	}
}
