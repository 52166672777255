import axios from 'axios';
import { isObject } from 'lodash';
import { DeferredRequests } from '../../deferred';

export interface WoldSheet {
	sheet_num: number;
	i_amot: number;
	i_volume: number;
	o_amot: number;
	o_volume: number;
}

export interface FcondInput {
	init_janak: number;
	init_amot: number;
	first_rate: number;
	first_vol: number;
	lower_rate: number;
	upper_rate: number;
	margin_rate: number;
	market: string;
	init_vol: number;
	usheet_cnt: number;
	lsheet_cnt: number;
	extend_lower: boolean;
	highest_price?: number;
	lowest_price?: number;
}

export interface FcondOutput extends FcondInput {
	auto_deal: string;
	i_cond_method: string;
	i_cond_amot: number;
	o_cond_method: string;
	o_cond_amot: number;
	auto_vol: number;
	a_cond_amot: number;
	chkDiv: string;
	divStep: number;
	divGap: number;
	sheets: WoldSheet[];
}

const drh = new DeferredRequests<FcondInput, FcondOutput>();
const BULK_REQUESTS_SIZE = 30;

export class WoldStub {
	static async makeManySheets(inputs: FcondInput[]): Promise<FcondOutput[]> {
		try {
			const resp = await axios.post('/data/make_sheets/attrib03', inputs)
			const r = resp.data;
			if (isObject(r)) {
				if ((r as any).result === 'ok') {
					return (r as any).data;
				} else if ((r as any).result === 'error') {
					throw new Error((r as any).message);
				} else {
					throw new Error('Unexpected data');
				}
			} else {
				throw new Error('Empty response');
			}
		} catch (err: any) {
			const response = err?.response;
			if (response) {
				if (response.data?.message) {
					throw new Error(response.data?.message);
				}
				if (response.data) {
					throw new Error(response.data);
				}
			}
			console.log("error", err);
			throw err;
		}
	}

	static deferRequestSheets(input: FcondInput): Promise<FcondOutput> {
		return drh.push(input);
	}

	static executeDeferredRequests(): Promise<void> {
		return drh.executeWith(this.makeManySheets, BULK_REQUESTS_SIZE);
	}
}
