import axios from 'axios';

export interface DailyValue {
	date: string;
	value: number;
}
interface IndicesResponse {
	data: DailyValue[];
	next_key?: string;
}

type Params = {code: string, start_date: string, end_date: string, type?: 'base' | 'closing'};
export async function queryIndices(params: Params, list?: DailyValue[]): Promise<DailyValue[]> {
	try {
		const resp = await axios.get('/data/index/daily', {
			params,
		})
		const r = resp.data as IndicesResponse;
	
		const merged_list = list ? list.concat(r.data) : r.data;
		if (r.next_key) {
			//console.log('next_key = ', res.next_key);
			return queryIndices({...params, start_date: r.next_key}, merged_list);
		} else {
			//console.log('fech end');
			return merged_list;
		}

	} catch (err: any) {
		const response = err?.response;
		if (response) {
			if (response.data?.message) {
				throw new Error(response.data?.message);
			}
			if (response.data) {
				throw new Error(response.data);
			}
		}
		console.log("error", err);
		throw err;
	}
}
