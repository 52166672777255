import { Fill, Workbook } from 'exceljs';
import { last } from 'lodash';
import { Asset } from '../assets';
import { Plan } from '../plans/Plan';
import { PlanComposite } from '../plans/PlanComposite';
import { PlanSingle } from '../plans/PlanSingle';
import { Deal } from '../plans/sheets';

import s from '../../xlsx-styles';

export class XlsxPlanContractSheet {

	static write(wb: Workbook, p: Plan): any {
		const ws = wb.addWorksheet('매매내역');

		const font = s.font;
		const right = s.right;
		const f = s.fills0;
		const columns: {width: number, style: any}[] = [
			{ width: 7, style: {font: font, fill: f[0]} },
			{ width: 8, style: {font: font, fill: f[0]} },
			{ width: 8, style: {font: font, fill: f[0]} },

			{ width: 4, style: {font: font, alignment: right, fill: f[1]} },
			{ width: 8, style: {font: font, alignment: right, fill: f[1]} },
			{ width:10, style: {font: font, fill: f[1]} },
			{ width:10, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
			{ width:10, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
			{ width: 8, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]} },
			{ width: 7, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]} },
			{ width: 8, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]} },
			{ width: 7, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]} },
			{ width: 8, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]} },
			{ width: 8, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]} },
			{ width: 8, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]} },
			{ width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
			{ width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
			{ width: 8, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
			{ width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
		];
		columns.forEach((column, idx) => {
			column.style.border = s.border.tb;
			ws.getColumn(idx+1).width = column.width;
			ws.getColumn(idx+1).style = column.style;
		});

		const add_row = function (f: Fill[] | undefined, values: any) {
			const row = ws.addRow(values);
			if (f) {
				let i = 1;
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
			}
			return row;
		};

		add_row(s.fills1, [
			'구분','', '',
			'매매내역', '', '', '', '',
			'매매상세', '', '', '', '', '', '',
			'잔고', '', '', '']);
		ws.mergeCells('A1:C1'); ws.getCell('A1').alignment = s.center;
		ws.mergeCells('D1:H1'); ws.getCell('D1').alignment = s.center;
		ws.mergeCells('I1:O1'); ws.getCell('I1').alignment = s.center;
		ws.mergeCells('P1:S1'); ws.getCell('P1').alignment = s.center;

		add_row(s.fills1, [
			'종목','시작일', '종료일',
			'No', '매매일자', '구분', '입금', '출금',
			'매수가격', '매수수량', '매도가격', '매도수량', '실현수익', '매매수수료', '세금',
			'주식평균단가(후입선출)', '주식평균단가(이동평균)', '주식수', '예수금']);

		const write_plan_row = function (p: PlanSingle, f: Fill[] | undefined) {

			const prod = p.product.code;
			const term = p.term;

			const initial_invest_volume = p.initial_invest.find(p.product);
			const post_invest_volume = p.post_invest.find(p.product);
			const invest_volume = new Asset(p.product);
			if (initial_invest_volume) invest_volume.push(initial_invest_volume.amount, initial_invest_volume.avg_price);
			if (post_invest_volume) invest_volume.push(post_invest_volume.amount, post_invest_volume.avg_price);

			const invest_value = p.initial_invest.value + p.post_invest.value;
			let after = {
				lifo_avg_price: invest_volume.price || 0,
				moving_avg_price: invest_volume.price || 0,
				volume: invest_volume.amount || 0,
				money: invest_value - invest_volume.value,
			};

			add_row(f, [
				prod, term.begin, term.end,
				0, term.begin, '계좌 입금', invest_value, '',
				'', '', '', '', '', '', '',
				after.lifo_avg_price, after.moving_avg_price, after.volume, after.money]);

			p.contracts.forEach((ctrt, ctrt_idx) => {
				switch (ctrt.deal) {
				case Deal.BUY:
					add_row(f, [
						prod, term.begin, term.end,
						ctrt_idx+1, ctrt.date.format_ymd(), '매수', '', -ctrt.flow,
						ctrt.price, ctrt.volume, '', '', '', ctrt.fee, ctrt.tax,
						ctrt.after.lifo_avg_price || 0, ctrt.after.moving_avg_price || 0, ctrt.after.volume || 0, ctrt.after.money
					]);
					break;
				case Deal.SELL:
					add_row(f, [
						prod, term.begin, term.end,
						ctrt_idx+1, ctrt.date.format_ymd(), '매도', ctrt.flow, '',
						'', '', ctrt.price, ctrt.volume, ctrt.deal_margin, ctrt.fee, ctrt.tax,
						ctrt.after.lifo_avg_price || 0, ctrt.after.moving_avg_price || 0, ctrt.after.volume || 0, ctrt.after.money
					]);
					break;
				case Deal.DIVIDEND:
					add_row(f, [
						prod, term.begin, term.end,
						ctrt_idx+1, ctrt.date.format_ymd(), '배당 '+ctrt.price+'/주', ctrt.flow, '',
						'', '', '', '', '', '', ctrt.tax,
						ctrt.after.lifo_avg_price || 0, ctrt.after.moving_avg_price || 0, ctrt.after.volume || 0, ctrt.after.money
					]);
					break;
				}
			});

			const lastctrt = last(p.contracts);
			if (lastctrt) after = {...lastctrt.after};

			if (p.interest_ernings) {
				after.money += p.interest_ernings;
				add_row(f, [
					prod, term.begin, term.end,
					p.contracts.length + 1, term.end, '이자', p.interest_ernings, '',
					'', '', '', '', '', '', '',
					after.lifo_avg_price || 0, after.moving_avg_price || 0, after.volume || 0, after.money]);
			}

			if (p.oper_cost) {
				after.money -= p.oper_cost;
				add_row(f, [
					prod, term.begin, term.end,
					p.contracts.length + 2, term.end, '운영보수', '', p.oper_cost,
					'', '', '', '', '', '', '',
					after.lifo_avg_price || 0, after.moving_avg_price || 0, after.volume || 0, after.money]);
			}
		};

		if (p instanceof PlanSingle) {
			write_plan_row(p, undefined);

		} else {
			let idx = 0;
			const write_leaf_plan_row = function (p: Plan) {
				if (p instanceof PlanSingle){
					write_plan_row(p, (idx++) % 2 ? s.fills1 : undefined);

				} else if (p instanceof PlanComposite) {
					p.subplans.forEach(write_leaf_plan_row);
				}
			};
			write_leaf_plan_row(p);
		}
	}
}
