import { getCD } from "./cd";
import { SimulationEnvSpec } from "./request";

export class SimulationEnv {
	trade_fee_rate: number;
	oper_cost_rate: number;
	interest_rate: ((date: Date) => number) | undefined;
	ex_dividend: boolean;
	etf_margin_tax: boolean;

	constructor(spec: SimulationEnvSpec) {
		this.trade_fee_rate = spec.trade_fee_rate_p / 100.0;
		this.oper_cost_rate = spec.oper_cost_rate_p / 100.0;
		this.interest_rate = spec.interest_rate ? getCD : undefined;
		this.ex_dividend = false; // 수정주가를 사용하므로 spec.ex_dividend 값을 사용하지 않고 항상 false로 설정한다.
		this.etf_margin_tax = spec.etf_margin_tax;
	}
}
