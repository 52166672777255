export class ProgressMessage {
	private startTime: number;

	constructor() {
		this.startTime = new Date().getTime();
	}

	getMessage(progress: number): string {
		const time = new Date().getTime();
		const delayed = time - this.startTime;

		if (progress == 0) {
			return '준비중';
			
		} else if (progress < 1) {
			const expected = Math.ceil((delayed / progress - delayed) / 1000);
			const expected_h = Math.floor(expected / (60*60));
			const expected_m = Math.floor((expected % (60*60)) / 60);
			const expected_s = expected % 60;
			const expected_str = (expected_h ? expected_h + '시간 ' : '')
				+ (expected_m ? expected_m + '분 ' : '') + expected_s + '초';

			return `${expected_str} 후 완료 예상 (${Math.ceil(delayed/1000)}초 소요됨)`;

		} else {
			return `완료 (${Math.ceil(delayed/1000)}초 소요됨)`;
		}
	}
}
