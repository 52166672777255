import { Term, TermsProvider } from './types';
import { fetchTicks } from '../../query-ticks';
import { DateDuration } from '../../date';
import { last } from 'lodash';
import { EveryNthDaysTermsSpec } from '../request';


export class EveryNthDaysTerms implements TermsProvider {
	protected begin: Date;
	protected end: Date;
	protected duration: DateDuration;
	protected days: number[];

	constructor(spec: EveryNthDaysTermsSpec) {
		this.begin = Date.from_ymd(spec.begin);
		this.end = Date.from_ymd(spec.end);
		this.duration = spec.duration;
		this.days = spec.days.sort();
	}

	private createBeginDates(): string[] {
		const end_of_begin = Date.subtract_duration(this.end, this.duration);
		const beginDates: string[] = [];

		const month_cursor = new Date(this.begin.getTime());
		while (month_cursor <= end_of_begin) {
			const year = month_cursor.getFullYear();
			const month = month_cursor.getMonth();

			this.days.forEach(day => {
				const d = new Date(month_cursor.getTime());
				d.setDate(day);

				if (year == d.getFullYear() && month == d.getMonth()) {
					if (d >= this.begin && d <= end_of_begin) {
						beginDates.push(d.format_ymd());
					}
				}
			});

			month_cursor.setMonth(month_cursor.getMonth() + 1);
		}

		return beginDates;
	}

	getTermList(): Promise<Term[]> {
		const latest_of_begin = Date.subtract_duration(this.end, {
			years: this.duration.years,
			months: this.duration.months,
			days: (this.duration.days || 0) - 1
		});
		const latest_of_begin_ymd = latest_of_begin.format_ymd();
		return fetchTicks(['069500'], this.begin.format_ymd(), latest_of_begin.format_ymd())
			.then((tick_list) => {
				return this.createBeginDates()
					.reduce((result, begin) => {
						let tick;
						do {
							tick = tick_list.shift();
						} while (tick && begin > tick.date);

						if (tick) {
							if (begin == tick.date) {
								const prev = last(result);
								if (!prev || begin > prev) {
									result.push(begin);
								}
							} else if (tick.date <= latest_of_begin_ymd){
								result.push(tick.date);
							}
						}

						return result;
					}, [] as string[])
					.map(begin => ({
						begin,
						end: Date.from_ymd(begin)
							.add_duration(this.duration)
							.add_days(-1)
							.format_ymd()
					}));

			});
	}
}
