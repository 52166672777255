import { isEqual } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';

interface Props {
	value: number[] | null | undefined;
	onChange: (e: {value: number[]}) => void;
	className?: string;
}
export function InputTextNumbers({value, onChange, className}: Props) {
	const [text, setText] = useState('');

	useEffect(() => {
		setText(value ? value.join(', ') : '');
	}, [value]);
	
	const onChangeText = (text: string) => {
		setText(text);
		try {
			const numbers = parseIntegers(text);
			if (!isEqual(value, numbers)) {
				onChange({value: numbers});
			}
		} catch (err) {
			// Ignore parseIntegers failure
		}
	};

	return (
		<InputText className={className} value={text} onChange={e => onChangeText(e.target.value)} />
	);
}

function parseIntegers(text: string): number[] {
	console.log('tokens', text.split(/[,\s]+/));
	return text.split(/[,\s]+/).map(token => {
		const val = parseInt(token)
		if (isNaN(val)) throw new Error(token + " is NaN!");
		return val;
	});
}
