import { Workbook } from "exceljs";
import { FundPrice } from "../query-fund-prices";
import { FundInfo } from "../query-funds";
import { XlsxFundPriceSheet } from "./XlsxFundPriceSheet";
import { writeWorkbookAsFile } from "../xlsx-writer";
import { DailyValue } from "../query-indices";

export interface FundData {
	type: 'fund';
	fund: FundInfo;
	days: {begin: string, end: string};
	prices: FundPrice[];
}

export interface IndexData {
	type: 'index' | 'etc';
	code: string;
	name: string;
	color: string;
	dailyValues: DailyValue[];
	unit?: '1/100',
	style?: { borderWidth?: number; borderDash?: number[]; format?: 'percent'};
}

export async function downloadPriceData(fundData: FundData, indices: IndexData[]) {
	console.log('download', fundData.fund.name, ...indices.map(i => i.name));
	const p_stime = new Date().getTime();
	const wb = new Workbook();

	XlsxFundPriceSheet.write(wb, fundData, indices);

	const filename = createFilename(fundData);

	const written = await writeWorkbookAsFile(wb, filename);
	if (written.type === 'fs') {
		console.log('> ' + written.path + ' written. ' + ((new Date().getTime() - p_stime)/1000) + '초 소요됨.');
	}
	return written;

}

function createFilename(fundData: FundData): string {
	return `기준가-${fundData.fund.name.replace(/\s+/g, '')}.xlsx`;
}
