import React, { useEffect, useRef, useState } from "react";
import { Chart } from "primereact/chart";
import { ChartData, ChartDataset, ChartOptions, Plugin, Chart as ChartChart } from "chart.js";
import 'chartjs-adapter-date-fns';
import { isDate, isNumber, isString, max as maxOf, min as minOf } from "lodash";
import colorLib from '@kurkle/color';

import { createVerticalTooltipLinePlugin } from "../../components/chart-plugins/VerticalTooltipLine";

const CustomTooltipPlugin: Plugin<'line'> = {
	id: 'custom-tooltip',
	afterDraw: (chart: ChartChart) => {
		const actives = chart.tooltip?.getActiveElements();
		if (actives?.length) {
			const chartArea = chart.chartArea;
			const ctx = chart.ctx;
			ctx.save();
			ctx.font = "bold 8pt " + getNumbersFontFamily();
			ctx.fillStyle = 'rgba(3, 83, 151, 1)';

			actives.forEach((active, activeIndex) => {
				const dataset = chart.data.datasets[active.datasetIndex];
				if (dataset) {
					const data = dataset.data[active.index] as number;
					if (data) {
						const meta = chart.getDatasetMeta(active.datasetIndex);
						const metadata = meta.data[active.index] as any;
						const tx = metadata.x - 2;
						const tooltipData = ((dataset as any).tooltipData?.[active.index] || (dataset as any).data?.[active.index]) as number | undefined;

						if (activeIndex === 0) {
							const labels = chart.data.labels as string[];
							const text = formatDate(labels[active.index]);
							ctx.textAlign = 'right';
							ctx.fillText(text, tx, chartArea.top + 5);
						}

						if (tooltipData) {
							const text = `${dataset.label}: ${tooltipData.toLocaleString()}`;
							const tm = ctx.measureText(text);
							const ty = chartArea.top + 5 + (tm.fontBoundingBoxAscent + tm.fontBoundingBoxDescent + 2)*(activeIndex + 1);
							const padding = {left: 2, top: 2, right: 1, bottom: 0};
							ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
							ctx.fillRect(
								tx - tm.width - padding.left,
								ty - tm.actualBoundingBoxAscent - padding.top,
								tm.width + padding.left + padding.right,
								tm.fontBoundingBoxAscent + tm.fontBoundingBoxDescent + padding.top + padding.bottom
							);

							ctx.fillStyle = colorLib(dataset.borderColor as any).darken(0.5).rgbString();
							ctx.textAlign = 'right';
							ctx.fillText(text, tx, ty);
						}
					}
				}
			});
			ctx.restore();
		}
	}
};

const chartPlugins: Plugin[] = [
	createVerticalTooltipLinePlugin('y1'),
	CustomTooltipPlugin,
];

export interface FundChartDataset {
	type: 'bar' | 'line',
	label: string,
	data: (number | null)[];
	tooltipData?: any[];
	borderColor: string;
	borderWidth?: number;
	borderDash?: number[];
	backgroundColor?: string;
	pointRadius?: number;
	pointHoverRadius?: number;
	normalized?: boolean;
	stack?: string;
	fill?: boolean;
	stepped?: boolean;
	animation?: false;
}

export interface FundMultiChartProps {
	dates: string[];
	y1: {
		type: '기준가' | '수익률',
		datasets: FundChartDataset[],
	};
	y2?: {
		type: '원본금액' | '자산구성' | '자산구성비중',
		datasets: FundChartDataset[],
	};
	className?: string;
}
export function FundMultiChart({dates, y1, y2, ...props}: FundMultiChartProps) {
	const [chartData] = useState<ChartData<'line' | 'bar'>>({
		datasets: []
	});
	const chart = useRef<Chart>(null);

	const [chartOptions] = useState<ChartOptions<'line' | 'bar'>>({
		maintainAspectRatio: false,
		datasets: {
			bar: {
				barPercentage: 0.6,
			}
		},
		interaction: {
			intersect: false,
			mode: 'index',
		},
		plugins: {
			legend: { display: false },
			tooltip: { enabled: false },
			decimation: { enabled: true, },
		},
	});

	useEffect(() => {
		const datasets = y1.datasets.map((dataset): ChartDataset<'line' | 'bar'> => {
			return {
				borderWidth: 2,
				pointRadius: 0,
				pointHoverRadius: 0,
				...dataset,
				yAxisID: 'y1',
				spanGaps: true,
			};
		});
		if (y2) {
			datasets.push(...y2.datasets.map((dataset): ChartDataset<'line' | 'bar'> => {
				return {
					borderWidth: 2,
					pointRadius: 0,
					pointHoverRadius: 0,
					...dataset,
					yAxisID: 'y2',
					spanGaps: true,
				};
			}));
		}

		chartData.labels = dates;
		chartData.datasets = datasets.map(dataset => {
			// 애니메이션 효과를 위해 기존 데이터셋을 찾아서 업데이트
			const oldDataset = chartData.datasets.find(d => d.label === dataset.label);
			if (oldDataset) {
				Object.assign(oldDataset, dataset);
				return oldDataset;
			}
			return dataset;
		});
		chartOptions.scales = getScales(y1, y2);

		chart.current?.refresh();
	}, [dates, y1, y2]);

	return (
		<Chart
			type="bar" ref={chart} className={props.className}
			data={chartData} options={chartOptions} plugins={chartPlugins}
		/>
	);
}

const scaleX = {
	type: 'timeseries',
	time: {
		unit: 'day',
		displayFormats: {
			day: 'yyyy-MM-dd',
			month: 'yyyy-MM',
		}
	},
	grid: { color: '#ebedef', display: true },
} as any;

function getScales(y1: FundMultiChartProps['y1'], y2: FundMultiChartProps['y2']): ChartOptions<'line' | 'bar'>['scales'] {
	const scales ={
		x: scaleX,
		y1: getScaleY1(y1),
	} as any;
	const scaleY2 = getScaleY2(y2);
	if (scaleY2) {
		scales.y2 = scaleY2;
	}
	return scales;
}

function getScaleY1(y1: FundMultiChartProps['y1']) {
	switch (y1.type) {
		case '기준가':
			return getScaleY1Price(y1.datasets);
		case '수익률':
			return getScaleY1Profit(y1.datasets);
	}
}

function getScaleY2(y2: FundMultiChartProps['y2']) {
	switch (y2?.type) {
		case '원본금액':
			return getScaleY2Size(y2.datasets);
		case '자산구성':
			return getScaleY2AssetFactors(y2.datasets);
		case '자산구성비중':
			return getScaleY2AssetFactorsRatio(y2.datasets);
	}
}

/// Y1축: 기준가
function getScaleY1Price(datasets: FundChartDataset[]): any {
	const stepSize = 50;
	return {
		type: 'linear',
		position: 'left',
		ticks: {
			stepSize,
			callback: (value: string | number) => value.toLocaleString(),
		},
		grid: { color: '#ebedef' },
		...getScaleMinMax(datasets, stepSize),
	};
}

/// Y1축: 수익률
function getScaleY1Profit(datasets: FundChartDataset[]): any {
	const stepSize = 5;
	return {
		type: 'linear',
		position: 'left',
		ticks: {
			stepSize: stepSize,
			callback: (value: string | number) => value.toLocaleString() + '﹪',
		},
		grid: { color: '#ebedef' },
		// max: 100 + 3*stepSize,
		// min: 100 - 1*stepSize,
		...getScaleMinMax(datasets, stepSize),
	}
}

/// Y2축: 원본금액/자산구성
function getScaleY2Size(_datasets: FundChartDataset[]): any {
	const stepSize = 1000000000;
	return {
		type: 'linear',
		position: 'right',
		ticks: {
			stepSize,
			callback: (value: string | number) => {
				if (isNumber(value)) {
					return (value / 100000000).toLocaleString() + '억';
				} else {
					return value;
				}
			}
		},
		grid: {
			drawOnChartArea: false, // only want the grid lines for one axis to show up
		},
		suggestedMax: 5000000000,
		suggestedMin: 0,
	}
}

/// Y2축 자산구성
function getScaleY2AssetFactors(datasets: FundChartDataset[]): any {
	const scale = getScaleY2Size(datasets);
	scale.stacked = true;
	return scale;
}

/// Y2축: 자산구성비중
function getScaleY2AssetFactorsRatio(_datasets: FundChartDataset[]): any {
	const stepSize = 5;
	return {
		type: 'linear',
		position: 'right',
		ticks: {
			stepSize: stepSize,
			callback: (value: string | number) => value.toLocaleString() + '﹪',
		},
		grid: {
			drawOnChartArea: false, // only want the grid lines for one axis to show up
		},
		max: 100,
		min: 0,
		stacked: true,
	}
}

function getScaleMinMax(datasets: FundChartDataset[], stepSize: number): { max: number, min: number } {
	const maxValue = maxOf(datasets.map(d => maxOf(d.data) || 0)) || 0;
	const minValue = minOf(datasets.map(d => minOf(d.data) || 0)) || 0;

	const max = Math.ceil(maxValue / stepSize) * stepSize;
	const min = Math.floor(minValue / stepSize) * stepSize;
	const diff = max - min;

	if (diff === 1*stepSize) {
		return { max: max + stepSize, min: min - stepSize };
	} else if (diff === 2*stepSize) {
		return { max: max + stepSize, min };
	} else {
		return { max, min };
	}
}

function getNumbersFontFamily() {
	return "Merriweather";
}

function formatDate(date: string | Date) {
	if (isDate(date)) {
		return date.toLocaleDateString();
	}
	if (isString(date)) {
		if (date.length === 8) {
			return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
		}
		return date;
	}
	return date + '';
}
