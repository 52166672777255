import { SimulationTermSpec } from '../request';
import { DailyRollingTerms } from './DailyRollingTerms';
import { EveryNthDaysTerms } from './EveryNthDaysTerms';
import { TermArray } from './TermArray';
import { TermsProvider } from './types';

export * from './types';
export * from './TermArray';
export * from './DailyRollingTerms';
export * from './EveryNthDaysTerms';

export function createTerm(spec: SimulationTermSpec): TermsProvider {
	switch (spec.type) {
	case 'array':
		return new TermArray(spec);

	case 'daily-rolling':
		return new DailyRollingTerms(spec);

	case 'every-nth-days':
		return new EveryNthDaysTerms(spec);
	}
}
