import { map } from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { CSSProperties } from 'react';

import { TermArraySpec, DailyRollingTermsSpec, EveryNthDaysTermsSpec, SimulationTermSpec } from '../../lib/simulation/request/types';
import { InputTextNumbers } from '../../components/InputTextNumbers';

const termsOptions = [
	{label: '직접 지정', value: 'array'},
	{label: '지정간격으로 시작(기간고정)', value: 'daily-rolling'},
	{label: '매월 지정일자 시작(기간고정)', value: 'every-nth-days'},
];

interface Props {
	value: SimulationTermSpec | null | undefined;
	onChange: (e: {value: SimulationTermSpec}) => void;
	className?: string;
	style?: CSSProperties;
}
export function SimulationTermsForm({value, onChange, className, style}: Props) {
	const onChangeTermsType = (termsType: 'array' | 'daily-rolling' | 'every-nth-days') => {
		if (value && value.type !== termsType) {
			const terms = createDefaultTermsByType(termsType);
			onChange({value: terms});
		}
	};

	return (
		<div className={className} style={style}>
			<div>
				<label>
					<span className="mr-2">기간 유형:</span>
					<Dropdown value={value?.type} options={termsOptions} onChange={(e) => onChangeTermsType(e.value)} placeholder="시뮬레이션 기간"/>
				</label>
			</div>
			{value?.type === 'array' && <ArrayTermsForm value={value} onChange={onChange} />}
			{value?.type === 'daily-rolling' && <DailyRollingTermsForm value={value} onChange={onChange} />}
			{value?.type === 'every-nth-days' && <EveryNthDaysTermsForm value={value} onChange={onChange} />}
		</div>
	)
}

function createDefaultTermsByType(type: 'array' | 'daily-rolling' | 'every-nth-days'): SimulationTermSpec {
	switch (type) {
	case 'array':
		return {
			type: 'array',
			array: [
				{begin: '20160101', end: '20200630'}
			]
		};
	case 'daily-rolling':
		return {
			type: 'daily-rolling',
			begin: '20160101',
			end: '20200630',
			duration: {years: 1},
			step: {days: 1},
		};
	case 'every-nth-days':
		return {
			type: 'every-nth-days',
			begin: '20160101',
			end: '20200630',
			duration: {years: 1},
			days: [1],
		};
	default:
		throw new Error("Wrong terms type: " + type);
	}
}

interface ArrayTermsFormProps {
	value: TermArraySpec | null | undefined;
	onChange: (e: {value: TermArraySpec}) => void;
}
function ArrayTermsForm({value, onChange}: ArrayTermsFormProps) {
	const onChangeDate = (date: string, index: number, name: 'begin' | 'end') => {
		if (value) {
			const dates = value.array[index];
			dates[name] = date;
			const newValue = {...value, array: [...value.array]};
			onChange({value: newValue});
		}
	};
	const onDeleteDate = (index: number) => {
		if (value) {
			const newArray = [...value.array];
			newArray.splice(index, 1)
			const newValue = {...value, array: newArray};
			onChange({value: newValue});
		}
	};
	const onAddClick = () => {
		if (value) {
			const newDates = {begin: '20160101', end: '20220630'};
			const newValue = {...value, array: [...value.array, newDates]};
			onChange({value: newValue});
		}
	};

	const items = map(value?.array, (dates, i) => (
		<div className='my-2 flex gap-2' key={i}>
			<div className='py-1 text-base font-bold'>{i + 1}.</div>
			<label>
				<div>시작일:</div>
				<InputText className="p-inputtext-sm w-8rem" value={dates.begin} onChange={e => onChangeDate(e.target.value, i, 'begin')} />
			</label>
			<label>
				<div>종료일:</div>
				<InputText className="p-inputtext-sm w-8rem" value={dates.end} onChange={e => onChangeDate(e.target.value, i, 'end')} />
			</label>
			<div>
				<Button className="p-button-outlined p-button-sm ml-3" label="삭제" onClick={() => onDeleteDate(i)} />
			</div>
		</div>
	));

	return (
		<div>
			{items}
			<div className="my-2">
				<Button className="p-button-outlined p-button-sm" label="추가" onClick={onAddClick} />
			</div>
		</div>
	);
}

interface DailyRollingTermsFormProps {
	value: DailyRollingTermsSpec | null | undefined;
	onChange: (e: {value: DailyRollingTermsSpec}) => void;
}
function DailyRollingTermsForm({value, onChange}: DailyRollingTermsFormProps) {
	const onChangeProp = (propValue: string, name: 'begin' | 'end') => {
		if (value) {
			const newValue = {...value, [name]: propValue};
			onChange({value: newValue});
		}
	};
	const onChangeStep = (propValue: number | null | undefined, name: 'months' | 'days') => {
		if (value) {
			const newStep = {...value.step, [name]: propValue || undefined};
			const newValue = {...value, step: newStep};
			onChange({value: newValue});
		}
	};
	const onChangeDuration = (propValue: number | null | undefined, name: 'years' | 'months' | 'days') => {
		if (value) {
			const newDuration = {...value.duration, [name]: propValue || undefined};
			const newValue = {...value, duration: newDuration};
			onChange({value: newValue});
		}
	};

	return (
		<div>
			<div className='mt-2'>
				<label className="mr-2">기간범위</label>
				<InputText className="p-inputtext-sm w-6rem" value={value?.begin} onChange={e => onChangeProp(e.target.value, 'begin')} />
				<span className="mx-2">~</span>
				<InputText className="p-inputtext-sm w-6rem" value={value?.end} onChange={e => onChangeProp(e.target.value, 'end')} />
			</div>
			<div className='mt-2'>
				<label className="mr-2">시작일의 간격</label>
				<InputNumber inputClassName='w-6rem' value={value?.step.months} onValueChange={(e) => onChangeStep(e.value, 'months')} />
				<span className='ml-1 mr-3'>월</span>
				<InputNumber inputClassName='w-6rem' value={value?.step.days} onValueChange={(e) => onChangeStep(e.value, 'days')} />
				<span className='ml-1'>일</span>
			</div>
			<div className='mt-2'>
				<label className="mr-2">기간</label>
				<InputNumber inputClassName='w-6rem' value={value?.duration.years} onValueChange={(e) => onChangeDuration(e.value, 'years')} />
				<span className='ml-1 mr-3'>년</span>
				<InputNumber inputClassName='w-6rem' value={value?.duration.months} onValueChange={(e) => onChangeDuration(e.value, 'months')} />
				<span className='ml-1 mr-3'>월</span>
				<InputNumber inputClassName='w-6rem' value={value?.duration.days} onValueChange={(e) => onChangeDuration(e.value, 'days')} />
				<span className='ml-1'>일</span>
			</div>
		</div>
	);
}

interface EveryNthDaysTermsFormProps {
	value: EveryNthDaysTermsSpec | null | undefined;
	onChange: (e: {value: EveryNthDaysTermsSpec}) => void;
}
function EveryNthDaysTermsForm({value, onChange}: EveryNthDaysTermsFormProps) {
	const onChangeProp = (propValue: unknown, name: 'begin' | 'end' | 'days') => {
		if (value) {
			const newValue = {...value, [name]: propValue};
			onChange({value: newValue});
		}
	};
	const onChangeDuration = (propValue: number | null | undefined, name: 'years' | 'months' | 'days') => {
		if (value) {
			const newDuration = {...value.duration, [name]: propValue || undefined};
			const newValue = {...value, duration: newDuration};
			onChange({value: newValue});
		}
	};

	return (
		<div>
			<div className='mt-2'>
				<label className="mr-2">기간범위</label>
				<InputText className="p-inputtext-sm w-6rem" value={value?.begin} onChange={e => onChangeProp(e.target.value, 'begin')} />
				<span className="mx-2">~</span>
				<InputText className="p-inputtext-sm w-6rem" value={value?.end} onChange={e => onChangeProp(e.target.value, 'end')} />
			</div>
			<div className='mt-2'>
				<label>
					<div>매월 시작일자</div>
					<InputTextNumbers className="p-inputtext-sm w-6rem" value={value?.days} onChange={e => onChangeProp(e.value, 'days')} />
				</label>
			</div>
			<div className='mt-2'>
				<label className="mr-3">기간</label>
				<InputNumber inputClassName='w-6rem' value={value?.duration.years} onValueChange={(e) => onChangeDuration(e.value, 'years')} />
				<span className='ml-1 mr-3'>년</span>
				<InputNumber inputClassName='w-6rem' value={value?.duration.months} onValueChange={(e) => onChangeDuration(e.value, 'months')} />
				<span className='ml-1 mr-3'>월</span>
				<InputNumber inputClassName='w-6rem' value={value?.duration.days} onValueChange={(e) => onChangeDuration(e.value, 'days')} />
				<span className='ml-1'>일</span>
			</div>
		</div>
	);
}
