import { CSSProperties } from 'react';

import { CheckboxLabel } from '../../components/CheckboxLabel';
import { InputTextNumber } from '../../components/InputTextNumber';
import { SimulationEnvSpec } from '../../lib/simulation/request/types';

interface Props {
	value: SimulationEnvSpec | null | undefined;
	onChange: (e: {value: SimulationEnvSpec}) => void;
	className?: string;
	style?: CSSProperties;
}
export function SimulationEnvForm({value, onChange, className, style}: Props) {
	const onChangeProp = (propValue: unknown, name: keyof SimulationEnvSpec) => {
		if (value) {
			const newValue = {...value, [name]: propValue};
			onChange({value: newValue});
		}
	};

	return (
		<div className={className} style={style}>
			<label className="font-medium">환경조건</label>
			<div className="mt-3 flex gap-3">
				<label>
					<div>매매수수료:</div>
					<InputTextNumber className='w-6rem' value={value?.trade_fee_rate_p} type="float" onChange={(e) => onChangeProp(e.value, 'trade_fee_rate_p')} />
				</label>
				<label>
					<div>운용보수료:</div>
					<InputTextNumber className='w-6rem' value={value?.oper_cost_rate_p} type="float" onChange={(e) => onChangeProp(e.value, 'oper_cost_rate_p')} />
				</label>
			</div>
			<CheckboxLabel className="mt-3" label="금리적용" checked={value?.interest_rate} onChange={e => onChangeProp(e.checked, 'interest_rate')} />
			<div className='text-sm text-800 pl-5'>
				보유금액에 대하여 CD금리를 적용하여 이자수익을 계산합니다.<br/>
				이자수익은 일할 계산되며, 복리 계산되지 않습니다.
			</div>
			<CheckboxLabel className="mt-3" label="배당금적용" disabled onChange={e => onChangeProp(e.checked, 'ex_dividend')} />
			<div className='text-sm text-500 pl-5'>수정주가를 사용하므로 배당금은 적용할 수 없습니다</div>
			<CheckboxLabel className="mt-3" label="ETF 보유기간과세" checked={value?.etf_margin_tax} onChange={e => onChangeProp(e.checked, 'etf_margin_tax')} />
			<div className='text-sm text-800 pl-5'>
				ETF 보유기간과세 종목에 한하여 매입평균가를 기준으로 매매차익의 15.4%를 세금으로 계산합니다.<br/>
				보유기간과세는 매도시점에 계산되며, 시뮬레이션 종료 시점까지 매도하지 않은 보유분에 대해서는 계산되지 않습니다.
			</div>
		</div>
	);
}
