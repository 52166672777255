import { isEqual } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';

interface Props {
	value: number | null | undefined;
	type: 'integer' | 'float';
	onChange: (e: {value: number}) => void;
	className?: string;
}
export function InputTextNumber({value, type, onChange, className}: Props) {
	const [text, setText] = useState('');

	useEffect(() => {
		setText(value?.toString() || '');
	}, [value]);
	
	const onChangeText = (text: string) => {
		setText(text);
		try {
			const intVal = parseNumber(text, type);
			if (!isEqual(value, intVal)) {
				onChange({value: intVal});
			}
		} catch (err) {
			// ignore parseNumber failure
		}
	};

	return (
		<InputText className={className} value={text} onChange={e => onChangeText(e.target.value)} />
	);
}

function parseNumber(text: string, type: 'integer' | 'float'): number {
	switch (type) {
	case 'integer':
		{
			const intVal = parseInt(text)
			if (isNaN(intVal)) throw new Error(text + " is NaN!");
			return intVal;
		}
	case 'float':
		{
			const floatVal = parseFloat(text)
			if (isNaN(floatVal)) throw new Error(text + " is NaN!");
			return floatVal;
		}
	}
}
