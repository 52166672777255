import { Alignment, Borders, Fill, Font } from 'exceljs';

const dark0: Fill = {type:'pattern', pattern: 'solid', fgColor: {argb: 'FFFFFFFF'}};
const dark1: Fill = {type:'pattern', pattern: 'solid', fgColor: {argb: 'FFDBE5F1'}};
const dark2: Fill = {type:'pattern', pattern: 'solid', fgColor: {argb: 'FFB8CCE4'}};
const dark3: Fill = {type:'pattern', pattern: 'solid', fgColor: {argb: 'FF95B3D7'}};
const dark4: Fill = {type:'pattern', pattern: 'solid', fgColor: {argb: 'FF75A3B7'}};
const dark9: Fill = {type:'pattern', pattern: 'solid', fgColor: {argb: 'FF335066'}};

const yellow0: Fill = {type:'pattern', pattern: 'solid', fgColor: {argb: 'FFFEFE09'}};
const yellow1: Fill = {type:'pattern', pattern: 'solid', fgColor: {argb: 'FFF3F309'}};
const yellow2: Fill = {type:'pattern', pattern: 'solid', fgColor: {argb: 'FFDDDD09'}};

class XlsxStyles {
	static readonly font: Font = {size: 9} as any;
	static readonly normal: Font = {size: 9} as any;
	static readonly bold: Font = {size: 9, bold: true} as any;
	static readonly italic: Font = {size: 9, italic: true} as any;
	static readonly bold_italic: Font = {size: 9, bold: true, italic: true} as any;

	static readonly left: Alignment = {horizontal: 'left'} as any;
	static readonly center: Alignment = {horizontal: 'center'} as any;
	static readonly right: Alignment = {horizontal: 'right'} as any;

	static readonly border = {
		tb: {
			top: {style:"thin"},
			left: {style:"thin"},
			bottom: {style:"thin"},
			right: {style:"thin"}
		} as Borders,
		no: {
			left: {style:"thin"},
			right: {style:"thin"}
		} as Borders,
		t: {
			top: {style:"thin"},
			left: {style:"thin"},
			right: {style:"thin"}
		} as Borders,
		b: {
			left: {style:"thin"},
			right: {style:"thin"},
			bottom: {style:"thin"},
		} as Borders,
	};

	static readonly dark0 = dark0;
	static readonly dark1 = dark1;
	static readonly dark2 = dark2;
	static readonly dark3 = dark3;
	static readonly dark4 = dark4;
	static readonly dark9 = dark9;

	static readonly fills0 = [dark0, dark1, dark2];
	static readonly fills1 = [dark1, dark2, dark3];
	static readonly fills2 = [dark2, dark3, dark4];
	static readonly fillsH = [yellow0, yellow1, yellow2];
}
export default XlsxStyles;