export interface TermArraySpec {
	type: "array";
	array: Array<{begin: string, end: string}>;
}
export interface DailyRollingTermsSpec {
	type: "daily-rolling";
	begin: string;
	end: string;
	duration: {years?: number, months?: number, days?: number};
	step: {months?: number, days?: number};
}
export interface EveryNthDaysTermsSpec {
	type: "every-nth-days";
	begin: string;
	end: string;
	duration: {years?: number, months?: number, days?: number};
	days: number[];
}

export type MarketCode = 'ETF' | 'KOSPI' | 'KOSDAQ';

export interface ProductPlanSpec {
	product_name?: string;
	product_code: string;
	product_market: MarketCode;
	etf_tax_type?: string | null;
}
export interface MoneyPlanSpec extends ProductPlanSpec {
	type: "money";
	id?: string;
	invest: number;
}
export interface HoldPlanSpec extends ProductPlanSpec {
	type: "hold";
	id?: string;
	invest: number;
}
export interface WavePlanSpec extends ProductPlanSpec {
	type: "wave";
	id?: string;
	invest: number;
	first_rate_p: number;
	range_low_p: number;
	range_high_p: number;
	margin_p: number;
	extend_lower: boolean;
}
export interface AutochaPlanSpec extends ProductPlanSpec {
	type: "autocha";
	id?: string;
	autocha_type: 'autocha1' | 'autocha2' | 'autocha3' | 'autocha4';
	invest: number;
}
export interface RegularHoldPlanSpec extends ProductPlanSpec {
	type: "regular-hold";
	id?: string;
	regular_interval?: number;
	regular_invest: number;
}
export interface RegularWavePlanSpec extends ProductPlanSpec {
	type: "regular-wave";
	id?: string;
	regular_interval?: number;
	regular_invest: number;
	first_rate_p: number;
	range_low_p: number;
	range_high_p: number;
	margin_p: number;
	extend_lower: boolean;
}
export interface RegularAutochaPlanSpec extends ProductPlanSpec {
	type: "regular-autocha";
	id?: string;
	autocha_type: 'autocha1' | 'autocha2' | 'autocha3' | 'autocha4';
	regular_interval?: number;
	regular_invest: number;
}

export interface SimulationEnvSpec {
	trade_fee_rate_p: number;
	oper_cost_rate_p: number;
	interest_rate: boolean;
	ex_dividend: boolean;
	etf_margin_tax: boolean;
}

export interface SimulationReportOptions {
	plan_report: boolean;
	matrix_report: boolean;
	terms_report: boolean;
}

export type SimulationTermSpec = TermArraySpec | DailyRollingTermsSpec | EveryNthDaysTermsSpec;
export type SimulationPlanSpec = MoneyPlanSpec | HoldPlanSpec | WavePlanSpec | AutochaPlanSpec | RegularHoldPlanSpec | RegularWavePlanSpec | RegularAutochaPlanSpec;
export interface SimulationRequest {
	name: string;
	terms: SimulationTermSpec;
	plans: SimulationPlanSpec[];
	env: SimulationEnvSpec;
	report: SimulationReportOptions;
}

export function getPlanTypeName(plan: SimulationPlanSpec): string | undefined {
	switch (plan.type) {
	case 'hold': return '단순거치';
	case 'money': return '현금거치';
	case 'wave': return '바스켓연속';
	case 'autocha': return getAutochaTypeName(plan.autocha_type);
	case 'regular-hold': return '적립식 단순거치';
	case 'regular-wave': return '적립식 바스켓연속';
	case 'regular-autocha': return '적립식 ' + getAutochaTypeName(plan.autocha_type);
	}
}

export function getAutochaTypeName(autochaType: string): string | undefined {
	switch (autochaType) {
	case 'autocha1': return '스페셜1';
	case 'autocha2': return '스페셜2';
	case 'autocha3': return '스페셜3';
	case 'autocha4': return '스페셜4';
	}
}
