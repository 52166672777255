import { Workbook, Worksheet } from 'exceljs';
import { flatten } from 'lodash';

import { TermResult, TermSummary } from '../matrix';
import s from '../../xlsx-styles';


const prepare_columns = (ws: Worksheet, summaries: TermSummary[]) => {
	const font = s.font;
	const right = s.right;
	const f0 = s.fills0;
	const f1 = s.fills1;
	const tb = s.border.tb;

	const columns: {width: number, style: any}[] = [
		{ width: 8, style: {font: font, fill: f0[0], border: tb} },
		{ width: 8, style: {font: font, fill: f0[0], border: tb} },
	];

	summaries.forEach((summary, idx) => {
		const f = idx % 2 ? f0 : f1;

		summary.products.forEach(() => {
			columns.push({ width: 7.5, style: {font: font, fill: f[0], border: tb, numFmt: '#,##0', alignment: right} });
			columns.push({ width: 7.5, style: {font: font, fill: f[0], border: tb, numFmt: '#,##0', alignment: right} });
		});

		columns.push({ width: 7.5, style: {font: font, fill: f[0], border: tb, numFmt: '0.0%', alignment: right} });
		columns.push({ width: 7.5, style: {font: font, fill: f[0], border: tb, numFmt: '0.0%', alignment: right} });
		columns.push({ width: 7.5, style: {font: font, fill: f[0], border: tb, numFmt: '0.0%', alignment: right} });
		columns.push({ width: 9, style: {font: font, fill: f[0], border: tb, numFmt: '#,##0.00', alignment: right} });
	});

	columns.forEach((column, idx) => {
		ws.getColumn(idx+1).width = column.width;
		ws.getColumn(idx+1).style = column.style;
	});
};


const write_column_titles = (ws: Worksheet, r: number, summaries: TermSummary[]) => {
	const f0 = s.fills0;

	const r1 = r,
		r2 = r+1;
	const row1 = ws.getRow(r1),
		row2 = ws.getRow(r2);
	let c = 1;

	ws.mergeCells(r1, c, r1, c+1);

	let cell = row1.getCell(c);
	cell.value = '구간';
	cell.fill = f0[1];

	cell = row2.getCell(c++);
	cell.value = '시작일';
	cell.fill = f0[1];

	cell = row2.getCell(c++);
	cell.value = '종료일';
	cell.fill = f0[1];

	summaries.forEach((summary, idx) => {
		const f = idx % 2 ? f0 : s.fills1;
		let cell;

		const products = summary.products;
		ws.mergeCells(r1, c, r1, c+4+products.length);
		cell = row1.getCell(c);
		cell.value = summary.id;
		cell.fill = f[1];
		cell.alignment = s.left;

		products.forEach(p => {
			let cell = row2.getCell(c++);
			cell.value = p.code + ' 시작가격';
			cell.fill = f[1];

			cell = row2.getCell(c++);
			cell.value = p.code + ' 종료가격';
			cell.fill = f[1];
		});

		cell = row2.getCell(c++);
		cell.value = '수익률';
		cell.fill = f[1];

		cell = row2.getCell(c++);
		cell.value = '일평균편입비';
		cell.fill = f[1];

		cell = row2.getCell(c++);
		cell.value = '매매금액比';
		cell.fill = f[1];

		cell = row2.getCell(c++);
		cell.value = '최종펀드기준가';
		cell.fill = f[1];
	});

	return r+2;
};


export class XlsxMultitermsProfitSheet {

	static write(wb: Workbook, term_results: TermResult[]): any {
		const ws = wb.addWorksheet('기간수익률');

		const summaries = flatten(term_results[0].summaries);
		prepare_columns(ws, summaries);

		let r = 1;
		r = write_column_titles(ws, r, summaries);

		term_results.forEach(term_result => {
			const row = ws.getRow(r++);
			let c = 1;

			let cell = row.getCell(c++);
			cell.value = term_result.term.begin;

			cell = row.getCell(c++);
			cell.value = term_result.term.end;

			flatten(term_result.summaries).forEach(summary => {
				let cell;

				summary.products.forEach(product => {
					let cell = row.getCell(c++);
					cell.value = summary.first_prices[product.code];

					cell = row.getCell(c++);
					cell.value = summary.last_prices[product.code];
				});

				cell = row.getCell(c++);
				cell.value = summary.value / summary.invest_value - 1;

				cell = row.getCell(c++);
				cell.value = summary.avg_volume_rate || '';

				cell = row.getCell(c++);
				cell.value = summary.trade_amount_rate;

				cell = row.getCell(c++);
				cell.value = summary.right_shares_price;
			});
		});
	}
}
