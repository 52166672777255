import { Fill, Workbook, Worksheet } from 'exceljs';
import { first, isEmpty, isFunction } from 'lodash';
import { MatrixPlans } from '../matrix';
import { Plan } from '../plans/Plan';
import { PlanRegularSavings } from '../plans/PlanRegularSavings';
import { PlanSingle } from '../plans/PlanSingle';
import { KOSPI } from '../product';
import { SimulationEnv } from '../SimulationEnv';

import s from '../../xlsx-styles';

interface Description {
	name: string;
	text: string;
}

export class XlsxMatrixProfitSheet {
	static write(wb: Workbook, matrix_plans: MatrixPlans, env: SimulationEnv): any {
		const ws = wb.addWorksheet('수익률분석');

		let r = 1;
		const descriptions: Description[] = [];

		const font = s.font;
		const right = s.right;
		const columns: {width: number, style: any}[] = [
			{ width: 7, style: {font: font} },
			{ width: 7, style: {font: font, numFmt: '#,##0', alignment: right} },
			{ width: 7, style: {font: font, numFmt: '#,##0', alignment: right} },
			{ width: 7, style: {font: font, numFmt: '0.0%', alignment: right} },

			{ width:10, style: {font: font} },
			{ width: 6, style: {font: font, numFmt: '0%', alignment: right} },
			{ width: 6, style: {font: font, numFmt: '0%', alignment: right} },
			{ width: 6, style: {font: font, numFmt: '0%', alignment: right} },
			{ width: 6, style: {font: font, numFmt: '0.0%', alignment: right} },
			{ width: 6, style: {font: font, numFmt: '0%', alignment: right} },
			{ width: 6, style: {font: font, alignment: right} },
			{ width:10, style: {font: font, numFmt: '#,##0', alignment: right} },

			{ width: 7, style: {font: font, numFmt: '0.0%', alignment: right} },
			{ width: 7, style: {font: font, numFmt: '0.0%', alignment: right} },
			{ width: 7, style: {font: font, numFmt: '0.0%', alignment: right} },
			{ width: 7, style: {font: font, numFmt: '0.0%', alignment: right} },
			{ width: 7, style: {font: font, numFmt: '0.0%', alignment: right} },
			{ width: 7, style: {font: font, numFmt: '0.0%', alignment: right} },
			{ width: 7, style: {font: font, numFmt: '0.0%', alignment: right} },
			{ width: 7, style: {font: font, numFmt: '0.0%', alignment: right} },

			{ width: 8, style: {font: font, numFmt: '0.0%', alignment: right} },
			{ width: 8, style: {font: font, numFmt: '0.0%', alignment: right} },
			{ width: 7, style: {font: font, numFmt: '0.0%', alignment: right} },
			{ width: 9, style: {font: font, numFmt: '#,##0.00', alignment: right} },
		];
		columns.forEach((column, idx) => {
			ws.getColumn(idx+1).width = column.width;
			ws.getColumn(idx+1).style = column.style;
		});


		const term = matrix_plans.term;
		const duration = Date.from_ymd(term.end).monthly_duration_from(Date.from_ymd(term.begin));
		const row = ws.addRow([
			(term.name ? term.name+', ' : '') +
			term.begin +'~'+ term.end +
			', '+ duration.months + (duration.days ? (' + '+ duration.days +'/'+ duration.days_last_month) : '') + '개월'
		]);
		row.getCell(1).font = font;
		r++;


		const add_row = (f: Fill[], values: any[]) => {
			const row = ws.addRow(values);

			if (f) {
				let i = 1;
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
			}

			let i = 1;
			const tb = s.border.tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;
			row.getCell(i++).border = tb;

			return row;
		};


		add_row(s.fills1, [
			'종목', '', '', '',
			'운용방식/매매조건', '', '', '', '', '', '', '',
			'수익/비용 분석', '', '', '', '', '', '', '',
			'비고', '', '', '']);
		ws.mergeCells('A'+r+':D'+r); ws.getCell('A'+r).alignment = s.center;
		ws.mergeCells('E'+r+':L'+r); ws.getCell('E'+r).alignment = s.center;
		ws.mergeCells('M'+r+':T'+r); ws.getCell('M'+r).alignment = s.center;
		ws.mergeCells('U'+r+':X'+r); ws.getCell('U'+r).alignment = s.center;
		r++;

		add_row(s.fills1, [
			'종목코드', '시작가', '최종가', '상승률',
			'투자방식', '최초비율', '하한가격', '상한가격', '매매차익', '2차조건', '리밸런싱', '총투자금액',
			'수익률', '매매수익률', '평가수익률', '이자수익률', '배당수익률', '매매수수료율', '세금비율', '운용보수료율',
			'차익실현률', '매매금액比', '최종편입비', '최종펀드기준가']);
		r++;


		const write_plan_single_row = (p: PlanSingle, f: Fill[]) => {
			const I = p.all_invest_value;
			const V = p.value;
			const info = p.get_plan_info();

			const d: any = {
				invest: I,
				profit: V / I - 1,
				sold_profit: p.sold_profit / I,
				volume_profit: p.volume.profit / I,
				interest_ernings: p.interest_ernings / I,
				dividend_ernings: p.dividend_ernings / I,
				fee: p.fee / I,
				tax: p.tax / I,
				oper_cost: p.oper_cost / I,
				sold_cost: p.sold_cost / I,
				trade_amount: p.trade_amount / I,
				volume_rate: p.volume.value / V,
				invest_way: '단일/'+info.type,
				product: p.product.code,
				p0: p.first_tick?.value,
				pn: p.last_tick?.value,
				p_raise: {formula: 'C'+r+ '/B'+r+'-1'},
				first_rate: info.first_rate,
				range_low: info.range_low,
				range_high: info.range_high,
				margin_rate: info.margin_rate,
				cumul_rate: info.cumul_rate,
				right_shares_price: p.right_shares_price(),
			};

			add_row(f, [
				d.product,
				d.p0 || '',
				d.pn || '',
				d.p_raise || '',
				d.invest_way || '',
				d.first_rate || '',
				d.range_low || '',
				d.range_high || '',
				d.margin_rate || '',
				d.cumul_rate || '',
				d.rebal_period || '',
				d.invest,
				d.profit,
				d.sold_profit,
				d.volume_profit,
				d.interest_ernings,
				d.dividend_ernings,
				d.fee,
				d.tax,
				d.oper_cost,
				d.sold_cost,
				d.trade_amount,
				d.volume_rate,
				d.right_shares_price,
			]);
			r++;
		};

		const write_plan_regular_row = (p: PlanRegularSavings, f: Fill[]) => {
			const I = p.all_invest_value;
			const V = p.sum_value();

			const d: any = {
				invest: I,
				profit: V / I - 1,
				sold_profit: p.sum_sold_profit() / I,
				volume_profit: p.sum_volume_profit() / I,
				interest_ernings: p.sum_interest_ernings() / I,
				dividend_ernings: p.sum_dividend_ernings() / I,
				fee: p.sum_fee() / I,
				tax: p.sum_tax() / I,
				oper_cost: p.sum_oper_cost() / I,
				sold_cost: p.sum_sold_cost() / I,
				trade_amount: p.sum_trade_amount() / I,
				volume_rate: p.sum_volume_value() / V,
				right_shares_price: p.right_shares_price(),
			};

			const subplan = first(p.subplans);
			if (subplan instanceof PlanSingle) {
				const info = subplan.get_plan_info();
				d.invest_way = '적립/'+info.type;
				d.product = subplan.product.code;
				d.p0 = subplan.first_tick?.value;
				d.pn = subplan.last_tick?.value;
				d.p_raise = {formula: 'C'+r+ '/B'+r+'-1'};
				d.first_rate = info.first_rate;
				d.range_low = info.range_low;
				d.range_high = info.range_high;
				d.margin_rate = info.margin_rate;
				d.cumul_rate = info.cumul_rate;

			} else {
				d.invest_way = '적립/다수종목';
				d.product = p.products().length + '종목';
			}

			add_row(f, [
				d.product,
				d.p0 || '',
				d.pn || '',
				d.p_raise || '',
				d.invest_way || '',
				d.first_rate || '',
				d.range_low || '',
				d.range_high || '',
				d.margin_rate || '',
				d.cumul_rate || '',
				d.rebal_period || '',
				d.invest,
				d.profit,
				d.sold_profit,
				d.volume_profit,
				d.interest_ernings,
				d.dividend_ernings,
				d.fee,
				d.tax,
				d.oper_cost,
				d.sold_cost,
				d.trade_amount,
				d.volume_rate,
				d.right_shares_price,
			]);
			r++;
		};

		const write_plan_row = (p: Plan, f: Fill[]) => {
			if (p instanceof PlanSingle) {
				return write_plan_single_row(p, f);

			} else if (p instanceof PlanRegularSavings) {
				return write_plan_regular_row(p, f);
			}
		};

		matrix_plans.plans?.forEach((group_plans, group_idx) => {
			group_plans.forEach(p => {
				const fills = p.has_tag('hl') ? s.fillsH : (group_idx % 2 ? s.fills1 : s.fills0);
				write_plan_row(p, fills);
			});
		});

		ws.addRow([]); r++;
		XlsxMatrixProfitSheet.write_desciptions(ws, env, r, descriptions);
	}


	static write_desciptions(ws: Worksheet, env: SimulationEnv, desc_r: number, descriptions: Description[]) {
		const desc_font = {size: 8, italic: true, color: {argb: 'FF666666'}};

		if (!isEmpty(descriptions)) {
			descriptions.forEach(desc => {
				ws.addRow([desc.name + ': ', desc.text]);
				const cell = ws.getCell('A'+desc_r);
				cell.font = desc_font;
				cell.alignment = s.right;
				ws.getCell('B'+desc_r).font = desc_font;
				desc_r++;
			});

			ws.addRow([]); desc_r++;
		}

		let r = desc_r;
		let c;

		const interest_rate = env.interest_rate;
		let interest_rate_string = '없음';
		if (interest_rate) {
			interest_rate_string = isFunction(interest_rate) ? 'CD' : interest_rate;
		}
		ws.addRow(['금리', interest_rate_string]);
		c = ws.getCell('A'+r);
		c.font = desc_font; c.alignment = s.right;
		c = ws.getCell('B'+r);
		c.font = desc_font;
		c.alignment = s.right; c.numFmt = '0.00%';
		r++;

		ws.addRow(['매매수수료율', env.trade_fee_rate ? env.trade_fee_rate : '없음']);
		c = ws.getCell('A'+r);
		c.font = desc_font; c.alignment = s.right;
		c = ws.getCell('B'+r);
		c.font = desc_font; c.alignment = s.right; c.numFmt = '0.00%';
		r++;

		ws.addRow(['매도세율', KOSPI.SELL_TAX_RATE, '(매매수수료에 합산, ETF 종목은 없음)']);
		c = ws.getCell('A'+r);
		c.font = desc_font; c.alignment = s.right;
		c = ws.getCell('B'+r);
		c.font = desc_font; c.alignment = s.right; c.numFmt = '0.00%';
		c = ws.getCell('C'+r);
		c.font = desc_font; c.alignment = s.left;
		r++;

		ws.addRow(['운영보수료율', env.oper_cost_rate ? env.oper_cost_rate : '없음']);
		c = ws.getCell('A'+r);
		c.font = desc_font; c.alignment = s.right;
		c = ws.getCell('B'+r);
		c.font = desc_font; c.alignment = s.right; c.numFmt = '0.00%';
		r++;


		r = desc_r;

		c = ws.getCell('G'+r);
		c.value = '매매수익률: ';
		c.font = desc_font; c.alignment = s.right;
		c = ws.getCell('H'+r);
		c.value = '∑(매도수량 × (매도가격 - 매입가격¹)) / 투자금액²';
		c.font = desc_font; c.alignment = s.left;
		r++;

		c = ws.getCell('G'+r);
		c.value = '평가수익률: ';
		c.font = desc_font; c.alignment = s.right;
		c = ws.getCell('H'+r);
		c.value = '(최종보유수량 × (최종가격 - 매입가격¹)) / 투자금액²';
		c.font = desc_font; c.alignment = s.left;
		r++;

		c = ws.getCell('G'+r);
		c.value = '차익실현률: ';
		c.font = desc_font; c.alignment = s.right;
		c = ws.getCell('H'+r);
		c.value = '∑(매도수량 × 매입가격¹) / 투자금액²';
		c.font = desc_font; c.alignment = s.left;
		r++;

		c = ws.getCell('G'+r);
		c.value = '매매금액比: ';
		c.font = desc_font; c.alignment = s.right;
		c = ws.getCell('H'+r);
		c.value = '∑(체결수량 × 체결가격) / 투자금액²';
		c.font = desc_font; c.alignment = s.left;
		r++;

		c = ws.getCell('G'+r);
		c.value = '최종편입비: ';
		c.font = desc_font; c.alignment = s.right;
		c = ws.getCell('H'+r);
		c.value = '주식평가액 / (주식평가액 + 운용보수 차감전 현금)';
		c.font = desc_font; c.alignment = s.left;
		r++;

		c = ws.getCell('G'+r);
		c.value = '최종펀드기준가: ';
		c.font = desc_font; c.alignment = s.right;
		c = ws.getCell('H'+r);
		c.value = '최초펀드기준가가 1,000원일 때 최종순자산평가액 / 펀드권리좌수';
		c.font = desc_font; c.alignment = s.left;
		r++;

		r = desc_r;

		c = ws.getCell('M'+r);
		c.value = '주¹: ';
		c.font = desc_font; c.alignment = s.right;
		c = ws.getCell('N'+r);
		c.value = '매입가격은 후입선출법으로 계산';
		c.font = desc_font; c.alignment = s.left;
		r++;

		c = ws.getCell('M'+r);
		c.value = '주²: ';
		c.font = desc_font; c.alignment = s.right;
		c = ws.getCell('N'+r);
		c.value = '적립식의 경우 투자금액은 기간내 적립금액의 총합';
		c.font = desc_font; c.alignment = s.left;
		r++;
	}
}
