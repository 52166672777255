import { Fill, Workbook, Worksheet } from 'exceljs';
import { flatten, uniq } from 'lodash';
import { Daily } from '.';
import { MatrixPlans } from '../matrix';

import { Plan } from '../plans/Plan';
import { Product } from '../product';
import s from '../../xlsx-styles';
import { SimulationEnv } from '../SimulationEnv';

const prepare_columns = (ws: Worksheet, products: Product[], plans: Plan[], env: SimulationEnv) => {
	const f0 = s.fills0;
	const f1 = s.fills1;

	const columns: {width: number, style: any}[] = [
		{ width: 8, style: {font: s.font, fill: f0[0], border: s.border.tb} },
	];

	if (env.interest_rate) {
		columns.push({ width: 8, style: {font: s.font, fill: f0[1], border: s.border.tb, numFmt: '#,##0.00', alignment: s.right}});
	}

	products.forEach(() => {
		columns.push({ width: 8, style: {font: s.font, fill: f0[0], border: s.border.tb, numFmt: '#,##0', alignment: s.right} });
	});

	plans.forEach((_, idx) => {
		columns.push({ width: 8, style: {font: s.font, fill: (idx % 2 ? f0 : f1)[0], border: s.border.tb, numFmt: '0.0%', alignment: s.right} });
		columns.push({ width: 8, style: {font: s.font, fill: (idx % 2 ? f0 : f1)[0], border: s.border.tb, numFmt: '0.0%', alignment: s.right} });
		columns.push({ width: 10, style: {font: s.font, fill: (idx % 2 ? f0 : f1)[0], border: s.border.tb, numFmt: '#,##0.00', alignment: s.right} });
	});

	columns.forEach((column, idx) => {
		ws.getColumn(idx+1).width = column.width;
		ws.getColumn(idx+1).style = column.style;
	});
}

const write_date_column = (ws: Worksheet, cell_num: number, dailies: Daily[], f: Fill[]): number => {
	let r = 1;

	let row = ws.getRow(r++);
	let cell = row.getCell(cell_num);
	cell.value = '';
	cell.fill = f[1];

	row = ws.getRow(r++);
	cell = row.getCell(cell_num);
	cell.value = '일자';
	cell.fill = f[1];

	dailies.forEach(d => {
		const row = ws.getRow(r++);
		const cell = row.getCell(cell_num);
		cell.value = d.date.format_ymd();
	});

	return cell_num + 1;
};

const write_interest_rate_column = (ws: Worksheet, cell_num: number, dailies: Daily[], f: Fill[]): number => {
	let r = 1;

	let row = ws.getRow(r++);
	let cell = row.getCell(cell_num);
	cell.value = '';
	cell.fill = f[1];

	row = ws.getRow(r++);
	cell = row.getCell(cell_num);
	cell.value = '금리';
	cell.fill = f[1];

	dailies.forEach(d => {
		const row = ws.getRow(r++);
		const cell = row.getCell(cell_num);
		cell.value = d.interest_rate;
	});

	return cell_num + 1;
};


const write_product_column = (ws: Worksheet, cell_num: number, product: Product, dailies: Daily[], f: Fill[]): number => {
	const pcode = product.code;
	let r = 1;

	let row = ws.getRow(r++);
	let cell = row.getCell(cell_num);
	cell.value = '';
	cell.fill = f[1];

	row = ws.getRow(r++);
	cell = row.getCell(cell_num);
	cell.value = pcode;
	cell.fill = f[1];

	dailies.forEach(d => {
		const row = ws.getRow(r++);
		const cell = row.getCell(cell_num);
		cell.value = d.ticks[pcode];
	});

	return cell_num + 1;
};

const write_plan_column = function (ws: Worksheet, cell_num: number, title: string | undefined, dailies: Daily[], f: Fill[]) {
	let r = 1;

	let row = ws.getRow(r++);

	let cell = row.getCell(cell_num);
	cell.value = title;
	cell.fill = f[1];
	cell.alignment = s.center;

	ws.mergeCells(1, cell_num, 1, cell_num+2);

	row = ws.getRow(r++);
	cell = row.getCell(cell_num);
	cell.value = '수익률';
	cell.fill = f[1];

	cell = row.getCell(cell_num+1);
	cell.value = '편입비';
	cell.fill = f[1];

	cell = row.getCell(cell_num+2);
	cell.value = '펀드기준가';
	cell.fill = f[1];

	dailies.reduce((fund, d) => {
		const volume_estimated_value = (d.volume_estimated_value === undefined ? (d.volume * d.tick) : d.volume_estimated_value);
		const value = d.money + d.interest_ernings - d.oper_cost - (d.applied_other_money || 0) + volume_estimated_value;

		const row = ws.getRow(r++);
		let cell = row.getCell(cell_num);
		cell.value = value / d.invest - 1;

		cell = row.getCell(cell_num+1);
		cell.value = volume_estimated_value / value;

		fund.holders += (d.invest - fund.invest) / fund.price;
		fund.price = value / fund.holders;
		fund.invest = d.invest;

		cell = row.getCell(cell_num+2);
		cell.value = fund.price;

		return fund;
	}, {holders: 0, price: 1000, invest: 0});

	return cell_num + 3;
};


export class XlsxMatrixDailySheet {
	static write(wb: Workbook, matrix_plans: MatrixPlans, env: SimulationEnv): any {
		const ws = wb.addWorksheet('일별수익률');

		const flatten_plans = flatten(matrix_plans.plans);

		const all_products = uniq(flatten(flatten_plans.map(p => p.products())));

		prepare_columns(ws, all_products, flatten_plans, env);

		const interest_column = env.interest_rate ? 1 : 0;
		let c = 2 + interest_column + all_products.length;
		let pi = 0;

		flatten_plans.forEach((p, idx) => {
			const dailies = p.get_dailies();

			if (idx === 0) {
				write_date_column(ws, 1, dailies, s.fills0);

				if (interest_column) {
					write_interest_rate_column(ws, 2, dailies, s.fills1);
				}
			}

			const products = p.products();
			while (products.includes(all_products[pi])) {
				write_product_column(ws, pi + 2 + interest_column, all_products[pi], dailies, s.fills0);
				pi++;
			}

			c = write_plan_column(ws, c, p.id, dailies, idx % 2 ? s.fills0 : s.fills1);
		});
	}
}
