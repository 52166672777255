import { cloneDeep, isEmpty, isError, last } from 'lodash';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useEffect, useRef, useState } from 'react';

import { ProgressMessage } from '../../lib/ProgressMessage';
import { SampleRequests, SimulationRequest, SimulationRequestStorage } from '../../lib/simulation/request';
import { Simulation } from '../../lib/simulation/simulation';
import { SimulationRequestForm } from './SimulationRequestForm';
import { SimulationRequestListBox } from './SimulationRequestListBox';
import { StatusBar } from './StatusBar';
import { NavBar } from '../../components/NavBar';
import { classNames } from 'primereact/utils';
import './styles.scss';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';

export function SimulationPage() {
	const [showNav, setShowNav] = useState<boolean>(false);
	const [selectedRequest, setSelectedRequest] = useState<SimulationRequest>();
	const [simRequests, setSimRequests] = useState<SimulationRequest[]>();
	const [confirmDeleteRequest, setConfirmDeleteRequest] = useState<SimulationRequest>();

	useEffect(() => {
		const storage = SimulationRequestStorage
		const requests = storage.readRequests();
		if (isEmpty(requests)) {
			setSimRequests(SampleRequests.read())
		} else {
			setSimRequests(requests);
		}
	}, []);

	const onEditRequest = (request: SimulationRequest) => {
		if (simRequests) {
			const newRequests = [...simRequests];
			const selectedIndex = simRequests.findIndex(r => r === selectedRequest);
			if (selectedIndex >= 0) {
				newRequests[selectedIndex] = request;
				setSelectedRequest(request);
			} else {
				newRequests.unshift(request);
			}
			SimulationRequestStorage.writeRequests(newRequests);
			setSimRequests(newRequests);

		} else {
			const newRequests = [request];
			SimulationRequestStorage.writeRequests(newRequests);
			setSimRequests(newRequests);
		}
	};

	const onAddRequest = (origin?: SimulationRequest) => {
		if (simRequests) {
			const request = cloneDeep(origin || selectedRequest || last(simRequests) || SampleRequests.read()[0]);
			delete (request as any)._id;

			for (;;) {
				const duplicated = simRequests.find(r => r.name === request.name);
				if (duplicated) {
					const matched = request.name.match(/\(([0-9]+)\)$/);
					if (matched && matched.length > 1) {
						const num = parseInt(matched[1]);
						request.name = request.name.replace(/\([0-9]+\)$/, `(${num + 1})`);
					} else {
						request.name = request.name + "(1)";
					}
				} else {
					break;
				}
			}
			const newRequests = [...simRequests, request];
			SimulationRequestStorage.writeRequests(newRequests);
			setSimRequests(newRequests);
			setSelectedRequest(request);
			console.log(newRequests);

		} else {
			const request = cloneDeep(SampleRequests.read()[0]);
			delete (request as any)._id;

			const newRequests = [request];
			SimulationRequestStorage.writeRequests(newRequests);
			setSimRequests(newRequests);
			setSelectedRequest(request);
		}
	}
	const onDeleteRequest = (removingRequest: SimulationRequest | undefined) => {
		setConfirmDeleteRequest(removingRequest)
	};

	const deleteRequest = (removingRequest: SimulationRequest | undefined) => {
		if (removingRequest && simRequests) {
			const newRequests = simRequests.filter(request => request !== removingRequest);
			SimulationRequestStorage.writeRequests(newRequests);
			setSimRequests(newRequests);

			if (selectedRequest && !newRequests.includes(selectedRequest)) {
				setSelectedRequest(undefined);
			}
		}
	}

	return <>
	<NavBar visible={showNav} onHide={() => setShowNav(false)}></NavBar>
	<div className='simulation-page-layout'>
		<div className="requests-view">
			<div className='flex align-items-center p-2'>
				<Button icon="pi pi-bars" text onClick={() => setShowNav(true)}/>
				<h2 className='my-0'>시뮬레이션</h2>
			</div>
			<div className="p-3">
				내 시뮬레이션 요청서
				<SimulationRequestListBox selection={selectedRequest} list={simRequests}  onChange={e => setSelectedRequest(e.value)} />
				<div className='mt-2'>
					<Button className="p-button-outlined p-button-sm mr-2" onClick={() => onAddRequest()}>추가</Button>
					<Button className="p-button-outlined p-button-sm mr-2" disabled={!selectedRequest}
						onClick={() => onDeleteRequest(selectedRequest)}>삭제</Button>
				</div>
				<ConfirmDialog visible={!!confirmDeleteRequest} onHide={() => setConfirmDeleteRequest(undefined)}
					message={`시뮬레이션을 삭제합니다: ${confirmDeleteRequest?.name}`}
					header="시뮬레이션 삭제" icon="pi pi-exclamation-triangle"
					accept={() => deleteRequest(confirmDeleteRequest)} />
			</div>
		</div>
		{selectedRequest &&
			<SimulationView
				request={selectedRequest} onEditRequest={onEditRequest}
				onClose={() => setSelectedRequest(undefined)}
				onCopy={onAddRequest}
				onDelete={onDeleteRequest}
			/>
		}
	</div>
	</>;
}

interface SimulationViewProps {
	request: SimulationRequest;
	className?: string;
	onEditRequest: (request: SimulationRequest) => void;
	onClose: () => void;
	onCopy: (request: SimulationRequest) => void;
	onDelete: (request: SimulationRequest) => void;
}
function SimulationView({request, ...props}: SimulationViewProps) {
	const [progress, setProgress] = useState<number>();
	const [simulation, setSimulation] = useState<{cancel: () => void}>();
	const [message, setMessage] = useState<string>();
	const [error, setError] = useState<Error>();
	const menuRef = useRef<Menu>(null);
	const menuItems: MenuItem[] = [
		{
			label: '복사하여 새로 만들기',
			icon: 'pi pi-copy',
			command: () => props.onCopy(request)
		},
		{
			label: '삭제',
			icon: 'pi pi-trash',
			command: () => props.onDelete(request)
		}
	];

	const onStartSimulation = async () => {
		try {
			setError(undefined);

			const progressMessage = new ProgressMessage();
			const {promise, control} = new Simulation(request).run(async (progress: number) => {
				setProgress(Math.floor(progress * 100));
				setMessage(progressMessage.getMessage(progress))
			});
			setSimulation(control);
			await promise;

		} catch (e: unknown) {
			console.log('*** ERROR:', e);
			if (isError(e)) {
				setError(e);
			} else {
				setError(new Error('Error: ' + e));
			}

		} finally {
			setSimulation(undefined);
		}
	};

	return (
		<div className={classNames("simulation-view flex flex-column", props.className)}>
			<div className='lg:hidden flex align-items-center p-2 flex-grow-0 shadow-1'>
				<Button icon="pi pi-arrow-left" text onClick={() => props.onClose()}/>
				<h2 className='my-0 flex-grow-1'>시뮬레이션 편집</h2>
				<Button icon="pi pi-cog" text onClick={(event) => menuRef.current?.toggle(event)} aria-controls="popup_menu" aria-haspopup /> </div>
				<Menu model={menuItems} popup ref={menuRef} id="popup_menu" />
			<SimulationRequestForm className='px-3 py-4 flex-grow-1 overflow-y-auto' value={request} onChange={e => props.onEditRequest(e.value)} />
			<div className="p-2 flex-grow-0">
				<div className='flex p-3 border-round-xl shadow-4'>
					<div className='mr-2'>
						<Button label='실행' icon='pi pi-play' disabled={!!simulation} onClick={onStartSimulation} />
					</div>
					<StatusBar className='flex-grow-1' progress={progress} message={error || message}/>
					{simulation &&
						<div className='ml-2'>
							<Button label='취소' icon="pi pi-ban" className='p-button-secondary' disabled={!simulation} onClick={() => simulation?.cancel()} />
						</div>
					}
				</div>
			</div>
		</div>
	);
}
