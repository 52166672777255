import axios from "axios";

export interface FundPrice {
	date: string;
	price?: number | null;
	input_size?: number | null;
	stock_size?: number | null;
	bond_size?: number | null;
	liquid_size?: number | null;
}

type Params = {code: string, begin_date: string, end_date: string};
type FundPricesResponse = { data: FundPrice[] };

export async function queryFundPrices(params: Params): Promise<FundPrice[]> {
	try {
		const resp = await axios.get('/data/fund/daily', { params })
		return (resp.data as FundPricesResponse).data;

		return mockQueryFundPrices();

	} catch (err: any) {
		const response = err?.response;
		if (response) {
			if (response.data?.message) {
				throw new Error(response.data?.message);
			}
			if (response.data) {
				throw new Error(response.data);
			}
		}
		console.log("error", err);
		throw err;
	}
}

async function mockQueryFundPrices(): Promise<FundPrice[]> {
	return [
		['2023-11-23', '1012.41', '0.46', '1181000000'],
		['2023-11-22', '1011.95', '1.45', '1181000000'],
		['2023-11-21', '1010.50', '1.95', '1181000000'],
		['2023-11-20', '1008.55', '-1.54', '1181000000'],
		['2023-11-17', '1010.09', '1.91', '1181000000'],
		['2023-11-16', '1008.18', '3.54', '1181000000'],
		['2023-11-15', '1004.64', '1.08', '1181000000'],
		['2023-11-14', '1003.56', '-0.73', '1181000000'],
		['2023-11-13', '1004.29', '-0.09', '1181000000'],
		['2023-11-10', '1004.38', '0.10', '1181000000'],
		['2023-11-09', '1004.28', '-0.26', '1043000000'],
		['2023-11-08', '1004.54', '-1.16', '1043000000'],
		['2023-11-07', '1005.70', '2.38', '801000000'],
		['2023-11-06', '1003.32', '1.64', '801000000'],
		['2023-11-03', '1001.68', '2.62', '801000000'],
		['2023-11-02', '999.06', '1.18', '801000000'],
		['2023-11-01', '997.88', '-0.65', '801000000'],
		['2023-10-31', '998.53', '0.48', '801000000'],
		['2023-10-30', '998.05', '3.48', '801000000'],
		['2023-10-27', '994.57', '-5.61', '680000000'],
		['2023-10-26', '1000.18', '1.91', '680000000'],
		['2023-10-25', '998.27', '4.95', '642000000'],
		['2023-10-24', '993.32', '-0.31', '642000000'],
		['2023-10-23', '993.63', '-6.31', '450000000'],
		['2023-10-20', '999.94', '-5.70', '450000000'],
		['2023-10-19', '1005.64', '3.72', '450000000'],
		['2023-10-18', '1001.92', '2.01', '450000000'],
		['2023-10-17', '999.91', '-2.89', '450000000'],
		['2023-10-16', '1002.80', '-2.10', '450000000'],
		['2023-10-13', '1004.90', '2.96', '450000000'],
		['2023-10-12', '1001.94', '4.41', '450000000'],
		['2023-10-11', '997.53', '-3.54', '450000000'],
		['2023-10-10', '1001.07', '0.17', '450000000'],
		['2023-10-06', '1000.90', '0.08', '450000000'],
		['2023-10-05', '1000.82', '0.08', '450000000'],
		['2023-10-04', '1000.74', '0.58', '450000000'],
		['2023-09-27', '1000.16', '0.08', '450000000'],
		['2023-09-26', '1000.08', '0.08', '450000000'],
		['2023-09-25', '1000.00', '0.00', '0'],
	].map(([date, price, _, size]) => {
		return {
			date,
			price: parseFloat(price),
			input_size: parseInt(size),
		}
	}).reverse();
}
