import { ListBox } from 'primereact/listbox';
import { CSSProperties, useMemo } from 'react';

import { SimulationRequest } from '../../lib/simulation/request/types';

interface Props {
	selection: SimulationRequest | null | undefined;
	list: SimulationRequest[] | null | undefined;
	onChange?: (e: {value: SimulationRequest}) => void;
	className?: string;
	style?: CSSProperties;
}
export function SimulationRequestListBox({selection, list, className, style, onChange}: Props) {
	const items = useMemo(() => {
		if (list) {
			return list.map(req => ({ label: req.name, value: req }));
		} else {
			return [];
		}
	}, [list]);

	return (
		<ListBox value={selection} options={items} onChange={onChange}
			className={className} style={style} dataKey="_id" />
	);
}
