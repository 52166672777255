export function column_to_letter(column: number, row?: number): string {
	let temp, letter = '';
	while (column > 0) {
		temp = (column - 1) % 26;
		letter = String.fromCharCode(temp + 65) + letter;
		column = (column - temp - 1) / 26;
	}

	return row === undefined ? letter : letter + row;
}

export function letter_to_column(letter: string): number {
	let column = 0;
	for (let i = 0; i < letter.length; i++) {
		column += (letter.charCodeAt(i) - 64) * Math.pow(26, letter.length - i - 1);
	}
	return column;
}
