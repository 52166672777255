import { isEmpty, last } from 'lodash';
import { DailyValue, queryInterests } from '../query-interests';

let _cacheReversed: DailyValue[];

export async function prepareInterests(begin_date: string, end_date: string): Promise<void> {
	const interests = await queryInterests('CD91', begin_date, end_date, []);
	_cacheReversed = interests.reverse();
}

export function getCD(date: Date): number {
	if (isEmpty(_cacheReversed)) throw new Error('CD is not prepared');

	const targetDate = date.format_ymd();
	const found = _cacheReversed.find(data => (data.date <= targetDate));
	return (found || last(_cacheReversed)!).value;
}
