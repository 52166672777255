import * as _ from 'lodash';
import { Product } from '../product/Product';
import { Asset } from './Asset';
import { SharedMoney } from './SharedMoney';

export class Assets {
	protected _list: Array<Asset>;

	constructor(...args: any[]) {
		this._list = [];
		this.push_all(args);
	}

	push(arg: Asset | Assets | Array<Asset>): void {
		if (arg instanceof Asset) {
			const asset = this.find(arg.product);
			if (asset) {
				asset.push(arg.entries);
			} else {
				this._list.push(arg.clone());
			}

		} else if (arg instanceof Assets) {
			_.each(arg.list, (asset) => { this.push(asset); });

		} else if (_.isArray(arg)) {
			_.each(arg, (asset) => { this.push(asset); });
		}
	}

	push_all(...args: Array<Asset | Assets | Array<Asset>>): void {
		for (let i = 0; i < args.length; ++i) {
			this.push(args[i]);
		}
	}

	find(prod: Product): Asset | undefined {
		if (prod instanceof Product) {
			return this._list.find(ast => ast.product === prod);

		} else {
			throw new Error('Only Product type is expected: ' + prod);
		}
	}

	get(prod: Product): Asset {
		let asset = this.find(prod);
		if (!asset) {
			asset = new Asset(prod);
			this.push(asset);
		}
		return asset;
	}

	get value(): number {
		return _.sumBy(this._list, 'value');
	}

	get list(): Array<Asset> {
		return this._list;
	}

	clone(): Assets {
		const c = new Assets();
		c._list = _.map(this._list, (a) => { return a.clone(); });
		return c;
	}

	copy(): Assets {
		return Assets.copy(this._list);
	}

	static copy(list: Array<Asset>): Assets {
		const c = new Assets();
		c._list = _.map(list, function (a) {
			if (a instanceof SharedMoney) {
				return a;

			} else {
				return a.clone();
			}
		});
		return c;
	}

	static from(list: Array<Asset>): Assets {
		const c = new Assets();
		c._list = list;
		return c;
	}
}
