import { CSSProperties } from 'react';

import { CheckboxLabel } from '../../components/CheckboxLabel';
import { SimulationReportOptions } from '../../lib/simulation/request/types';

interface Props {
	value: SimulationReportOptions | null | undefined;
	onChange: (e: {value: SimulationReportOptions}) => void;
	className?: string;
	style?: CSSProperties;
}
export function SimulationReportOptionsForm({value, onChange, className, style}: Props) {
	const onChangeProp = (propValue: unknown, name: keyof SimulationReportOptions) => {
		if (value) {
			const newValue = {...value, [name]: propValue};
			onChange({value: newValue});
		}
	};

	return (
		<div className={className} style={style}>
			<label className="font-medium">보고서 선택</label>
			<CheckboxLabel className="mt-3" label="조건보고서" checked={value?.plan_report} onChange={e => onChangeProp(e.checked, 'plan_report')} />
			<CheckboxLabel className="mt-3" label="기간별 종합 보고서" checked={value?.matrix_report} onChange={e => onChangeProp(e.checked, 'matrix_report')} />
			<CheckboxLabel className="mt-3" label="복수기간 요약 보고서" checked={value?.terms_report} onChange={e => onChangeProp(e.checked, 'terms_report')} />
		</div>
	);
}
