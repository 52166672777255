import { isEmpty, isObject, uniqBy } from 'lodash';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { CSSProperties, useEffect, useState } from 'react';

import { InputTextNumber } from '../../components/InputTextNumber';
import { MarketCode, SimulationPlanSpec } from '../../lib/simulation/request';
import { ItemInfo } from '../../lib/query-items';
import { searchItems } from '../../lib/search-items';
import { queryItems } from '../../lib/query-items';

type Product = ItemInfo;

const typeOptions = [
	{value: 'hold', label: '단순거치'},
	{value: 'money', label: '현금거치'},
	{value: 'wave', label: '바스켓연속'},
	{value: 'autocha', label: '스페셜'},
	{value: 'regular-hold', label: '적립식 단순거치'},
	{value: 'regular-wave', label: '적립식 바스켓연속'},
	{value: 'regular-autocha', label: '적립식 스페셜'},
];

// const marketOptions = ['KOSPI', 'KOSDAQ', 'ETF'];

const autochaTypeOptions = [
	{value: 'autocha1', label: '스페셜1'},
	{value: 'autocha2', label: '스페셜2'},
	{value: 'autocha3', label: '스페셜3'},
	{value: 'autocha4', label: '스페셜4'},
];

export interface PlanSpecCreateFormValue {
	type: string;
	product_codes: string[];
	product_market: MarketCode;
	invest: number | null;
	first_rate_p: number | null;
	range_low_p: number | null;
	range_high_p: number | null;
	margin_p: number | null;
	regular_interval: number | null;
	regular_invest: number | null;
	autocha_type: 'autocha1' | 'autocha2' | 'autocha3' | 'autocha4' | null;
}
interface PlanSpecCreateFormProps {
	value: PlanSpecCreateFormValue;
	onChange: (e: {value: PlanSpecCreateFormValue}) => void;
	className?: string;
	style?: CSSProperties;
}
function PlanSpecCreateForm({value, onChange, className, style}: PlanSpecCreateFormProps) {
	const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
	const [selectedProducts, _setSelectedProducts] = useState<Product[]>([]);

	const onChangeProp = (name: keyof PlanSpecCreateFormValue, val: unknown) => {
		const newValue = {...value, [name]: val};
		onChange({value: newValue});
	};

	const searchProducts = (e: AutoCompleteCompleteEvent) => {
		const query = e.query.trim();
		if (query.length) {
			searchItems({ query, includesVirtual: true })
				.then(setFilteredProducts)
				.catch(err => {
					console.error(err);
					setFilteredProducts([]);
				});

		} else {
			setFilteredProducts([]);
		}
	};

	const setSelectedProducts = (products: Product[] | null) => {
		// blur 이벤트 발생시 null이 들어오기 때문에 null이 아닌 경우에만 값을 변경한다
		if (products !== null) {
			_setSelectedProducts(products);
			onChangeProp('product_codes', products.map(p => p.code));
		}
	};

	const matchProductByQuery = (query: string, input: HTMLInputElement) => {
		console.log(`trying match product with '${value}'`);
		searchItems({ query, includesVirtual: true })
			.then(products => {
				if (products.length == 1) {
					setSelectedProducts(uniqBy([...selectedProducts, products[0]], product => product.code));
					setFilteredProducts([]);
					input.value = "";
				} else {
					console.log(`cannot match product with '${value}' because there is(are) ${products.length} items`);
					setFilteredProducts(products);
				}
			})
			.catch(err => {
				console.error(err);
				setFilteredProducts([]);
			});
	};

	const matchProductsByCodes = (codes: string[], input: HTMLInputElement) => {
		console.log(`trying fetch products with codes '${value}'`);
		queryItems(codes)
			.then(products => {
				if (products.length) {
					setSelectedProducts(uniqBy([...selectedProducts, ...products], product => product.code));
					setFilteredProducts([]);
					input.value = "";
				} else {
					console.log(`No such product with`);
				}
			})
			.catch(err => {
				console.error(err);
			});
	}

	const handleProductInput = (input: HTMLInputElement) => {
		const tokens = input.value.trim().split(/[^\w:]+/).filter(str => !!str.length);
		if (tokens.length == 1) {
			matchProductByQuery(tokens[0], input);
		} else if (tokens.length > 1) {
			matchProductsByCodes(tokens, input);
		}
	};

	// 키보드 입력에 따라 종목을 입력한다
	const onKeyUpOnProductInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.code === 'Enter' || e.code === 'Space' || e.code === 'Comma' || (e.code === 'KeyV' && e.ctrlKey)) {
			handleProductInput(e.target as HTMLInputElement);
		}
	};

	const onBlurOnProductInput = (e: React.FocusEvent<HTMLInputElement>) => {
		handleProductInput(e.target);
	}

	return <div className={className} style={style}>
		<div className="field">
			<label className="mr-2">조건유형</label>
			<Dropdown className='w-full' value={value.type} options={typeOptions} onChange={e => onChangeProp('type', e.value)}/>
		</div>
		{['autocha', 'regular-autocha'].includes(value.type) && <div className="field">
			<label className="mr-2">스페셜 유형</label>
			<Dropdown className='w-full' value={value.autocha_type} options={autochaTypeOptions} onChange={e => onChangeProp('autocha_type', e.value)}/>
		</div>}
		{['hold', 'wave', 'autocha', 'regular-wave', 'regular-autocha'].includes(value.type) && <div className="field">
			<label className="mr-2">종목코드</label>
			<div className='p-fluid'>
				<AutoComplete field="name" multiple
				value={selectedProducts} suggestions={filteredProducts}
				completeMethod={searchProducts} onChange={e => setSelectedProducts(e.value)}
				onKeyUp={onKeyUpOnProductInput} onBlur={onBlurOnProductInput} />
			</div>
			<small>1개 이상 입력할 경우 종목 개수만큼 조건이 추가됩니다</small>
		</div>}
		{['hold', 'money', 'wave', 'autocha'].includes(value.type) && <div className="field">
			<label className="mr-2">투자금액</label>
			<InputTextNumber className="p-inputtext-sm w-full" type="integer" value={value.invest} onChange={e => onChangeProp('invest', e.value)} />
		</div>}
		{['wave', 'regular-wave'].includes(value.type) && <div className="field">
			<label className="mr-2">최초비율(%)</label>
			<InputTextNumber className="p-inputtext-sm w-full" type="float" value={value.first_rate_p} onChange={e => onChangeProp('first_rate_p', e.value)} />
		</div>}
		{['wave', 'regular-wave'].includes(value.type) && <div className="field">
			<label className="mr-2">매매하한(%)</label>
			<InputTextNumber className="p-inputtext-sm w-full" type="float" value={value.range_low_p} onChange={e => onChangeProp('range_low_p', e.value)} />
		</div>}
		{['wave', 'regular-wave'].includes(value.type) && <div className="field">
			<label className="mr-2">매매상한(%)</label>
			<InputTextNumber className="p-inputtext-sm w-full" type="float" value={value.range_high_p} onChange={e => onChangeProp('range_high_p', e.value)} />
		</div>}
		{['wave', 'regular-wave'].includes(value.type) && <div className="field">
			<label className="mr-2">매매차익(%)</label>
			<InputTextNumber className="p-inputtext-sm w-full" type="float" value={value.margin_p} onChange={e => onChangeProp('margin_p', e.value)} />
		</div>}
		{['regular-hold', 'regular-wave', 'regular-autocha'].includes(value.type) && <div className="field">
			<label className="mr-2">적립간격(개월)</label>
			<InputTextNumber className="p-inputtext-sm w-full" type="integer" value={value.regular_interval} onChange={e => onChangeProp('regular_interval', e.value)} />
		</div>}
		{['regular-hold', 'regular-wave', 'regular-autocha'].includes(value.type) && <div className="field">
			<label className="mr-2">1회적립금액</label>
			<InputTextNumber className="p-inputtext-sm w-full" type="integer" value={value.regular_invest} onChange={e => onChangeProp('regular_invest', e.value)} />
		</div>}
	</div>
}

const defaultValue: PlanSpecCreateFormValue = {
	type: 'wave',
	product_codes: [],
	product_market: 'KOSPI',
	invest: null,
	first_rate_p: null,
	range_low_p: null,
	range_high_p: null,
	margin_p: null,
	regular_interval: null,
	regular_invest: null,
	autocha_type: null,
};

function createSimulationPlanSpecs(value: PlanSpecCreateFormValue): SimulationPlanSpec[] {
	switch (value.type) {
	case 'hold':
		return value.product_codes.map(code => ({
			type: 'hold',
			product_code: code,
			product_market: value.product_market,
			invest: value.invest || 0,
		}));
	case 'money':
		return [{
			type: 'money',
			product_code: 'v:KOSPI',
			product_market: 'KOSPI',
			invest: value.invest || 0,
		}];
	case 'wave':
		return value.product_codes.map(code => ({
			type: 'wave',
			product_code: code,
			product_market: value.product_market,
			invest: value.invest || 0,
			first_rate_p: value.first_rate_p || 0,
			range_low_p: value.range_low_p || 0,
			range_high_p: value.range_high_p || 0,
			margin_p: value.margin_p || 0,
			extend_lower: true,
		}));
	case 'autocha':
		return value.product_codes.map(code => ({
			type: 'autocha',
			autocha_type: value.autocha_type || 'autocha1',
			product_code: code,
			product_market: value.product_market,
			invest: value.invest || 0,
		}));
	case 'regular-hold':
		return value.product_codes.map(code => ({
			type: 'regular-hold',
			product_code: code,
			product_market: value.product_market,
			regular_interval: value.regular_interval || 1,
			regular_invest: value.regular_invest || 0,
		}));
	case 'regular-wave':
		return value.product_codes.map(code => ({
			type: 'regular-wave',
			product_code: code,
			product_market: value.product_market,
			first_rate_p: value.first_rate_p || 0,
			range_low_p: value.range_low_p || 0,
			range_high_p: value.range_high_p || 0,
			margin_p: value.margin_p || 0,
			extend_lower: true,
			regular_interval: value.regular_interval || 1,
			regular_invest: value.regular_invest || 0,
		}));
	case 'regular-autocha':
		return value.product_codes.map(code => ({
			type: 'regular-autocha',
			autocha_type: value.autocha_type || 'autocha1',
			product_code: code,
			product_market: value.product_market,
			regular_interval: value.regular_interval || 1,
			regular_invest: value.regular_invest || 0,
		}));
	default:
		throw new Error('This line cannot be reached');
	}
}

export interface PlanSpecCreateDialogProps {
	visible: boolean;
	initialValue: PlanSpecCreateFormValue | 'blank';
	onHide: () => void;
	onOk: (e: {value: SimulationPlanSpec[]}) => void;
	className?: string;
	style?: CSSProperties;
}
export function PlanSpecCreateDialog({visible, initialValue, onHide, onOk, className, style}: PlanSpecCreateDialogProps) {
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		if (isObject(initialValue)) {
			setValue({...initialValue});
		} else {
			setValue({...defaultValue});
		}
	}, [initialValue]);

	const onAccept = () => {
		console.log('value', value);
		const specs = createSimulationPlanSpecs(value);
		console.log('specs', specs);
		if (specs.length) {
			onOk({value: specs});
		}
	};

	const footer = (
		<div>
			<Button label="확인" className="p-button-primary" icon="pi pi-check" disabled={value.type !== 'money' && isEmpty(value.product_codes)} onClick={onAccept} />
			<Button label="취소" className="p-button-secondary" icon="pi pi-times" onClick={onHide} />
		</div>
	);

	return (
		<Dialog header="새 조건 입력" footer={footer} visible={visible} modal onHide={onHide} className={className} style={style}>
			<PlanSpecCreateForm value={value} onChange={e => setValue(e.value)}/>
		</Dialog>
	)
}
