export interface MonthlyDuration {
	months: number;
	days: number;
	days_last_month?: number;
}

export interface DateDuration {
	years?: number;
	months?: number;
	days?: number;
}

declare global {
	interface Date {
		format_ymd(delimiter?: string): string;
		monthly_duration_from(other: Date): MonthlyDuration;
		days_from(other: Date): number;
		add_years(years: number, months?: number, dates?: number): Date;
		add_months(months: number): Date;
		add_days(days: number): Date;
		add_duration(duration: DateDuration): Date;
		subtract_duration(duration: DateDuration): Date;
		clone(): Date;
	}

	interface DateConstructor {
		from_ymd(ymd: string): Date;
		add_duration(date: Date, duration: DateDuration): Date;
		subtract_duration(date: Date, duration: DateDuration): Date;
		range(begin: Date, end: Date, step: DateDuration): Date[];
	}
}

Date.prototype.format_ymd = function (delimiter?: string): string {
	const y = this.getFullYear();
	const m = this.getMonth() + 1;
	const d = this.getDate();

	if (delimiter) {
		return y + delimiter + (m < 10 ? '0' : '') + m + delimiter + (d < 10 ? '0' : '') + d;

	} else {
		return y + (m < 10 ? '0' : '') + m + (d < 10 ? '0' : '') + d;
	}
};

Date.prototype.monthly_duration_from = function (other: Date): MonthlyDuration {
	const r = other;
	const ly = this.getFullYear();
	const lm = this.getMonth() + 1;
	const ld = this.getDate();
	const ry = r.getFullYear();
	const rm = r.getMonth() + 1;
	const rd = r.getDate();
	const lmon = 12 * ly	+ lm;
	const rmon = 12 * ry	+ rm;

	return {
		months: lmon - rmon,
		days: ld - rd,
		days_last_month: new Date(ly, lm, 0).getDate()
	};
};

Date.prototype.days_from = function (other: Date): number {
	return Math.round((this.getTime()-other.getTime())/(1000*60*60*24));
};

Date.prototype.add_years = function (years: number, months?: number, dates?: number): Date {
	const y = this.getFullYear();
	const m = this.getMonth() + 1;
	const d = this.getDate();

	if (years) {
		this.setFullYear(y + years);

		if (m != this.getMonth() + 1) {
			this.setDate(0);
		}
	}

	if (months) {
		this.setMonth(m + months - 1);
		if (d != this.getDate()) {
			this.setDate(0);
		}
	}

	if (dates) {
		this.setDate(d + dates);
	}
	return this;
};

Date.prototype.add_months = function (months: number): Date {
	return this.add_years(0, months);
}

Date.prototype.add_days = function (days: number): Date {
	return this.add_years(0, 0, days);
}

Date.prototype.add_duration = function (duration: DateDuration): Date {
	return this.add_years(duration.years || 0, duration.months, duration.days);
};

Date.prototype.subtract_duration = function (duration: DateDuration): Date {
	return this.add_years(-(duration.years || 0), -(duration.months || 0), -(duration.days || 0));
};

Date.prototype.clone = function (): Date {
	return new Date(this.getTime());
};


Date.from_ymd = function (ymd: string): Date {
	let y: number;
	let m: number;
	let d: number;

	if (ymd.length == 8) {
		y = parseInt(ymd.substr(0, 4));
		m = parseInt(ymd.substr(4, 2)) - 1;
		d = parseInt(ymd.substr(6, 2));

	} else if (ymd.length == 10) {
		y = parseInt(ymd.substr(0, 4));
		m = parseInt(ymd.substr(5, 2)) - 1;
		d = parseInt(ymd.substr(8, 2));

	} else {
		throw new Error('날짜 입력 문자열 오류: ' + ymd);
	}

	if (y < 1000 || y > 9999 || m < 0 || m > 11 || d < 1 || d > 31) {
		throw new Error('날짜 입력 문자열 오류: ' + ymd);
	}

	const inst = new Date();
	inst.setFullYear(y, m, d);
	inst.setHours(0, 0, 0, 0);

	if (inst.getFullYear() != y || inst.getMonth() != m) {
		throw new Error('날짜 입력 문자열 오류: ' + ymd);
	}

	return inst;
};

Date.add_duration = function (date: Date, duration: DateDuration): Date {
	return new Date(date.getTime()).add_duration(duration);
};

Date.subtract_duration = function (date: Date, duration: DateDuration): Date {
	return new Date(date.getTime()).subtract_duration(duration);
};

const multiply_duration = function (duration: DateDuration, x: number): DateDuration {
	const d: DateDuration = {};
	if (duration.years) d.years = duration.years * x;
	if (duration.months) d.months = duration.months * x;
	if (duration.days) d.days = duration.days * x;
	return d;
};

Date.range = function (begin: Date, end: Date, step: DateDuration): Date[] {
	let x = 1;
	const list: Date[] = [];
	
	let cur = new Date(begin.getTime());
	while (cur < end) {
		list.push(cur);

		cur = Date.add_duration(begin, multiply_duration(step, x++));
	}

	return list;
};
