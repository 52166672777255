import colorLib, { Color, RGBA } from "@kurkle/color";

const COLORS = [
	"#4dc9f6",
	"#f67019",
	"#f53794",
	"#537bc4",
	"#acc236",
	"#166a8f",
	"#00a950",
	"#58595b",
	"#8549ba"
];

export function color(index: number) {
	return COLORS[index % COLORS.length];
}

export function transparentize(value: string | number[] | Color | RGBA, opacity: number) {
	const alpha = opacity === undefined ? 0.5 : 1 - opacity;
	return colorLib(value).alpha(alpha).rgbString();
}

export const CHART_COLORS = {
	darkred: "rgb(160, 28, 48)",
	red: "rgb(255, 99, 132)",
	orange: "rgb(255, 159, 64)",
	yellow: "rgb(255, 205, 86)",
	green: "rgb(75, 192, 192)",
	blue: "rgb(54, 162, 235)",
	purple: "rgb(153, 102, 255)",
	grey: "rgb(201, 203, 207)",
};

const NAMED_COLORS = [
	CHART_COLORS.red,
	CHART_COLORS.orange,
	CHART_COLORS.yellow,
	CHART_COLORS.green,
	CHART_COLORS.blue,
	CHART_COLORS.purple,
	CHART_COLORS.grey,
];

export function namedColor(index: number) {
	return NAMED_COLORS[index % NAMED_COLORS.length];
}
