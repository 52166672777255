import { isArray } from 'lodash';

import { SimulationRequest } from './types';

let requestId = 0;

export function newId() {
	return ++requestId;
}

class SimulationRequestStorage {
	constructor(private storageKey: string) {}

	readRequests(): SimulationRequest[] {
		const data = localStorage.getItem(this.storageKey);
		// console.log('read', data);
		if (data) {
			try {
				const storage = JSON.parse(data);
				if (!isArray(storage?.request_list)) throw new Error('Wrong data: ' + data);

				storage.request_list.forEach((request: any) => {
					if (!request.type && request.$type) {
						request.type = request.$type;
						delete request.$type;
					}
					request._id = newId();
					if (isArray(request.plans)) {
						request.plans.forEach((plan: any) => {
							plan._id = newId();
						})
					}
				});
				const requests = (storage.request_list as SimulationRequest[]).sort((a, b) => {
					if (a.name > b.name) {
						return 1;
					} else if (a.name < b.name) {
						return -1;
					}
					return 0;
				});
				return requests;

			} catch (e) {
				console.log('readRequests', e);
			}
		}
		return [];
	}

	writeRequests(requests: SimulationRequest[]) {
		const data = JSON.stringify({request_list: requests});
		requests.forEach((request: any) => {
			if (!request._id) {
				request._id = newId();
			}
			if (isArray(request.plans)) {
				request.plans.forEach((plan: any) => {
					if (!plan._id) {
						plan._id = newId();
					}
				})
			}
		})
		requests = requests.sort((a, b) => {
			if (a.name > b.name) {
				return 1;
			} else if (a.name < b.name) {
				return -1;
			}
			return 0;
		});
		// console.log('write', data);
		localStorage.setItem(this.storageKey, data);
	}
}

const STORAGE_KEY = "monkey-eyes-simulations";

export default new SimulationRequestStorage(STORAGE_KEY);
