import { filter, first, isFunction, last, partition } from 'lodash';

import { DayTick, isBaseTick, isOpeningTick, SingleTick } from '../../query-ticks';
import { SimulationEnv } from '../SimulationEnv';
import { PlanSheetsBased, PlanSheetsBasedOptions } from './PlanSheetBased';
import { PlanInfo } from './PlanSingle';
import { Deal, Sheet, Sheets } from './sheets';
import { FcondInput, FcondOutput, WoldSheet, WoldStub } from './wold-stub';
import assert from 'assert';


class PlanWaveSheets implements Sheets {
	protected _usheets: Sheet[];
	protected _lsheets: Sheet[];
	protected _cumul_rate: number;

	constructor(usheets: Sheet[], lsheets: Sheet[], cumul_rate: number) {
		this._usheets = usheets;
		this._lsheets = lsheets;
		this._cumul_rate = cumul_rate;
	}

	nearest_sell(): Sheet | undefined {
		let sheet = first(this._lsheets);

		if (!sheet || sheet.svol < 1) {
			sheet = last(this._usheets);
			while (sheet && sheet.svol < 1) {
				this._lsheets.unshift(this._usheets.pop()!);
				sheet = last(this._usheets);
			}
		}
		return sheet;
	}

	nearest_buy(): Sheet | undefined {
		let sheet = last(this._usheets);

		if (!sheet || sheet.bvol < 1) {
			sheet = first(this._lsheets);
			while (sheet && sheet.bvol < 1) {
				this._usheets.push(this._lsheets.shift()!);
				sheet = first(this._lsheets);
			}
		}

		return sheet;
	}

	list(): Sheet[] {
		return this._usheets.concat(this._lsheets);
	}

	on_matched(deal: Deal, cvol: number, sheet: Sheet): void {
		if (deal === Deal.BUY) {
			sheet.bvol -= cvol;
			if (sheet.bvol < 1) {
				sheet.svol = sheet.svol_size;
			} else if (sheet.bvol_size !== sheet.svol_size) {
				sheet.svol = Math.floor(sheet.svol_size * (1 - (sheet.bvol / sheet.bvol_size)))
			} else {
				sheet.svol += cvol;
			}

		} else if (deal === Deal.SELL) {
			sheet.svol -= cvol;
			if (sheet.svol < 1) {
				sheet.bvol = sheet.bvol_size;
			} else if (sheet.bvol_size !== sheet.svol_size) {
				sheet.bvol = Math.floor(sheet.bvol_size * (1 - (sheet.svol / sheet.svol_size)));
			} else {
				sheet.bvol += cvol;
			}

			if (this._cumul_rate && sheet.svol === 0 && cvol === sheet.svol_size) { // 전량 완전 체결
				const bincrement = Math.floor(this._cumul_rate * sheet.bvol_size);
				const sincrement = Math.floor(this._cumul_rate * sheet.svol_size);
				if (bincrement && sincrement) {
					if (!sheet.org_bvol_size) sheet.org_bvol_size = sheet.bvol_size;
					if (!sheet.org_svol_size) sheet.org_svol_size = sheet.svol_size;
					sheet.bvol_size += bincrement;
					sheet.svol_size += sincrement;
					sheet.bvol = sheet.bvol_size;
					sheet.cumul_occur = (sheet.cumul_occur || 0) + 1;
				}
			}
		}
	}
}

export interface Range {
	low: number;
	high: number;
}

export interface PlanWaveOptions extends PlanSheetsBasedOptions {
	range: Range;
	first_rate: number | ((first_price: number) => number);
	margin_rate: number;
	extend_lower: boolean;
	cumul_rate?: number;
	suppress_insufficent_first_money?: boolean;
	first_vol?: number;
	first_price?: number;
	sheets?: PlanWaveSheets;
	lsheet_cnt?: number;
	usheet_cnt?: number;
}


export class PlanWave extends PlanSheetsBased {
	range: Range;
	first_rate: number | ((first_price: number) => number);
	first_vol: number | undefined;
	first_price: number | undefined;
	margin_rate: number;
	cumul_rate: number | undefined;
	extend_lower: boolean;
	lsheet_cnt: number | undefined;
	usheet_cnt: number | undefined;
	suppress_insufficent_first_money: boolean;
	auto_vol: number | undefined;
	protected _sheets: PlanWaveSheets | undefined;

	constructor(o: PlanWaveOptions, env: SimulationEnv) {
		super(o, env);
		this.range = o.range;
		this.first_rate = o.first_rate;
		this.margin_rate = o.margin_rate;
		this.cumul_rate = o.cumul_rate;
		this.extend_lower = o.extend_lower;
		this.lsheet_cnt = o.lsheet_cnt;
		this.usheet_cnt = o.usheet_cnt;
		this.suppress_insufficent_first_money = !!o.suppress_insufficent_first_money;
		this.first_vol = o.first_vol;
		this.first_price = o.first_price;
		this._sheets = o.sheets;
	}

	get_sheets(): Sheets | undefined {
		return this._sheets;
	}

	deferredFcondOutput: Promise<FcondOutput> | undefined;

	async beforeFeedDayTicks(dayticks: DayTick[]): Promise<void> {
		await super.beforeFeedDayTicks(dayticks);

		const firstDaytick = first(dayticks)!;
		const ticks = firstDaytick.ticksByCodeMap[this.product.code];
		const firstDayClosing = {
			value: last(ticks)!,
			pos: {date: Date.from_ymd(firstDaytick.date), idx_in_date: ticks.length - 1, cnt_in_date: ticks.length}
		};
		assert(firstDayClosing.value);

		const input = this.createFcondInput(firstDayClosing.value);
		this.deferredFcondOutput = WoldStub.deferRequestSheets(input);
	}

	async onSingleTick(single: SingleTick): Promise<void> {
		if (isBaseTick(single.pos)) return; // 기준가로는 체결시키지 않는다

		if (this.first_vol === undefined) {
			await this.dealFirstVol(single)
		}
		this.match_tick(single);
	}

	private async dealFirstVol(single: SingleTick): Promise<void> {
		const price = single.value;
		this.first_price = price;

		const output = await (() => {
			if (this.deferredFcondOutput) {
				return this.deferredFcondOutput;
			} else {
				const input = this.createFcondInput(price);
				return WoldStub.makeManySheets([input]).then(outputs => outputs[0]);
			}
		})();
		this.makeSheets(output);

		let volume = this.first_vol! - (this.volume?.amount || 0);
		let tradeoff = this.compute_tradeoff(price, volume);

		if (this.money.value < -tradeoff.money) {
			if (this.suppress_insufficent_first_money) {
				const tradeoff_one = this.compute_buy_tradeoff(price, 1);
				volume = Math.floor(this.money.value / -tradeoff_one.money);
				if (volume > 0) {
					tradeoff = this.compute_buy_tradeoff(price, volume);
				} else {
					volume = 0;
				}
			} else {
				console.log(single.pos.date, 'having vs need', this.money.value, -tradeoff.money);
				throw new Error("Insufficient money for buying the first volume");
			}
		}

		if (volume !== 0) this.tradeoff_assets(tradeoff, single.pos.date);
	}

	private makeSheets(output: FcondOutput): PlanWave {
		this.first_vol = output.first_vol;
		this.auto_vol = output.auto_vol;

		const top_sheet_num = 501 - Math.ceil(this.first_vol! / this.auto_vol!);
		let top_cutted_wold_sheets: WoldSheet[];
		if (output.usheet_cnt) {
			top_cutted_wold_sheets = output.sheets;
		} else {
			top_cutted_wold_sheets = filter(output.sheets, (source) => {
				return (source.sheet_num >= top_sheet_num);
			});
		}

		let top_cutted_sheets = top_cutted_wold_sheets.map(this.sheet_mapper);
		top_cutted_sheets = top_cutted_sheets.map(this.sheet_vol_cutter);

		const [sell_sheets, buy_sheets] = partition(top_cutted_sheets, (sheet) => {
			return (sheet.no <= 500);
		});

		this._sheets = new PlanWaveSheets(sell_sheets, buy_sheets, this.cumul_rate || 0);
		return this;
	}

	private createFcondInput(firstPrice: number): FcondInput {
		if (!this.product.market_code) throw new Error('product.market_code should be defined');

		const first_rate = isFunction(this.first_rate) ? this.first_rate(firstPrice) : this.first_rate;
		return {
			init_janak: this.initial_invest.value,
			init_amot: firstPrice,
			first_rate: first_rate * 100,
			first_vol: this.first_vol || 0,
			lower_rate: this.range.low * 100,
			upper_rate: this.range.high * 100,
			margin_rate: this.margin_rate * 100,
			market: this.product.market_code,
			init_vol: 0,
			usheet_cnt: this.usheet_cnt || 0,
			lsheet_cnt: this.lsheet_cnt || 0,
			extend_lower: this.extend_lower || false,
			highest_price: this.tickBounds?.highest,
			lowest_price: this.tickBounds?.lowest,
		};
	}

	sheet_mapper(source: WoldSheet): Sheet {
		return {
			bprc: source.i_amot,
			bvol_size: source.i_volume,
			sprc: source.o_amot,
			svol_size: source.o_volume,
			no: source.sheet_num,
			bvol: 0,
			svol: 0,
		};
	}

	sheet_vol_cutter(source: Sheet) {
		if (source.no > 500) {
			source.bvol = source.bvol_size;
			source.svol = 0;
		} else {
			source.bvol = 0;
			source.svol = source.svol_size;
		}
		return source;
	}

	protected match_tick(single: SingleTick): void {
		let matched = true;

		while (matched) {
			matched = false;
			if (this.match_buy_tick(single)) matched = true;
			if (this.match_sell_tick(single)) matched = true;
		}
	}

	protected match_buy_tick(single: SingleTick): boolean {
		const sheet = this._sheets!.nearest_buy();
		if (!sheet || sheet.bprc < single.value) return false;

		const money = this.money
		const bprc = isOpeningTick(single.pos) ? single.value : sheet.bprc; // 시초가 체결이면 시초가로 체결, 그 외에는 주문가로 체결
		let bvol = sheet.bvol;

		let tradeoff = this.compute_buy_tradeoff(bprc, bvol);
		if (money.value < -tradeoff.money) {
			const tradeoff_one = this.compute_buy_tradeoff(bprc, 1);
			bvol = Math.floor(money.value / -tradeoff_one.money);
			if (bvol < 1) return false;

			tradeoff = this.compute_buy_tradeoff(bprc, bvol);
		}

		this.tradeoff_assets(tradeoff, single.pos.date);
		this._sheets!.on_matched(Deal.BUY, bvol, sheet);
		return true;
	}

	protected match_sell_tick(single: SingleTick): boolean {
		const sheet = this._sheets!.nearest_sell();
		if (!sheet || sheet.sprc > single.value) return false;

		const sprc = isOpeningTick(single.pos) ? single.value : sheet.sprc; // 시초가 체결이면 시초가로 체결, 그 외에는 주문가로 체결
		const svol = Math.min((this.volume?.amount || 0), sheet.svol);
		if (svol < 1) return false;

		const tradeoff = this.compute_sell_tradeoff(sprc, svol);
		this.tradeoff_assets(tradeoff, single.pos.date);
		this._sheets!.on_matched(Deal.SELL, svol, sheet);
		return true;
	}

	get_plan_info(): PlanInfo {
		const first_rate = isFunction(this.first_rate) ? (this.first_price && this.first_rate(this.first_price)) : this.first_rate;
		return  {
			type: '바스켓연속',
			first_price: this.first_price,
			first_rate,
			range_low: this.range.low,
			range_high: this.range.high,
			margin_rate: this.margin_rate,
			cumul_rate: this.cumul_rate,
			invest_value: this.all_invest_value
		};
	}
}
