import { Fill, Workbook } from 'exceljs';
import { Plan } from '../plans/Plan';
import { PlanComposite } from '../plans/PlanComposite';
import { PlanSingle } from '../plans/PlanSingle';

import s from '../../xlsx-styles';


export class XlsxPlanInfoSheet {

	static write(wb: Workbook, p: Plan): any {
		const ws = wb.addWorksheet('설정정보');

		const f = s.fills0;
		const columns: {width: number, style: any}[] = [
			{ width: 7, style: {font: s.font, fill: f[0]} },
			{ width: 8, style: {font: s.font, fill: f[0]} },
			{ width: 8, style: {font: s.font, fill: f[0]} },

			{ width: 8, style: {font: s.font, fill: f[1]} },
			{ width: 8, style: {font: s.font, numFmt: '#,##0', alignment: s.right, fill: f[1]} },
			{ width: 6, style: {font: s.font, numFmt: '0%', alignment: s.right, fill: f[1]} },
			{ width: 6, style: {font: s.font, numFmt: '0%', alignment: s.right, fill: f[1]} },
			{ width: 6, style: {font: s.font, numFmt: '0%', alignment: s.right, fill: f[1]} },
			{ width: 6, style: {font: s.font, numFmt: '0.0%', alignment: s.right, fill: f[1]} },
			{ width: 6, style: {font: s.font, numFmt: '0%', alignment: s.right, fill: f[1]} },
			{ width:10, style: {font: s.font, numFmt: '#,##0', alignment: s.right, fill: f[1]} },
		];
		columns.forEach((column, idx) => {
			column.style.border = s.border.tb;
			ws.getColumn(idx+1).width = column.width;
			ws.getColumn(idx+1).style = column.style;
		});


		const add_row = function (f: Fill[] | undefined, values: any) {
			const row = ws.addRow(values);

			if (f) {
				let i = 1;
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[0];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
				row.getCell(i++).fill = f[1];
			}

			return row;
		};

		add_row(s.fills1, [
			'구분','', '',
			'설정정보', '', '', '', '', '', '']);
		ws.mergeCells('A1:C1'); ws.getCell('A1').alignment = s.center;
		ws.mergeCells('D1:K1'); ws.getCell('D1').alignment = s.center;

		add_row(s.fills1, [
			'종목','시작일', '종료일',
			'유형', '설정가격', '최초비율', '하한가격', '상한가격', '매매차익', '2차조건', '설정금액']);

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const write_plan_row = function (p: PlanSingle, f: Fill[]) {
			const info = p.get_plan_info();
			add_row(undefined, [
				p.product.code, p.term.begin, p.term.end,
				info.type,
				info.first_price,
				info.first_rate || '',
				info.range_low || '',
				info.range_high || '',
				info.margin_rate || '',
				info.cumul_rate || '',
				p.initial_invest.value + p.post_invest.value
			]);
		};

		if (p instanceof PlanSingle) {
			write_plan_row(p, s.fills0);

		} else if (p instanceof PlanComposite) {
			const write_leaf_plan_row = function (p: Plan) {
				if (p instanceof PlanSingle) {
					write_plan_row(p, s.fills0);

				} else if (p instanceof PlanComposite) {
					p.subplans.forEach(write_leaf_plan_row);
				}
			};

			write_leaf_plan_row(p);
		}
	}
}
