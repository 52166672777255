import { isObject } from 'lodash';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { CSSProperties, useEffect, useState } from 'react';

import { InputTextNumber } from '../../components/InputTextNumber';
import { MarketCode } from '../../lib/simulation/request';

// const marketOptions = ['KOSPI', 'KOSDAQ', 'ETF'];

export interface PlanSpecModifyFormValue {
	mod_product_market?: boolean;
	product_market: MarketCode;
	mod_invest?: boolean;
	invest: number | null;
	mod_first_rate_p?: boolean;
	first_rate_p: number | null;
	mod_range_low_p?: boolean;
	range_low_p: number | null;
	mod_range_high_p?: boolean;
	range_high_p: number | null;
	mod_margin_p?: boolean;
	margin_p: number | null;
	mod_regular_interval?: boolean;
	regular_interval: number | null;
	mod_regular_invest?: boolean;
	regular_invest: number | null;
}
interface PlanSpecModifyFormProps {
	value: PlanSpecModifyFormValue;
	onChange: (e: {value: PlanSpecModifyFormValue}) => void;
	className?: string;
	style?: CSSProperties;
}
function PlanSpecModifyForm({value, onChange, className, style}: PlanSpecModifyFormProps) {
	const onChangeProp = (name: keyof PlanSpecModifyFormValue, val: unknown) => {
		const newValue = {...value, [name]: val};
		if (!name.startsWith('mod_')) {
			(newValue as any)['mod_' + name] = true;
		}
		onChange({value: newValue});
	}

	return <div className={className} style={style}>
		{/* <div className="field">
			<Checkbox tabIndex={-1} checked={value.mod_product_market} onChange={e => onChangeProp('mod_product_market', e.checked)} />
			<label className="mx-2">시장구분</label>
			<Dropdown className="w-full" value={value.product_market} options={marketOptions} onChange={e => onChangeProp('product_market', e.value)}/>
		</div> */}
		<div className="field">
			<Checkbox tabIndex={-1} checked={!!value.mod_invest} onChange={e => onChangeProp('mod_invest', e.checked)} />
			<label className="mx-2">투자금액</label>
			<InputTextNumber className="p-inputtext-sm w-full" type="integer" value={value.invest} onChange={e => onChangeProp('invest', e.value)} />
		</div>
		<div className="field">
			<Checkbox tabIndex={-1} checked={!!value.mod_first_rate_p} onChange={e => onChangeProp('mod_first_rate_p', e.checked)} />
			<label className="mx-2">최초비율</label>
			<InputTextNumber className="p-inputtext-sm w-full" type="float" value={value.first_rate_p} onChange={e => onChangeProp('first_rate_p', e.value)} />
		</div>
		<div className="field">
			<Checkbox tabIndex={-1} checked={!!value.mod_range_low_p} onChange={e => onChangeProp('mod_range_low_p', e.checked)} />
			<label className="mx-2">매매하한</label>
			<InputTextNumber className="p-inputtext-sm w-full" type="float" value={value.range_low_p} onChange={e => onChangeProp('range_low_p', e.value)} />
		</div>
		<div className="field">
			<Checkbox tabIndex={-1} checked={!!value.mod_range_high_p} onChange={e => onChangeProp('mod_range_high_p', e.checked)} />
			<label className="mx-2">매매상한</label>
			<InputTextNumber className="p-inputtext-sm w-full" type="float" value={value.range_high_p} onChange={e => onChangeProp('range_high_p', e.value)} />
		</div>
		<div className="field">
			<Checkbox tabIndex={-1} checked={!!value.mod_margin_p} onChange={e => onChangeProp('mod_margin_p', e.checked)} />
			<label className="mx-2">매매차익</label>
			<InputTextNumber className="p-inputtext-sm w-full" type="float" value={value.margin_p} onChange={e => onChangeProp('margin_p', e.value)} />
		</div>
		<div className="field">
			<Checkbox tabIndex={-1} checked={!!value.mod_regular_interval} onChange={e => onChangeProp('mod_regular_interval', e.checked)} />
			<label className="mx-2">적립간격(개월)</label>
			<InputTextNumber className="p-inputtext-sm w-full" type="integer" value={value.regular_interval} onChange={e => onChangeProp('regular_interval', e.value)} />
		</div>
		<div className="field">
			<Checkbox tabIndex={-1} checked={!!value.mod_regular_invest} onChange={e => onChangeProp('mod_regular_invest', e.checked)} />
			<label className="mx-2">1회적립금액</label>
			<InputTextNumber className="p-inputtext-sm w-full" type="integer" value={value.regular_invest} onChange={e => onChangeProp('regular_invest', e.value)} />
		</div>
	</div>
}

const defaultValue: PlanSpecModifyFormValue = {
	product_market: 'KOSPI',
	invest: null,
	first_rate_p: null,
	range_low_p: null,
	range_high_p: null,
	margin_p: null,
	regular_invest: null,
	regular_interval: null,
};

export interface PlanSpecModifyDialogProps {
	visible: boolean;
	initialValue: PlanSpecModifyFormValue | 'blank';
	onHide: () => void;
	onOk: (e: {value: PlanSpecModifyFormValue}) => void;
	className?: string;
	style?: CSSProperties;
}
export function PlanSpecModifyDialog({visible, initialValue, onHide, onOk, className, style}: PlanSpecModifyDialogProps) {
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		if (isObject(initialValue)) {
			setValue({...initialValue});
		} else {
			setValue({...defaultValue});
		}
	}, [initialValue]);


	const onAccept = () => {
		onOk({value});
	};

	const footer = (
		<div>
			<Button label="확인" className="p-button-primary" icon="pi pi-check" onClick={onAccept} />
			<Button label="취소" className="p-button-secondary" icon="pi pi-times" onClick={onHide} />
		</div>
	);

	return (
		<Dialog header="조건 수정" footer={footer} visible={visible} modal onHide={onHide} className={className} style={style}>
			<PlanSpecModifyForm value={value} onChange={e => setValue(e.value)}/>
		</Dialog>
	)
}

