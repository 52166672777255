import { InputText } from 'primereact/inputtext';
import { CSSProperties } from 'react';

import { SimulationRequest } from '../../lib/simulation/request/types';
import { SimulationEnvForm } from './SimulationEnvForm';
import { SimulationPlansForm } from './SimulationPlansForm';
import { SimulationReportOptionsForm } from './SimulationReportOptionsForm';
import { SimulationTermsForm } from './SimulationTermsForm';

interface Props {
	value: SimulationRequest | null | undefined;
	onChange: (e: {value: SimulationRequest}) => void;
	className?: string;
	style?: CSSProperties;
}
export function SimulationRequestForm({value, onChange, className, style}: Props) {
	const onChangeProp = (propVal: unknown, name: keyof SimulationRequest) => {
		if (value) {
			const newValue = {...value, [name]: propVal};
			onChange({value: newValue});
		}
	}

	return (
		<div className={className} style={style}>
			<div>
				<label className='mr-2'>제목:</label>
				<InputText className="p-inputtext-sm max-w-30rem w-full" value={value?.name} onChange={e => onChangeProp(e.target.value, 'name')} />
			</div>
			<div className="grid mt-4">
				<div className="col-12 xl:col-5 mb-4">
					<SimulationTermsForm value={value?.terms} onChange={e => onChangeProp(e.value, 'terms')} />
				</div>
				<div className="col-12 lg:col-8 xl:col-4 mb-4">
					<SimulationEnvForm value={value?.env} onChange={e => onChangeProp(e.value, 'env')} />
				</div>
				<div className="col-12 lg:col-4 xl:col-3 mb-4">
					<SimulationReportOptionsForm value={value?.report} onChange={e => onChangeProp(e.value, 'report')} />
				</div>
			</div>
			<SimulationPlansForm value={value?.plans} onChange={e => onChangeProp(e.value, "plans")} />
		</div>
	);
}
