import * as _ from 'lodash';
import { MONEY } from '../product/ProductImplements';
import { Asset } from './Asset';

export class Money extends Asset {
	interest_ernings: number;

	constructor(amount: number) {
		super(MONEY);
		this.interest_ernings = 0;

		if (amount) this.push(amount);
	}

	get value(): number {
		return this._amount;
	}

	get price(): number | undefined {
		return this._amount ? 1 : undefined;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	push(amount: number | Money | Money[], price?: number) {
		if (_.isNaN(amount)) throw new Error('amount is NaN');

		if (_.isNumber(amount)) {
			if (amount < 0) throw new Error("Asset#push() allows only positive value of amount");

			this._amount += amount;
			this._lifo = [{amount: this.amount, price: 1}];

		} else if (_.isArray(amount)) {
			super.push(amount);

		} else if (amount instanceof Money) {
			if (this !== amount) this.push(amount.amount);
		}
	}

	clone() {
		return new Money(this.amount);
	}
}
