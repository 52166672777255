import { isString } from 'lodash';

import { createProduct } from '../product';
import { getPlanTypeName, SimulationPlanSpec } from '../request';
import { SimulationEnv } from '../SimulationEnv';
import { Term } from '../terms';
import { Plan } from './Plan';
import { PlanAutocha } from './PlanAutocha';
import { PlanHold } from './PlanHold';
import { PlanRegularSavings } from './PlanRegularSavings';
import { PlanWave } from './PlanWave';
import { PlanMoney } from './PlanMoney';

export interface PlanFactory {
	(term: Term): Plan;
}

function create_id(input: any, num: number): string {
	return '#'+num + '(' + getPlanTypeName(input) + ')';
}

export function createPlanFactories(specs: SimulationPlanSpec[], env: SimulationEnv): PlanFactory[] {
	return specs.map((spec, index): PlanFactory => {
		const src = {...spec};
		if (isString(src.id)) {
			src.id = src.id.trim();
		} else {
			src.id = create_id(src, index+1);
		}
		return createPlanFactory(src, env);
	});
}

function createPlanFactory(spec: SimulationPlanSpec, env: SimulationEnv): PlanFactory {
	switch (spec.type) {
	case 'money':
		return (term: Term): Plan => {
			return new PlanMoney({
				id: spec.id,
				product: createProduct(spec),
				term: term,
				invest: spec.invest,
			}, env);
		};
	case 'hold':
		return (term: Term): Plan => {
			return new PlanHold({
				id: spec.id,
				product: createProduct(spec),
				term: term,
				invest: spec.invest,
			}, env);
		};
	case 'wave':
		return (term: Term): Plan => {
			return new PlanWave({
				id: spec.id,
				product: createProduct(spec),
				term: term,
				invest: spec.invest,
				first_rate: spec.first_rate_p / 100.0,
				range: {low: spec.range_low_p / 100.0, high: spec.range_high_p / 100.0},
				margin_rate: spec.margin_p / 100.0,
				extend_lower: spec.extend_lower,
			}, env);
		};
	case 'autocha':
		return (term: Term): Plan => {
			return new PlanAutocha({
				id: spec.id,
				product: createProduct(spec),
				term: term,
				invest: spec.invest,
				type: spec.autocha_type,
			}, env);
		};
	case 'regular-hold':
		return (term: Term): Plan => {
			return new PlanRegularSavings({
				id: spec.id,
				invest: 0,
				term: term,
				regular_interval: spec.regular_interval || 1,
				regular_invest: spec.regular_invest,
				regular_plan_creator: (options: any) => {
					return new PlanHold({
						...options,
						product: createProduct(spec),
					}, env);
				},
			}, env);
		};
	case 'regular-wave':
		return (term: Term): Plan => {
			return new PlanRegularSavings({
				id: spec.id,
				invest: 0,
				term: term,
				regular_interval: spec.regular_interval || 1,
				regular_invest: spec.regular_invest,
				regular_plan_creator: (options) => {
					return new PlanWave({
						...options,
						product: createProduct(spec),
						first_rate: spec.first_rate_p / 100.0,
						range: {low: spec.range_low_p / 100.0, high: spec.range_high_p / 100.0},
						margin_rate: spec.margin_p / 100.0,
						extend_lower: spec.extend_lower,
					}, env);
				},
			}, env);
		};
	case 'regular-autocha':
		return (term: Term): Plan => {
			return new PlanRegularSavings({
				id: spec.id,
				invest: 0,
				term: term,
				regular_interval: spec.regular_interval || 1,
				regular_invest: spec.regular_invest,
				regular_plan_creator: (options) => {
					return new PlanAutocha({
						...options,
						type: spec.autocha_type,
						product: createProduct(spec),
					}, env);
				},
			}, env);
		};
	}
}
