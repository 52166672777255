import axios from 'axios';
import { MarketCode } from './simulation/request';

export interface ItemInfo {
	code: string;
	name: string;
	market: MarketCode;
	info_date: string;
	list_date: string;
	etf_tax_type: string;
}
export interface ItemsResponse {
	items: ItemInfo[];
}

export async function queryItems(codes: string[]): Promise<ItemInfo[]> {
	try {
		const resp = await axios.get('/data/items', {
			params: { item_cd: codes.join(',') }
		});
		return (resp.data as ItemsResponse).items;

	} catch (err: any) {
		const response = err?.response;
		if (response) {
			if (response.data?.message) {
				throw new Error(response.data?.message);
			}
			if (response.data) {
				throw new Error(response.data);
			}
		}
		console.log("error", err);
		throw err;
	}
}
