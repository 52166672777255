import { chunk } from "lodash";

export class Deferred<T> {
	promise: Promise<T>;
	resolve!: (value: T | PromiseLike<T>) => void;
	reject!: (reason?: any) => void;

	constructor() {
		this.promise = new Promise<T>((resolve, reject) => {
			this.resolve = resolve;
			this.reject = reject;
		});
	}
}

export class DeferredRequests<Input, Output> {
	list: {input: Input, deferred: Deferred<Output>}[] = [];

	push(input: Input): Promise<Output> {
		const deferred = new Deferred<Output>();
		this.list.push({input, deferred});
		return deferred.promise;
	}

	async executeWith(fn: (inputs: Input[]) => Promise<Output[]>, chunkSize: number): Promise<void> {
		const requestGroups = chunk(this.list, chunkSize);
		this.list = [];

		for (const requests of requestGroups) {
			try {
				const ouputs = await fn(requests.map(r => r.input));
				for (let i = 0; i < requests.length; i++) {
					requests[i].deferred.resolve(ouputs[i]);
				}
				
			} catch (err) {
				for (const {deferred} of requests) {
					deferred.reject(err);
				}
			}
		}
	}
}
