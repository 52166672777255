import { Workbook } from 'exceljs';

import { MatrixPlans } from '../matrix';
import { SimulationEnv } from '../SimulationEnv';
import { XlsxMatrixDailySheet } from './XlsxMatrixDailySheet';
import { XlsxMatrixMonthlySheet } from './XlsxMatrixMonthlySheet';
import { XlsxMatrixProfitSheet } from './XlsxMatrixProfitSheet';
import { writeWorkbookAsFile, Written } from '../../xlsx-writer';

export class XlsxMatrixWriter {
	static async write(matrix_plans: MatrixPlans, env: SimulationEnv, filename: string): Promise<Written> {
		const p_stime = new Date().getTime();
		const wb = new Workbook();
		
		XlsxMatrixProfitSheet.write(wb, matrix_plans, env);
		XlsxMatrixDailySheet.write(wb, matrix_plans, env);
		XlsxMatrixMonthlySheet.write(wb, matrix_plans);
		
		const written = await writeWorkbookAsFile(wb, filename)
		if (written.type === 'fs') {
			console.log('> ' + written.path + ' written. ' + ((new Date().getTime() - p_stime)/1000) + '초 소요됨.');
		}
		return written;
	}
}
