/* DEPRECATED
 * 배당금 정보를 시뮬레이션에 제공하기 위한 코드이지만,
 * 수정주가를 사용하기로 결정한 이상 배당금 정보는 더이상 필요없다.
 */

interface DailyProductMaster {
	base: number;
	ex_dividend: number;
}
type DailyProductMasterMap = {[date: string]: DailyProductMaster};
const masterMaps: { [code: string]: DailyProductMasterMap; } = { };

masterMaps['069500'] = {
	'20030429': {base: 7230, ex_dividend:220},
	'20040429': {base:11700, ex_dividend:200},
	'20041028': {base:10350, ex_dividend: 10},
	'20050428': {base:11900, ex_dividend:200},
	'20051028': {base:14950, ex_dividend: 10},
	'20060127': {base:17795, ex_dividend: 50},
	'20060427': {base:18850, ex_dividend:275},
	'20060728': {base:17000, ex_dividend: 30},
	'20061030': {base:17935, ex_dividend: 30},
	'20070427': {base:20150, ex_dividend:360},
	'20070730': {base:23755, ex_dividend:250},
	'20071030': {base:25760, ex_dividend: 50},
	'20080429': {base:23470, ex_dividend:400},
	'20080730': {base:20050, ex_dividend: 15},
	'20090429': {base:16825, ex_dividend:200},
	'20090730': {base:19925, ex_dividend: 50},
	'20100429': {base:22760, ex_dividend:300},
	'20100729': {base:23230, ex_dividend: 50},
	'20110428': {base:29370, ex_dividend:400},
	'20110728': {base:28480, ex_dividend: 50},
	'20120427': {base:26270, ex_dividend:310},
	'20120730': {base:24320, ex_dividend: 20},
	'20130429': {base:25340, ex_dividend:195},
	'20130730': {base:24660, ex_dividend: 30},
	'20140429': {base:25635, ex_dividend:235},
	'20140730': {base:26795, ex_dividend:  5},
	'20150429': {base:26830, ex_dividend:320},
	'20150730': {base:24605, ex_dividend:  5},
	'20160428': {base:24785, ex_dividend:455},
	'20160728': {base:24785, ex_dividend: 10},
	'20170428': {base:28680, ex_dividend:475},
	'20170731': {base:30000, ex_dividend:100}, // base 값은 사용되지 않아 임의로 입력함
	'20171031': {base:30000, ex_dividend: 30},
	'20180430': {base:30000, ex_dividend:460},
	'20180731': {base:30000, ex_dividend: 65},
	'20181031': {base:30000, ex_dividend: 80},
	'20190430': {base:30000, ex_dividend:465},
	'20190731': {base:30000, ex_dividend: 60},
	'20191031': {base:30000, ex_dividend: 70},
	'20200131': {base:30000, ex_dividend: 50},
	'20200429': {base:30000, ex_dividend:425},
	'20200731': {base:30000, ex_dividend: 55},
	'20201030': {base:30000, ex_dividend: 50},
	'20210129': {base:30000, ex_dividend: 50},
	'20210430': {base:30000, ex_dividend:670},
	'20210730': {base:30000, ex_dividend: 55},
	'20211029': {base:30000, ex_dividend: 85},
	'20220128': {base:30000, ex_dividend: 60},
	'20220429': {base:30000, ex_dividend:500},
};
masterMaps['229200'] = {
	'20160428': {base:10665, ex_dividend: 40},
};
masterMaps['102110'] = {
	'20090429': {base:16750, ex_dividend:130},
	'20091029': {base:21135, ex_dividend: 10},
	'20100429': {base:22655, ex_dividend:200},
	'20101028': {base:24610, ex_dividend: 50},
	'20110428': {base:29220, ex_dividend:280},
	'20111028': {base:25130, ex_dividend: 50},
	'20120427': {base:26170, ex_dividend:300},
	'20121030': {base:24820, ex_dividend: 30},
	'20130429': {base:25370, ex_dividend:300},
	'20131030': {base:27020, ex_dividend: 90},
	'20140429': {base:25680, ex_dividend:275},
	'20140730': {base:26785, ex_dividend: 15},
	'20150129': {base:25495, ex_dividend: 15},
	'20150429': {base:26850, ex_dividend:325},
	'20150730': {base:24590, ex_dividend: 10},
	'20160128': {base:23380, ex_dividend: 90},
	'20160428': {base:24825, ex_dividend:360},
};
masterMaps['232080'] = {
	'20160428': {base:10675, ex_dividend: 50},
};
masterMaps['138230'] = {
	'20111228': {base:12685, ex_dividend:210},
	'20131227': {base:11235, ex_dividend:490},
	'20141229': {base:11470, ex_dividend:210},
	'20151229': {base:11945, ex_dividend:160},
	'20161228': {base:12285, ex_dividend:130},
};

function find(code: string, date: string): DailyProductMaster | undefined {
	const masterMap = masterMaps[code];
	if (masterMap) {
		return masterMap[date];
	} else {
		return undefined;
	}
}

const pmaster = {find};
export default pmaster;