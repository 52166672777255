import { sumBy, isNaN, isNumber, isArray, isObject, reduce } from 'lodash';
import { Product } from '../product/Product';

export interface AssetEntry {
	amount: number;
	price: number | undefined;
}

export class Asset implements AssetEntry {
	readonly product: Product;

	protected _amount = 0;
	protected _last_price: number | undefined = undefined;
	protected _lifo: AssetEntry[] = [];
	protected _lifo_avg_price_cache: number | undefined = undefined;
	protected _moving_avg_price_cache: number;

	constructor(prod: Product, amount?: any, price?: number) {
		this.product = prod;
		this._moving_avg_price_cache = price || 0;

		if (amount) this.push(amount, price);
	}

	get amount(): number {
		return this._amount;
	}

	get cost(): number {
		return sumBy(this._lifo, function (l) { return l.amount * (l.price || 0); });
	}

	get value(): number {
		if (this._last_price !== undefined) {
			return this._amount * this._last_price;
		} else {
			return this.cost;
		}
	}

	get entries(): AssetEntry[] {
		return this._lifo;
	}

	get lifo_avg_price(): number {
		if (!this._lifo_avg_price_cache && this._amount > 0) {
			this._lifo_avg_price_cache = this.cost / this._amount;
		}
		return this._lifo_avg_price_cache || 0;
	}

	get moving_avg_price(): number {
		return this._amount > 0 ? this._moving_avg_price_cache : 0;
	}

	get avg_price(): number {
		return this.lifo_avg_price;
	}

	get price(): number | undefined {
		return this.lifo_avg_price;
	}

	get profit(): number {
		if (this._last_price !== undefined) {
			return this._amount * (this._last_price - this.avg_price);
		} else {
			return 0;
		}
	}

	update_last_price(price: number): void {
		this._last_price = price;
	}

	estimate_value_by(price: number): number {
			return this._amount * price;
	}

	push(amount: number | AssetEntry | AssetEntry[], price?: number): void {
		if (isNaN(amount)) throw new Error('amount is NaN');

		if (isNumber(amount)) {
			if (isNumber(price)) {
				if (amount < 0) throw new Error("Asset#push() allows only positive value of amount");

				const old_amount = this._amount;
				const old_moving_avg_price = this._moving_avg_price_cache;
				const new_amount = old_amount+amount;
				this._amount = new_amount;
				this._lifo.push({amount: amount, price: price});
				this._lifo_avg_price_cache = undefined;
				this._moving_avg_price_cache = (old_moving_avg_price*old_amount + price*amount) / new_amount;

			} else {
				throw new Error("Price should be given");
			}

		} else if (isArray(amount)) {
			amount.forEach((entry: AssetEntry) => {
				if (entry.amount < 0) throw new Error("Asset#push() allows only positive value of amount");
				this.push(entry.amount, entry.price);
			});

		} else if (isObject(amount)) {
			this.push(amount.amount, amount.price);
		}
	}

	pop(amount: number | AssetEntry | AssetEntry[]): AssetEntry[] {
		if (isNaN(amount)) throw new Error('amount is NaN');

		if (isNumber(amount)) {
			if (amount >= 0) throw new Error("Use push() for a positive amount");

			if (-amount > this.amount) {
				throw new Error("차감할 양이 보유량을 초과");
			}

			const lifo = this._lifo;
			const old = [];

			this._amount += amount;
			while (amount < 0) {
				const recent = lifo.pop()!;
				amount += recent.amount

				if (amount > 0) {
					old.push({amount: recent.amount - amount, price: recent.price});
					lifo.push({amount: amount, price: recent.price});

				} else {
					old.push(recent);
				}
			}
			this._lifo_avg_price_cache = undefined;

			return old;

		} else if (isArray(amount)) {
			return reduce(amount, (popped, obj: AssetEntry) => {
				if (obj.amount >= 0) throw new Error("Asset#pop() allows only negative value of amount");
				return popped.concat(this.pop(obj));
			}, [] as AssetEntry[]);

		} else if (isObject(amount)) {
			return this.pop(amount.amount);
		}

		throw new Error("This line cannot be reached")
	}

	clone() {
		return new Asset(this.product, this._lifo);
	}

	static value_of(entries: AssetEntry[]) {
		return sumBy(entries, (o) => { return o.amount * (o.price || 0); });
	}
}
