import { Workbook } from 'exceljs';

import { Plan } from '../plans/Plan';
import { SimulationEnv } from '../SimulationEnv';
import { XlsxPlanContractSheet } from './XlsxPlanContractSheet';
import { writeWorkbookAsFile, Written } from '../../xlsx-writer';
import { XlsxPlanDailySheet } from './XlsxPlanDailySheet';
import { XlsxPlanInfoSheet } from './XlsxPlanInfoSheet';
import { XlsxPlanProfitSheet } from './XlsxPlanProfitSheet';
import { XlsxPlanTableSheet } from './XlsxPlanTableSheet';

export class XlsxPlanWriter {
	static write(plan: Plan, env: SimulationEnv, filename: string): Promise<Written> {
		return write_plan(plan, env, filename);
	}
}

async function write_plan(plan: Plan, env: SimulationEnv, filename: string): Promise<Written> {
	const p_stime = new Date().getTime();
	const wb = new Workbook();

	XlsxPlanProfitSheet.write(wb, plan);
	// if (!env.omit_dailies) {
	XlsxPlanDailySheet.write(wb, plan, env);
	// }
	XlsxPlanInfoSheet.write(wb, plan);
	XlsxPlanTableSheet.write(wb, plan);
	// if (!env.omit_contracts) {
	XlsxPlanContractSheet.write(wb, plan);
	// }

	const written = await writeWorkbookAsFile(wb, filename);
	if (written.type === 'fs') {
		console.log('> ' + written.path + ' written. ' + ((new Date().getTime() - p_stime)/1000) + '초 소요됨.');
	}
	return written;
}
