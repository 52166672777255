import { isError, isNumber } from 'lodash';
import { ProgressBar } from 'primereact/progressbar';
import { classNames } from 'primereact/utils';
import { CSSProperties, useMemo } from 'react';

export interface StatusBarProps {
	progress: number | undefined;
	message: string | Error | undefined;
	className?: string;
	style?: CSSProperties;
}
export function StatusBar({progress, message, className, style}: StatusBarProps) {
	const [text, errorClassName] = useMemo<[string | undefined, string | undefined]>(() => {
		if (isError(message)) {
			return [message.message, 'error'];
		} else {
			return [message, undefined];
		}
	}, [message]);

	return (
		<div className={classNames('simulation-status', className, errorClassName)} style={style}>
			{isNumber(progress) && <ProgressBar value={progress}/>}
			{text && <div className="text">{text}</div>}
		</div>
	)
}