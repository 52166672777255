import { last, max, maxBy, min, minBy, sumBy } from 'lodash';

import { DayTick, SingleTick } from '../../query-ticks';
import { Asset } from '../assets/Asset';
import { pmaster, Product } from '../product';
import { Contract, Daily, DividendErning, SimpleAssetsStat } from '../reports';
import { SimulationEnv } from '../SimulationEnv';
import { Plan, PlanOptions } from './Plan';
import { Deal } from './sheets';

export interface TradeOff {
	deal: Deal;
	money: number;
	price: number;
	volume: number;
	fee: number
	tax: number
}

export interface PlanInfo {
	type: string;
	first_price?: number;
	first_rate?: number;
	range_low?: number;
	range_high?: number;
	margin_rate?: number;
	cumul_rate?: number;
	invest_value?: number;
}

export interface PlanSingleOptions extends PlanOptions {
	product: Product;
}
export abstract class PlanSingle extends Plan {
	product: Product;
	first_tick: SingleTick | undefined;
	last_tick: SingleTick | undefined;
	contracts: Contract[];
	dividend_ernings_list: DividendErning[];
	last_interest_rate: number;
	interest_ernings: number;
	sold_profit: number;
	sold_cost: number;
	fee: number;
	tax: number;
	trade_amount: number;
	protected _dailies: Daily[];
	protected _sum_volume_rate: number;
	protected _num_of_days: number;

	constructor(options: PlanSingleOptions, env: SimulationEnv) {
		super(options, env);

		this.product = options.product;
		this.assets.get(this.product); // prepare product asset
		this.contracts = [];
		this.dividend_ernings_list = [];
		this.last_interest_rate = 0;
		this.interest_ernings = 0;
		this.sold_profit = 0;
		this.fee = 0;
		this.tax = 0;
		this.sold_cost = 0;
		this.trade_amount = 0;
		this._dailies = [];
		this._sum_volume_rate = 0;
		this._num_of_days = 0;
	}

	get volume(): Asset {
		return this.assets.find(this.product)!;
	}

	get value(): number {
		return this.pure_money_value + this.volume!.value;
	}

	get profit(): number {
		return this.value - (this.all_invest_value);
	}

	get pure_money_value(): number {
		if (this.has_tag('shared_limit_slave')) {
			return this.interest_ernings - this.oper_cost;
		} else {
			return (this.money?.value || 0) + this.interest_ernings - this.oper_cost;
		}
	}

	products(): Product[] {
		return [this.product];
	}

	get_dailies(): Daily[] {
		return [...this._dailies];
	}

	protected tickBounds: {highest: number, lowest: number} | undefined;

	async beforeFeedDayTicks(dayticks: DayTick[]): Promise<void> {
		const code = this.product.code;
		// console.log(`PlanSingle{product=${code}}.beforeFeedDayTicks()`);
		const bounds = dayticks.map(daytick => {
			const ticks = daytick.ticksByCodeMap[code];
			return {
				highest: max(ticks)!,
				lowest: min(ticks)!
			}
		})
		const highest = maxBy(bounds, bound => bound.highest)!.highest;
		const lowest = minBy(bounds, bound => bound.lowest)!.lowest;
		this.tickBounds = {highest, lowest};
	}

	async onDayTick(dayTick: DayTick, lastClosing: SingleTick | null): Promise<SingleTick> {
		// console.log('onDayTick', dayTick, lastClosing);
		const ticks = dayTick.ticksByCodeMap[this.product.code];
		let closing: SingleTick | undefined;

		if (!this.stop_date || dayTick.date < this.stop_date) { // 종료일 이전인 경우에만 처리
			// 금리 데이터를 입력
			if (this.env.interest_rate) {
				this.last_interest_rate = this.env.interest_rate(Date.from_ymd(dayTick.date));
			}

			if (this.first_tick) {
				this.onBeginOfDay(dayTick.date, lastClosing);

				for (let i=0; i < ticks.length; i++) {
					const value = ticks[i];
					if (value) { // 가격이 0인 것(매매정지)은 생략한다. 
						const single = {
							value,
							pos: {date: Date.from_ymd(dayTick.date), idx_in_date: i, cnt_in_date: ticks.length}
						};
						await this.onSingleTick(single);
						closing = single;
					}
				}

			} else {
				// 최초일이라면 종가만 처리한다. (시뮬레이션은 시작일의 종가부터 시작)
				const firstDayClosing = {
					value: last(ticks)!,
					pos: {date: Date.from_ymd(dayTick.date), idx_in_date: ticks.length - 1, cnt_in_date: ticks.length}
				};
				await this.onSingleTick(firstDayClosing);
				this.first_tick = firstDayClosing;
				closing = firstDayClosing;
			}

		} else {
			closing = {
				value: last(ticks)!,
				pos: {date: Date.from_ymd(dayTick.date), idx_in_date: ticks.length - 1, cnt_in_date: ticks.length}
			};
		}

		if (!closing) throw new Error("No closing tick at the end of date");

		this.onEndOfDay(closing);
		return closing;
	}

	protected onBeginOfDay(datestr: string, lastClosing: SingleTick | null): void {
		if (lastClosing) {
			const date = Date.from_ymd(datestr)
			this.takeInterestErnings(lastClosing.pos.date, date);
			this.takeDividendErnings(date);
		}
	}

	private onEndOfDay(closing: SingleTick): void {
		this.update_oper_cost(closing);

		const tick = closing.value;
		const volume_amount = this.volume!.amount;
		const volume_estimated_value = volume_amount * tick;
		this._sum_volume_rate += volume_estimated_value / (volume_estimated_value + this.pure_money_value);
		this._num_of_days++;

		const avg_price = this.volume!.avg_price;
		const volume_details = {[this.product.code]: {
			tick: tick,
			avg_price: avg_price,
			volume: volume_amount,
		}};
		const volume_original_value = volume_amount * avg_price;

		this._dailies.push({
			date: closing.pos.date,
			tick: tick,
			ticks: {[this.product.code]: closing.value},
			money: this.money.value,
			invest: this.all_invest_value,
			volume: volume_amount,
			avg_price: avg_price,
			sold_profit: this.sold_profit,
			interest_rate: this.last_interest_rate,
			interest_ernings: this.interest_ernings,
			dividend_ernings: this.dividend_ernings,
			fee: this.fee,
			tax: this.tax,
			oper_cost: this.oper_cost,
			volume_details: volume_details,
			volume_original_value: volume_original_value,
			volume_estimated_value: volume_estimated_value,
			applied_other_money: 0,
			applied_estimated_profit: 0,
		});

		this.last_tick = closing;
		this.volume!.update_last_price(closing.value);
	}

	// 복리를 적용하지 않는다. (현금+이자누적액의 합산이 아닌 현금에 대한 이자만 계산한다)
	private takeInterestErnings(from: Date, to: Date, flags?: string[]): void {
		const money_amount = (flags?.includes('shared_limit_slave') ? 0 : this.money.value);
		const target_amount = money_amount + this.dividend_ernings;
		if (target_amount <= 0) return;

		if (this.last_interest_rate) {
			let date = from;
			while (date < to) {
				// 이자는 원단위 절사한다
				const interest_ernings = Math.floor(target_amount * (this.last_interest_rate / 100) / 365);
				this.interest_ernings += interest_ernings;

				date = new Date(date.getTime()).add_days(1);
			}
		}
	}

	private takeDividendErnings(date: Date): void {
		if (this.env.ex_dividend) {
			const pm = pmaster.find(this.product.code, date.format_ymd());
			if (pm?.ex_dividend) {
				const money = this.money;
				const volume = this.volume!;
	
				this.dividend_ernings_list.push({
					date: date,
					volumes: volume.amount,
					dividend_per_volume: pm.ex_dividend,
				});
	
				const flow = volume.amount  * pm.ex_dividend;
				// 세금은 원단위 절사한다
				const tax = Math.floor(flow * this.product.ex_dividend_tax_rate);
	
				this.tax += tax;
				money.push(flow - tax);
				this.record_contract(date, Deal.DIVIDEND, pm.ex_dividend, volume.amount, flow - tax, 0, tax, 0, this.assets_stat());
			}
		}
	}

	protected compute_buy_tradeoff(price: number, volume: number): TradeOff {
		const trade_amount = price * volume;
		// 수수료는 원단위 올림한다
		const fee = Math.ceil(trade_amount * this.env.trade_fee_rate);
		return { deal: Deal.BUY, money: -(trade_amount + fee), price: price, volume: volume, fee: fee, tax: 0 };
	}

	private compute_margin_tax(price: number, volume: number): number {
		if (this.env.etf_margin_tax && this.product.margin_tax_rate) {
			// 보유기간과세(양도차익의 15.4%) 계산은 이동평균 매입가를 사용하여 계산하되, 원단위 절사한다
			const asset_volume = this.volume!;
			const margin_amount = (price - asset_volume.moving_avg_price) * volume;
			return Math.floor(margin_amount > 0 ? margin_amount * this.product.margin_tax_rate : 0);

		} else return 0;
	}

	protected compute_sell_tradeoff(price: number, volume: number): TradeOff {
		const trade_amount = price * volume;
		// 수수료는 원단위 올림한다
		const fee = Math.ceil(trade_amount * this.env.trade_fee_rate);
		// 매도세는 원단위 절사한다
		const sell_tax = Math.floor(trade_amount * this.product.sell_tax_rate);
		const margin_tax = this.compute_margin_tax(price, volume);
		const tax = sell_tax + margin_tax;

		return { deal: Deal.SELL, money: trade_amount - fee - tax, price: price, volume: -volume, fee: fee, tax: tax };
	}

	protected compute_tradeoff(price: number, volume: number): TradeOff {
		if (volume >= 0) {
			return this.compute_buy_tradeoff(price, volume);
		} else {
			return this.compute_sell_tradeoff(price, -volume);
		}
	}

	protected tradeoff_assets(tradeoff: TradeOff, date: Date): void {
		const money = this.money;
		const volume = this.volume!;

		if (tradeoff.deal === Deal.BUY) {
			money.pop(tradeoff.money);
			volume.push(tradeoff.volume, tradeoff.price);

			this.fee += tradeoff.fee;
			this.tax += tradeoff.tax;
			this.trade_amount += tradeoff.price * tradeoff.volume;
			this.record_contract(date, tradeoff.deal, tradeoff.price, tradeoff.volume, tradeoff.money, tradeoff.fee, tradeoff.tax, 0, this.assets_stat());

		} else if (tradeoff.deal === Deal.SELL){
			money.push(tradeoff.money);
			const origin = volume.pop(tradeoff.volume);
			const origin_value = Asset.value_of(origin);
			const trade_value = tradeoff.price * -tradeoff.volume; // tradeoff.volume is negative !!
			const trade_margin = trade_value - origin_value;

			this.fee += tradeoff.fee;
			this.tax += tradeoff.tax;
			this.trade_amount += trade_value;
			this.sold_cost += origin_value;
			this.sold_profit += trade_margin;
			this.record_contract(date, tradeoff.deal, tradeoff.price, -tradeoff.volume, tradeoff.money, tradeoff.fee, tradeoff.tax, trade_margin, this.assets_stat());
		}
	}

	protected assets_stat(): SimpleAssetsStat {
		return {money: this.money.value, volume:this.volume!.amount, lifo_avg_price: this.volume!.avg_price, moving_avg_price: this.volume!.moving_avg_price};
	}

	protected record_contract(date: Date, deal: Deal, price: number, volume: number, flow: number, fee: number, tax: number, deal_margin: number, after: SimpleAssetsStat): void {
		// if (!this.env.omit_contracts) {
			this.contracts.push({ date, deal, price, volume, flow, fee, tax, deal_margin, after });
		// }
	}

	get dividend_ernings(): number {
		return sumBy(this.dividend_ernings_list, dErning => {
			return dErning.dividend_per_volume * dErning.volumes;
		});
	}

	right_shares_price(): number {
		if (this.right_shares_count > 0) {
			return this.value / this.right_shares_count;
		} else {
			return Plan.INITIAL_RIGHT_SHARES_PRICE;
		}
	}

	avg_volume_rate(): number {
		return this._sum_volume_rate / this._num_of_days;
	}

	abstract get_plan_info(): PlanInfo;
}


export interface PlanSingleFactory {
	(o: PlanSingleOptions, index?: number): Plan;
}
