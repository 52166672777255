import React, { CSSProperties, useMemo, useRef } from "react";
import { Transition } from "react-transition-group";

interface FadeProps {
	show: boolean;
	duration?: number;
	from?: 'left' | 'right' | 'up' | 'down';
	distance?: number;
	children: React.ReactNode;
}
export function FadeTransition({show, duration, from, distance, children}: FadeProps) {
	const nodeRef = useRef(null);

	if (duration == undefined) {
		duration = 300;
	}
	const defaultStyle: CSSProperties = {
		transition: `opacity ${duration}ms ease-in-out, transform ${duration}ms ease-in-out`,
	};
	const transitionStyles = useMemo(() => createTransitionStyles(from, distance ?? 20), [from, distance]);

	return (
		<Transition nodeRef={nodeRef} in={show} timeout={duration}>
			{state => (
				<div ref={nodeRef} style={{...defaultStyle, ...transitionStyles[state]}}>
					{children}
				</div>
			)}
		</Transition>
	);
}

function createTransitionStyles(from: 'left' | 'right' | 'up' | 'down' | undefined, distance: number): {[name: string]: CSSProperties} {
	const [transformIn, transformOut] = (() => {
		if (from && distance) {
			switch (from) {
				case 'left': return ['translateX(0px)', `translateX(-${distance}px)`];
				case 'right': return ['translateX(0px)', `translateX(${distance}px)`];
				case 'up': return ['translateY(0px)', `translateY(-${distance}px)`];
				case 'down': return ['translateY(0px)', `translateY(${distance}px)`];
			}
		}
		return [undefined, undefined];
	})();
	return {
		entering: { opacity: 1, transform: transformIn, position: 'static', top: '0px'},
		entered: { opacity: 1, transform: transformIn, position: 'static', top: '0px' },
		exiting: { opacity: 0, transform: transformOut, position: 'static', top: '0px' },
		exited: { opacity: 0, transform: transformOut, position: 'absolute', top: '-1000px'},
	};
}
