import { TermArraySpec } from "../request";
import { Term, TermsProvider } from "./types";

export class TermArray implements TermsProvider {
	constructor(private spec: TermArraySpec) {}

	async getTermList(): Promise<Term[]> {
		return this.spec.array.map(term => {
			return {
				// 입력받을 일자가 유효한지 확인하기 위해 Date로 변환시켜본다
				begin: Date.from_ymd(term.begin).format_ymd(),
				end: Date.from_ymd(term.end).format_ymd(),
			};
		});
	}
}