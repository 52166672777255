import { first, flatten, isEmpty, maxBy, minBy } from 'lodash';
import { clearAllTickCaches, prepareTicks } from '../query-ticks';

import { Plan } from './plans/Plan';
import { PlanFactory } from './plans/PlanFactory';
import { PlanRegularSavings } from './plans/PlanRegularSavings';
import { PlanSingle } from './plans/PlanSingle';
import { Product } from './product';
import { MatrixReporter, PlanReporter, TermsReporter } from './reports';
import { SimulationEnv } from './SimulationEnv';
import { Term, TermsProvider } from './terms';
import { prepareInterests } from './cd';

export interface MatrixPlans {
	term: Term;
	plans: Plan[][] | undefined;
}

export interface TermSummary {
	id: string;
	products: Product[];
	invest_value: number;
	invest_way: string;
	first_rate: number;
	range_low: number;
	range_high: number;
	margin_rate: number;
	cumul_rate: number;
	value: number;
	first_prices: {[code: string]: number};
	last_prices: {[code: string]: number};
	avg_volume_rate: number;
	trade_amount_rate: number;
	right_shares_price: number;
}

export interface TermResult {
	term: Term;
	summaries: TermSummary[][]
}

export interface ProgressReporter {
	(progress: number, terms_count: number, terms_completed: number, plans_count: number, plans_completed: number): Promise<any>
}

export class Matrix {
	protected _terms_provider: TermsProvider | undefined;
	protected _plan_factories: PlanFactory[][] | undefined;
	protected _plan_reporter: PlanReporter | undefined;
	protected _matrix_reporter: MatrixReporter | undefined;
	protected _terms_reporter: TermsReporter | undefined;
	protected _progress_reporter: ProgressReporter | undefined;

	protected _cancelled: boolean | undefined;
	protected _running_terms_count: number | undefined;
	protected _running_term_index: number | undefined;
	protected _product_codes_to_prepare: string[] | undefined;

	terms(arg: TermsProvider): Matrix {
		this._terms_provider = arg;
		return this;
	}

	factories(arg: PlanFactory[][]): Matrix {
		this._plan_factories = arg;
		return this;
	}

	set_plan_reporter(arg: PlanReporter | undefined): Matrix {
		this._plan_reporter = arg;
		return this;
	}

	set_matrix_reporter(arg: MatrixReporter | undefined): Matrix {
		this._matrix_reporter = arg;
		return this;
	}

	set_terms_reporter(arg: TermsReporter | undefined): Matrix {
		this._terms_reporter = arg;
		return this;
	}

	set_progress_reporter(arg: ProgressReporter | undefined): Matrix {
		this._progress_reporter = arg;
		return this;
	}

	set_product_codes_to_prepare(codes: string[]) {
		this._product_codes_to_prepare = codes;
		return this;
	}

	async plan_report(event: string, term: Term, plan: Plan, env: SimulationEnv): Promise<Plan> {
		if (this._plan_reporter) {
			await this._plan_reporter(event, term, plan, env);
		}
		return plan;
	}

	async matrix_report(event: string, matrix_plans: MatrixPlans, env: SimulationEnv): Promise<MatrixPlans> {
		if (this._matrix_reporter) {
			await this._matrix_reporter(event, matrix_plans, env);
		}
		return matrix_plans;
	}

	async terms_report(event: string, term_results: TermResult[] | undefined, env: SimulationEnv): Promise<TermResult[] | undefined> {
		if (this._terms_reporter) {
			await this._terms_reporter(event, term_results, env);
		}
		return term_results;
	}

	async progress_report<T>(terms_count: number, terms_completed: number, plans_count: number, plans_completed: number, result?: T): Promise<T | undefined> {
		if (this._progress_reporter) {
			let progress;
			if (terms_count === 0) {
				progress = 0;
			} else if (terms_completed === terms_count) {
				progress = 1;
			} else if (terms_count) {
				const progress1 = (terms_completed+1) / terms_count;
				progress = terms_completed / terms_count;
				progress += (progress1 - progress) * (plans_completed / plans_count);
			}
			await this._progress_reporter(progress || 0, terms_count, terms_completed, plans_count, plans_completed);
		}
		return result;
	}

	async start(env: SimulationEnv): Promise<void> {
		await this.terms_report('start', undefined, env);
		await this.progress_report(0, 0, 0, 0);

		const terms = await this._terms_provider!.getTermList();
		if (isEmpty(terms)) {
			throw new Error("시뮬레이션 기간을 입력하세요")
		}
		// console.log('terms[first]', terms[0]);
		// console.log('terms[last]', terms[terms.length-1]);

		clearAllTickCaches();
		await Promise.all([
			this.prepareAllTicks(this._product_codes_to_prepare!, terms),
			this.prepareInterests(terms),
		]);

		this._cancelled = false;
		this._running_terms_count = terms.length;
		const term_results: TermResult[] = [];
		for (let i=0; i < terms.length ; i++) {
			const term = terms[i];
			this._running_term_index = i;

			const matrix_plans = await this.start_matrix_for_term(term, env);
			if (this._terms_reporter) {
				term_results.push({ term, summaries: Matrix.create_term_summary(matrix_plans) });
			}
		}

		await this.progress_report(this._running_terms_count, this._running_terms_count, 0, 0, term_results);
		await this.terms_report('end', term_results, env);
	}

	async prepareAllTicks(codes: string[], terms: Term[]) {
		if (!isEmpty(this._product_codes_to_prepare)) {
			const begin_date = minBy(terms, 'begin')!.begin;
			const end_date = maxBy(terms, 'end')!.end;
			await Promise.all(this._product_codes_to_prepare!.map(code => prepareTicks(code, begin_date, end_date)));
		}
	}

	async prepareInterests(terms: Term[]) {
		const begin_date = minBy(terms, 'begin')!.begin;
		const end_date = maxBy(terms, 'end')!.end;
		await prepareInterests(begin_date, end_date);
	}

	async start_matrix_for_term(term: Term, env: SimulationEnv): Promise<MatrixPlans> {
		await this.matrix_report('start', {term: term, plans: undefined}, env)
		const plans = this._plan_factories!.map(factories => {
			return factories.map(factory => factory(term));
		});

		const flatten_plans = flatten(plans);
		for (let i=0; i < flatten_plans.length; i++) {
			const plan = flatten_plans[i];
			await this.progress_report(this._running_terms_count!, this._running_term_index!, flatten_plans.length, i);
			await this.start_plan_for_term(plan, term, env);
		}
		await this.progress_report(this._running_terms_count!, this._running_term_index!, flatten_plans.length, flatten_plans.length);
		return this.matrix_report('end', {term: term, plans:plans}, env);
	}

	async start_plan_for_term(plan: Plan, term: Term, env: SimulationEnv): Promise<Plan> {
		if (this._cancelled) throw new Error("시뮬레이션이 취소되었습니다");

		await this.plan_report('start', term, plan, env)
		await plan.start();
		await this.plan_report('end', term, plan, env);
		return plan;
	}

	cancel() {
		this._cancelled = true;
	}

	static create_term_summary(matrix_plans: MatrixPlans): TermSummary[][] {
		return matrix_plans.plans!.map(plans => {
			return plans.map((plan): TermSummary => {

				const s: any = {
					id: plan.id,
					products: plan.products(),
					invest_value: plan.all_invest_value,
					right_shares_price: plan.right_shares_price(),
				};

				if (plan instanceof PlanSingle) {
					const info = plan.get_plan_info();
					s.invest_way = '단일/'+ info.type;
					s.first_rate = info.first_rate;
					s.range_low = info.range_low;
					s.range_high = info.range_high;
					s.margin_rate = info.margin_rate;
					s.cumul_rate = info.cumul_rate;
					s.invest_value = plan.all_invest_value;
					s.value = plan.value;
					s.first_prices = {[plan.product.code]: plan.first_tick!.value};
					s.last_prices = {[plan.product.code]: plan.last_tick!.value};
					s.avg_volume_rate = plan.avg_volume_rate();
					s.trade_amount_rate = plan.trade_amount / s.invest_value;

				} else if (plan instanceof PlanRegularSavings) {
					s.invest_value = plan.all_invest_value;
					s.value = plan.sum_value();
					s.avg_volume_rate = plan.avg_volume_rate();
					s.trade_amount_rate = plan.sum_trade_amount() / s.invest_value;

					const subplan = first(plan.subplans);
					if (subplan instanceof PlanSingle) {
						const info = subplan.get_plan_info();
						s.invest_way = '적립/'+ info.type;
						s.first_rate = info.first_rate;
						s.range_low = info.range_low;
						s.range_high = info.range_high;
						s.margin_rate = info.margin_rate;
						s.cumul_rate = info.cumul_rate;
						s.first_prices = {[subplan.product.code]: subplan.first_tick!.value};
						s.last_prices = {[subplan.product.code]: subplan.last_tick!.value};
					} else{
						s.invest_way = '적립/다수종목';
					}
				}
				return s;
			});
		});
	}
}
