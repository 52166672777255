import s from '../xlsx-styles';
import { Workbook, Fill } from 'exceljs';
import { FundData, IndexData } from './download';

export class XlsxFundPriceSheet {

	static write(wb: Workbook, data: FundData, indices: IndexData[]) {
		const ws = wb.addWorksheet(data.fund.name);

		const font = s.font;
		const left = s.left;
		const right = s.right;
		const f = s.fills0;
		const columns: {width: number, style: any}[] = [
		/* A */ { width:10, style: {font: font, fill: f[0], alignment: left} },
		/* B */ { width:10, style: {font: font, numFmt: '#,##0.00', alignment: right}},
		/* C */ { width: 8, style: {font: font, numFmt: '#,##0', alignment: right} },
		/* D */ { width: 8, style: {font: font, numFmt: '#,##0', alignment: right} },
		/* E */ { width: 8, style: {font: font, numFmt: '#,##0', alignment: right} },
		/* F */ { width: 8, style: {font: font, numFmt: '#,##0', alignment: right} },
			...indices.map(indexData => {
				return {
					width: 8,
					style: {
						font: font,
						numFmt: indexData.style?.format === 'percent' ? '0.0%' : '#,##0.00',
						alignment: right
					}
				};
			}),
		];
		columns.forEach((column, idx) => {
			column.style.border = s.border.tb;
			ws.getColumn(idx+1).width = column.width;
			ws.getColumn(idx+1).style = column.style;
		});

		const add_row = function (f: Fill[] | undefined, values: any[]) {
			const row = ws.addRow(values);

			if (f) {
				columns.forEach((_, idx) => {
					row.getCell(idx+1).fill = f[0];
				});
			}

			return row;
		};

		add_row(s.fills1, [
			'일자', '기준가', '설정원본(백만)', '주식자산(백만)', '채권자산(백만)', '유동자산(백만)',
			...indices.map(i => i.name),
		]);

		data.prices.forEach(p => {
			add_row(f, [
				Date.from_ymd(p.date).format_ymd('-'),
				p.price,
				p.input_size && (p.input_size / 1000000),
				p.stock_size && (p.stock_size / 1000000),
				p.bond_size && (p.bond_size / 1000000),
				p.liquid_size && (p.liquid_size / 1000000),
				...indices.map(indexData => {
					const value = indexData.dailyValues.find(d => d.date === p.date)?.value;
					if (indexData.unit === '1/100') {
						return value && value / 100;
					}
					return value;
				}),
			]);
		});
	}
}
