import '../node_modules/primeflex/primeflex.css';
import './index.scss';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

import { PrimeReactProvider, addLocale, locale } from 'primereact/api';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { SimulationPage } from './pages/simulation';
import reportWebVitals from './reportWebVitals';
import { FundPage } from './pages/fund';

addLocale("ko", {
	"accept": "예",
	"reject": "아니오",
	"choose": "선택",
	"upload": "업로드",
	"cancel": "취소",
	"dayNames": ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"],
	"dayNamesShort": ["일", "월", "화", "수", "목", "금", "토"],
	"dayNamesMin": ["일", "월", "화", "수", "목", "금", "토"],
	"monthNames": ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
	"monthNamesShort": ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
	"today": "오늘",
	"clear": "초기화",
	"weekHeader": "Wk",
	"firstDayOfWeek": 0,
	"dateFormat": "mm/dd/yy",
	"weak": "약함",
	"medium": "보통",
	"strong": "강함",
	"passwordPrompt": "패스워드를 입력하세요"
});
locale('ko');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
	{ path: "/", element: <SimulationPage /> },
	{ path: "/fund", element: <FundPage /> },
]);

root.render(
	<React.StrictMode>
		<PrimeReactProvider>
			<RouterProvider router={router}/>
		</PrimeReactProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line @typescript-eslint/no-floating-promises
reportWebVitals();
