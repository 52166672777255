import { Plugin } from 'chart.js';

export const VerticalTooltipLinePlugin: Plugin<'bar' | 'line'> = {
	id: 'vertial-tootip-line',
	afterDraw: (chart) => {
		const actives = chart.tooltip?.getActiveElements();
		if (actives?.length) {
			const active = actives[0];
			const x = active.element.x;
			const yAxis = chart.scales.y;
			const ctx = chart.ctx;
			ctx.save();
			ctx.beginPath();
			ctx.moveTo(x, yAxis.top);
			ctx.lineTo(x, yAxis.bottom);
			ctx.lineWidth = 1;
			ctx.strokeStyle = 'rgba(96, 96, 96, 0.2)';
			ctx.stroke();
			ctx.restore();
		}
	}
};

export function createVerticalTooltipLinePlugin(yAxisName: string): Plugin<'bar' | 'line'> {
	return {
		id: 'vertial-tootip-line',
		afterDraw: (chart) => {
			const actives = chart.tooltip?.getActiveElements();
			if (actives?.length) {
				const active = actives[0];
				const x = active.element.x;
				const yAxis = chart.scales[yAxisName];
				const ctx = chart.ctx;
				ctx.save();
				ctx.beginPath();
				ctx.moveTo(x, yAxis.top);
				ctx.lineTo(x, yAxis.bottom);
				ctx.lineWidth = 1;
				ctx.strokeStyle = 'rgba(96, 96, 96, 0.2)';
				ctx.stroke();
				ctx.restore();
			}
		}
	}
}
