import { Money } from "./Money";

export class SharedMoney extends Money {
	protected _shared_parent: Money;

	constructor(amount: number, shared_parent: Money) {
		super(amount);
		this._shared_parent = shared_parent;
	}

	get value(): number {
		if (this._shared_parent) {
			return Math.min(this.amount, this._shared_parent.value);
		} else {
			return this.amount;
		}
	}

	get shared_value(): number {
		if (this._shared_parent) {
			return this._shared_parent.value;
		} else {
			return this.amount;
		}
	}

	push(amount: number | Money | Money[], price?: number) {
		super.push(amount, price);
		if (this._shared_parent) this._shared_parent.push(amount, price);
	}

	copy() {
		return new SharedMoney(this.amount, this._shared_parent);
	}

	clone() {
		return new SharedMoney(this.amount, this._shared_parent.clone());
	}
}
