import { isBaseTick, SingleTick } from '../../query-ticks';
import { PlanInfo, PlanSingle } from './PlanSingle';


export class PlanHold extends PlanSingle {
	protected _first_vol: number | undefined;
	protected _first_price: number | undefined;

	get first_rate(): number {
		return 1;
	}

	get first_vol(): number | undefined {
		return this._first_vol;
	}

	get first_price(): number | undefined {
		return this._first_price;
	}

	async onSingleTick(single: SingleTick): Promise<void> {
		if (isBaseTick(single.pos)) return;
		if (this.first_vol === undefined) {
			this.deal_first_vol(single);
		}
	}

	protected deal_first_vol(single: SingleTick): void {
		this._first_price = single.value;

		const cost_per_volume = this.compute_buy_tradeoff(single.value, 1).money;
		this._first_vol = Math.floor(this.initial_invest.value / -cost_per_volume);

		const price = single.value, volume = this._first_vol - this.volume!.amount;
		const tradeoff = this.compute_tradeoff(price, volume);

		if (this.money.value >= -tradeoff.money) {
			if (volume !== 0) this.tradeoff_assets(tradeoff, single.pos.date);
		} else {
			throw new Error("Insufficient money for buying the first volume");
		}
	}

	get_plan_info(): PlanInfo {
		return  {
			type: '단순거치',
			first_price: this.first_price,
			first_rate: 1,
		};
	}
}
