import { SingleTick } from '../../query-ticks';
import { PlanInfo, PlanSingle } from './PlanSingle';


export class PlanMoney extends PlanSingle {
	async onSingleTick(_single: SingleTick): Promise<void> {
		// do nothing
	}

	get_plan_info(): PlanInfo {
		return  {
			type: '현금거치',
			first_price: 0,
			first_rate: 1,
		};
	}
}
