import { cloneDeep } from "lodash";
import { newId } from "./storage";
import { SimulationRequest } from "./types";

const samples: SimulationRequest[] = [
	{
		name: '테스트: 단일 기간, 단일 조건',
		terms: {
			type: 'array',
			array: [{begin:'20160101', end:'20220630'}]
		},
		plans: [
			{
				type: 'hold',
				invest: 1000000,
				product_code:'069500',
				product_market: 'ETF',
			},
			{
				type: 'wave',
				invest: 1000000,
				product_code:'069500',
				product_market: 'ETF',
				first_rate_p: 60,
				range_low_p: 70,
				range_high_p: 130,
				margin_p: 3,
				extend_lower: true,
			},
			{
				type: 'autocha',
				autocha_type: 'autocha1',
				invest: 1000000,
				product_code:'069500',
				product_market: 'ETF',
			},
		],
		env: {
			trade_fee_rate_p: 0.2,
			oper_cost_rate_p: 1.5,
			interest_rate: true,
			ex_dividend: true,
			etf_margin_tax: false,
		},
		report: {
			plan_report: true,
			matrix_report: true,
			terms_report: false,
		},
	},
	{
		name: '테스트: 복수 기간, 적립식 조건',
		terms: {
			type: 'array',
			array: [
				{begin:'20160101', end:'20210101'},
				{begin:'20170101', end:'20220101'}
			]
		},
		plans: [
			{
				type: 'regular-hold',
				regular_invest: 1000000,
				product_code:'069500',
				product_market: 'ETF',
			},
			{
				type: 'regular-wave',
				regular_invest: 1000000,
				product_code:'069500',
				product_market: 'ETF',
				first_rate_p: 60,
				range_low_p: 70,
				range_high_p: 130,
				margin_p: 3,
				extend_lower: true,
			},
			{
				type: 'regular-autocha',
				regular_invest: 1000000,
				autocha_type: 'autocha1',
				product_code:'069500',
				product_market: 'ETF',
			},
		],

		env: {
			trade_fee_rate_p: 0.2,
			oper_cost_rate_p: 1.5,
			interest_rate: true,
			ex_dividend: true,
			etf_margin_tax: false,
		},
		report: {
			plan_report: false,
			matrix_report: true,
			terms_report: false,
		},
	},
	{
		name: '테스트: 매일 설정',
		terms: {
			type: 'daily-rolling',
			begin:'20160101',
			end:'20220630',
			duration: {years:3},
			step: {days:1}
		},
		plans: [
			{
				type: 'wave',
				invest: 1000000,
				product_code:'069500',
				product_market: 'ETF',
				first_rate_p: 60,
				range_low_p: 70,
				range_high_p: 130,
				margin_p: 3,
				extend_lower: true,
			},
		],
		env: {
			trade_fee_rate_p: 0.2,
			oper_cost_rate_p: 1.5,
			interest_rate: true,
			ex_dividend: true,
			etf_margin_tax: false,
		},
		report: {
			plan_report: false,
			matrix_report: false,
			terms_report: true,
		},
	},
	{
		name: '테스트: 매달 1, 15일 설정',
		terms: {
			type: 'every-nth-days',
			begin:'20160101',
			end:'20220630',
			duration: {years:3},
			days: [1, 15]
		},
		plans: [
			{
				type: 'autocha',
				autocha_type: 'autocha1',
				invest: 1000000,
				product_code:'069500',
				product_market: 'ETF',
			},
			{
				type: 'wave',
				invest: 1000000,
				product_code:'069500',
				product_market: 'ETF',
				first_rate_p: 60,
				range_low_p: 70,
				range_high_p: 130,
				margin_p: 3,
				extend_lower: true,
			},
		],
		env: {
			trade_fee_rate_p: 0.2,
			oper_cost_rate_p: 1.5,
			interest_rate: true,
			ex_dividend: true,
			etf_margin_tax: false,
		},
		report: {
			plan_report: false,
			matrix_report: false,
			terms_report: true,
		},
	}
];
const SampleRequests = {
	read: (): SimulationRequest[] => {
		return samples.map(sample => {
			const request = cloneDeep(sample);
			(request as any)._id = newId();
			return request;
		});
	}
}
export default SampleRequests;
