import React, { useEffect, useMemo, useRef, useState } from "react";
import { NavBar } from "../../components/NavBar";
import { FundDetailView } from "./FundDetailView";
import { FundInfo, queryFunds } from '../../lib/query-funds';
import { SelectList } from "../../components/SelectList";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useLocation, useNavigate } from 'react-router-dom';
import './styles.scss';
import { classNames } from "primereact/utils";

export function FundPage() {
	const [showNav, setShowNav] = useState<boolean>(false);
	const [funds, setFunds] = useState<FundInfo[]>([]);
	const toast = useRef<Toast>(null);

	const showError = (err: any) => {
		toast.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: err.message || err,
			life: 3000
		});
		console.error(err);
	}

	useEffect(() => {
		queryFunds()
			.then(setFunds)
			.catch(showError);
	}, []);

	const location = useLocation();
	const navigate = useNavigate();
	const fundCode = location.hash.replace(/^#/, '');
	// console.log("*** fundCode", fundCode);

	const fund = fundCode ? funds.find(f => f.code === fundCode) : undefined;
	const fundItems = useMemo(() => {
		return funds.map(fund => ({ id: fund.code, label: fund.name, data: fund }));
	}, [funds]);

	const navigateToFund = (fundCode: string) => {
		navigate('#' + (fundCode || ''));
	};

	return <>
	<NavBar visible={showNav} onHide={() => setShowNav(false)}></NavBar>
	<div className={classNames("grid fund-page-layout", fund && "fund-detail-backdrop")}>
		<div className="col-12 lg:col-3 xl:col-2">
			<div className='flex align-items-center p-2'>
				<Button icon="pi pi-bars" text onClick={() => setShowNav(true)}/>
				<h2 className='my-0'>펀드 기준가</h2>
			</div>
			<div className="p-3">
				<SelectList<string> mode="single"
					selection={fund?.code ? [fund.code] : []} list={fundItems}
					onChange={e => navigateToFund(e.selection[0])} />
			</div>
		</div>
		{fund && <FundDetailView className="col-12 lg:col-9 xl:col-10 p-3" fund={fund} />}
	</div>
	<Toast ref={toast} />
	</>;
}
