import { Fill, Workbook, Worksheet } from 'exceljs';
import { Plan } from '../plans/Plan';
import { Product } from '../product';

import s from '../../xlsx-styles';
import { column_to_letter } from '../../xlsx-util';
import { SimulationEnv } from '../SimulationEnv';

export class XlsxPlanDailySheet {

	private static prepare_doc_structure(ws: Worksheet, products: Product[], env: SimulationEnv) {
		const f = s.fills0;
		const font = s.font;
		const right = s.right;

		const columns: {name: string, width: number, style: any}[] = [
		/* 일자 */
		{name: '일자', width: 8, style: {font: font, fill: f[0]}},
		{name: '기타손익 정산전 현금', width:10, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]}},
		{name: '기타손익 정산후 현금', width:10, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]}},
		{name: '기타손익', width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]}},
		];

		const interest_column = env.interest_rate ? 1 : 0;
		if (interest_column) {
			columns.push({name: '금리', width: 9, style: {font: font, numFmt: '#,##0.00', alignment: right, fill: f[0]}});
		}

		products.forEach(product => {
			columns.push(
			{name: product.code + ' 주식수', width:10, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]}},
			{name: product.code + ' 평단가', width:10, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]}},
			{name: product.code + ' 종가', width:10, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]}},
			{name: product.code + ' 편입비', width:10, style: {font: font, numFmt: '0.0%', alignment: right, fill: f[0]}},
			);
		});

		columns.push(
		{name: '주식매입금액', width:10, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]}},
		{name: '주식평가금액', width:10, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]}},
		{name: '평가금액', width:10, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]}},
		{name: '투자금액', width:10, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]}},

		{name: '수익률', width: 8, style: {font: font, numFmt: '0.0%', alignment: right, fill: f[0]}},
		{name: '수익금액', width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]}},
		{name: '매매수익', width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]}},
		{name: '평가수익', width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]}},
		{name: '이자수익', width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]}},
		{name: '배당수익', width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]}},
		{name: '매매수수료', width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]}},
		{name: '세금', width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]}},
		{name: '운용보수료', width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]}},

		{name: '기반영 기타손익', width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },
		{name: '기반영 평가손익', width: 9, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[1]} },

		{name: '펀드 좌수', width:10, style: {font: font, numFmt: '#,##0', alignment: right, fill: f[0]}},
		{name: '펀드 기준가', width:10, style: {font: font, numFmt: '#,##0.00', alignment: right, fill: f[0]}},
		);

		const add_row = function (f: Fill[] | undefined, values: any) {
			const row = ws.addRow(values);
			if (f) {
				let c = 1, i;
				row.getCell(c++).fill = f[0];
				for (i = 0; i < (3); i++) row.getCell(c++).fill = f[1];
				if (interest_column) {
					row.getCell(c++).fill = f[0];
				}
				for (i = 0; i < products.length*4; i++) row.getCell(c++).fill = f[0];
				for (i = 0; i < 4; i++) row.getCell(c++).fill = f[1];
				for (i = 0; i < 9; i++) row.getCell(c++).fill = f[0];
				for (i = 0; i < 2; i++) row.getCell(c++).fill = f[1];
				for (i = 0; i < 2; i++) row.getCell(c++).fill = f[0];
			}
			return row;
		};

		return {
			columns: columns,
			products_count: products.length,
			add_row: add_row,
		};
	}

	static write(wb: Workbook, pl: Plan, env: SimulationEnv) {
		const ws = wb.addWorksheet('일별수익률');

		const products = pl.products();
		const doc = XlsxPlanDailySheet.prepare_doc_structure(ws, products, env);

		doc.columns.forEach((column, idx) => {
			column.style.border = s.border.tb;
			ws.getColumn(idx+1).width = column.width;
			ws.getColumn(idx+1).style = column.style;
		});

		let r = 1;

		doc.add_row(s.fills1, doc.columns.map(c => c.name));
		r++;

		const L = function (c: number) {
			return column_to_letter(c < 1 ? doc.columns.length + c : c);
		}

		const interest_column = env.interest_rate ? 1 : 0;
		const product_base_column = 5 + interest_column;

		pl.get_dailies().forEach((d, i) => {
			const row = [
				d.date.format_ymd(),
				d.money,
				{formula: 'B'+r+'+D'+r+'-'+L(-3)+r},
				{formula: L(-8)+r +'-'+ L(-4)+r},
			];

			if (interest_column) {
				row.push(d.interest_rate);
			}

			products.forEach((product, index) => {
				const volume_detail = d.volume_details[product.code];
				return row.push(
					volume_detail.volume,
					volume_detail.avg_price,
					volume_detail.tick,
					{formula: L(product_base_column + index*4)+r +'*'+ L(product_base_column + index*4 + 2)+r +'/'+ L(-14)+r},
				);
			});
			row.push(
				{formula: products.reduce((formula, product, index) => {
					return formula + (index ? '+' : '') +
						L(product_base_column + index*4)+r +'*'+ L(product_base_column + index*4 + 1)+r;
				}, '')},
				{formula: products.reduce((formula, product, index) => {
					return formula + (index ? '+' : '') +
						L(product_base_column + index*4)+r +'*'+ L(product_base_column + index*4 + 2)+r;
				}, '')},
				{formula: 'C'+r +'+'+ L(-15)+r},
				d.invest,
				{formula: L(-11)+r +'/'+ L(-13)+r},
				{formula: L(-14)+r +'-'+ L(-13)+r},
				d.sold_profit,
				{formula: L(-15)+r +'-'+ L(-16)+r +'+' +L(-2)+r},
				d.interest_ernings, d.dividend_ernings, d.fee, d.tax, d.oper_cost,
				d.applied_other_money || 0,
				d.applied_estimated_profit || 0,
			);
			if (i === 0) {
				row.push(
					{formula: L(-13)+r},
					{formula: L(-14)+r+'/'+L(-1)+r+'*1000'},
				);
			} else {
				row.push(
					{formula: L(-1)+(r-1)+'+('+L(-13)+r+'-'+L(-13)+(r-1)+')/'+L(0)+(r-1)+'*1000'},
					{formula: L(-14)+r+'/'+L(-1)+r+'*1000'},
				);
			}
			doc.add_row(undefined, row);
			r++;
		});
	}
}
