import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";
import { CSSProperties, useState } from "react";

let seq = 0;

export interface CheckboxLabelProps {
	checked?: boolean;
	label?: string;
	disabled?: boolean;
	onChange?: (e: {checked: boolean}) => void;
	className?: string;
	style?: CSSProperties;
}
export function CheckboxLabel({checked, label, disabled, onChange, className, style}: CheckboxLabelProps) {
	const [inputId] = useState(() => `checkboxlabel${seq++}`);

	return (
		<div className={className} style={style}>
			<Checkbox inputId={inputId} checked={!!checked} disabled={disabled} onChange={e => onChange?.({checked: !!e.checked})} />
			<label className={classNames('ml-2 inline', {'text-500': disabled})} htmlFor={inputId}>{label}</label>
		</div>
	);
}