import { MatrixPlans, TermResult } from '../matrix';
import { Plan } from '../plans/Plan';
import { Deal } from '../plans/sheets';
import { SimulationEnv } from '../SimulationEnv';
import { Term } from '../terms';
import { Written } from '../../xlsx-writer';
import { XlsxMatrixWriter } from './XlsxMatrixWriter';
import { XlsxMultitermsWriter } from './XlsxMultitermsWriter';
import { XlsxPlanWriter } from './XlsxPlanWriter';

export interface Daily {
	date: Date;
	tick: number;
	ticks: {[product_code:string]: number};
	money: number;
	invest: number;
	volume: number;
	avg_price: number;
	sold_profit: number;
	interest_rate: number;
	interest_ernings: number;
	dividend_ernings: number;
	fee: number;
	tax: number;
	oper_cost: number;
	volume_details: {[product_code:string]: {tick:number, avg_price:number, volume:number}};
	volume_original_value: number;
	volume_estimated_value: number;
	applied_other_money: number;
	applied_estimated_profit: number;
}


export interface Contract {
	date: Date;
	deal: Deal;
	price: number;
	volume: number;
	flow: number;
	fee: number;
	tax: number;
	deal_margin: number;
	after: SimpleAssetsStat;
}


export interface SimpleAssetsStat {
	money: number;
	volume: number;
	lifo_avg_price: number;
	moving_avg_price: number;
}


export interface DividendErning {
	date: Date;
	volumes: number;
	dividend_per_volume: number;
}

export type PlanReporter = (event: string, term: Term, plan: Plan, env: SimulationEnv) => Promise<Written | undefined>;
export type MatrixReporter = (event: string, matrix_plans: MatrixPlans, env: SimulationEnv) => Promise<Written | undefined>;
export type TermsReporter = (event: string, term_results: TermResult[] | undefined, env: SimulationEnv) => Promise<Written | undefined>;

export interface Reporters {
	plan_reporter?: PlanReporter;
	matrix_reporter?: MatrixReporter;
	terms_reporter?: TermsReporter;
}

export function createReporters(name: string, obj: any): Reporters {
	const prefix = name.replace(/[ /]/g,'_') + '-';
//		console.log('name', name, '-->', prefix);
	const reporters: Reporters = {};
	if (obj.plan_report) {
		reporters.plan_reporter = async (event: string, term: Term, plan: Plan, env: SimulationEnv) => {
			if (event === 'end') {
				const filename = `${prefix}${plan.id}-${term.begin}~${term.end}.xlsx`;
				return XlsxPlanWriter.write(plan, env, filename);
			}
		}
	}
	if (obj.matrix_report) {
		reporters.matrix_reporter = async (event: string, matrix_plans: MatrixPlans, env: SimulationEnv) => {
			const term = matrix_plans.term;
			if (event === 'end') {
				const filename = `${prefix}matrix-${term.begin}~${term.end}.xlsx`;
				return XlsxMatrixWriter.write(matrix_plans, env, filename);
			}
		};
	}
	if (obj.terms_report) {
		reporters.terms_reporter = async (event: string, term_results: TermResult[] | undefined, env: SimulationEnv) => {
			if (event === 'end' && term_results) {
				const filename = `${prefix}terms.xlsx`;
				return XlsxMultitermsWriter.write(term_results, filename, env);
			}
		};
	}
	return reporters;
}
