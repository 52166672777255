import { ProductPlanSpec } from "../request";
import { Product } from "./Product";

export class ETF extends Product {
	static MARKET_CODE = 'ETF';
	protected _margin_tax_rate: number;

	constructor(code: string, name: string, margin_tax_rate?: number) {
		super(code, name, 'KOSPI');
		this._margin_tax_rate = margin_tax_rate || 0;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	get_punit(price: number): number {
		return 5;
	}

	get margin_tax_rate() {
		return this._margin_tax_rate;
	}

	get market_code(): string { return ETF.MARKET_CODE; }
}

export class KOSPI extends Product {
	static MARKET_CODE = 'KOSPI';
	static SELL_TAX_RATE = 0.002;

	constructor(code: string, name: string) {
		super(code, name, 'KOSPI');
	}

	get_punit(price: number): number {
		if (price < 1000) return 1;
		if (price < 5000) return 5;
		if (price < 10000) return 10;
		if (price < 50000) return 50;
		if (price < 100000) return 100;
		if (price < 500000) return 500;
		return 1000;
	}

	get sell_tax_rate() {
		return KOSPI.SELL_TAX_RATE;
	}

	get market_code(): string { return KOSPI.MARKET_CODE; }
}

export class KOSDAQ extends Product {
	static MARKET_CODE = 'KOSDAQ';
	static SELL_TAX_RATE = 0.002;

	constructor(code: string, name: string) {
		super(code, name, 'KOSPI');
	}

	get_punit(price: number): number {
		if (price < 1000) return 1;
		if (price < 5000) return 5;
		if (price < 10000) return 10;
		if (price < 50000) return 50;
		return 100;
	}

	get sell_tax_rate() {
		return KOSDAQ.SELL_TAX_RATE;
	}

	get market_code(): string { return KOSDAQ.MARKET_CODE; }
}

export class Money extends Product {
	constructor() {
		super('MONEY', '현금');
	}

	get_punit() {
		return 1;
	}

	toString() {
		return this.name;
	}

	get market_code(): string { return 'MONEY'; }
}

export const MONEY = new Money();

export function createProduct(plan: ProductPlanSpec): Product {
	switch (plan.product_market) {
	case 'KOSPI':
		return new KOSPI(plan.product_code, plan.product_name || plan.product_code);

	case 'KOSDAQ':
		return new KOSDAQ(plan.product_code, plan.product_name || plan.product_code);

	case 'ETF':
		if (!plan.etf_tax_type || plan.etf_tax_type === '비과세' || plan.etf_tax_type.includes('해당없음')) {
			return new ETF(plan.product_code, plan.product_name || plan.product_code);
		} else {
			return new ETF(plan.product_code, plan.product_name || plan.product_code, 0.154);
		}

	default:
		throw new Error("Wrong market_code " + plan.product_market);
	}
}