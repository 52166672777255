import axios from 'axios';

export interface DailyValue {
	date: string;
	value: number;
}
interface InterestsResponse {
	data: DailyValue[];
	next_key?: string;
}

export async function queryInterests(code: string, begin_date: string, end_date: string, list: DailyValue[]): Promise<DailyValue[]> {
	try {
		const resp = await axios.get('/data/interest/daily', {
			params: { code, start_date: begin_date, end_date, compress: true }
		})
		const r = resp.data as InterestsResponse;
	
		const merged_list = list.concat(r.data);
		if (r.next_key) {
			//console.log('next_key = ', res.next_key);
			return queryInterests(code, r.next_key, end_date, merged_list);
		} else {
			//console.log('fech end');
			return merged_list;
		}

	} catch (err: any) {
		const response = err?.response;
		if (response) {
			if (response.data?.message) {
				throw new Error(response.data?.message);
			}
			if (response.data) {
				throw new Error(response.data);
			}
		}
		console.log("error", err);
		throw err;
	}
}
