import { isNumber, last, reduce } from 'lodash';

import { DayTick, fetchTicks, SingleTick } from '../../query-ticks';
import { Asset, Assets, Money, SharedMoney } from '../assets';
import { MONEY, Product } from '../product';
import { Daily } from '../reports';
import { SimulationEnv } from '../SimulationEnv';
import { Term } from '../terms';
import { WoldStub } from './wold-stub';
import { AutochaStub } from './autocha-stub';

export interface PlanOptions {
	invest: number | Assets;
	term: Term;
	tags?: string[];
	id?: string;
	stop_date?: Date;
	oper_cost_base_amount?: number;
	seq_as_children?: number;
}
export abstract class Plan {
	static INITIAL_RIGHT_SHARES_PRICE = 1000; // 최초 권리좌수당 가격은 1000으로 한다.

	initial_invest: Assets;
	post_invest: Assets;
	term: Term;
	tags: string[] | undefined;
	assets: Assets;
	id: string | undefined;
	stop_date: string | undefined;
	oper_cost_base_amount: number | undefined;
	oper_cost: number;
	seq_as_children: number | undefined;
	right_shares_count: number; // 권리좌수
	env: SimulationEnv;

	constructor(options: PlanOptions, env: SimulationEnv) {
		const invest = options.invest;
		if (isNumber(invest)) {
			this.assets = new Assets(new Money(invest));
		} else if (invest instanceof Assets) {
			this.assets = invest.copy();
		} else throw new Error("wrong type of PlanOptions.invest");

		this.initial_invest = this.assets.clone();
		this.post_invest = new Assets();
		this.term = options.term;
		this.tags = options.tags;
		this.id = options.id;
		this.stop_date = options.stop_date?.format_ymd();
		this.oper_cost_base_amount = options.oper_cost_base_amount;
		this.seq_as_children = options.seq_as_children;
		this.oper_cost = 0;
		this.right_shares_count = this.initial_invest.value / Plan.INITIAL_RIGHT_SHARES_PRICE;
		this.env = env;
	}

	get money(): Asset {
		const money = this.assets.find(MONEY);
		if (!money) throw new Error("Asset 'money' should be defined");
		return money;
	}

	get use_shared_money(): boolean {
		return (this.money instanceof SharedMoney);
	}

	get all_invest_value(): number {
		return this.initial_invest.value + this.post_invest.value;
	}

	abstract products(): Product[];

	has_tag(tag: string): boolean {
		return this.tags ? this.tags.includes(tag) : false;
	}

	async start(): Promise<void> {
		console.log(`plan{id=${this.id}}.start()`);
		const codes = this.products().map(p => p.code);
		let dayticks = await fetchTicks(codes, this.term.begin, this.term.end)
		dayticks = this.ensure_end_day_tick(dayticks);

		await this.beforeFeedDayTicks(dayticks);

		// 성능을 위해 매매테이블 원격 생성 요청을 bulk로 묶어놓은 것을
		// 실제로 실행하고 결과를 배분한다.
		await WoldStub.executeDeferredRequests();
		await AutochaStub.executeDeferredRequests();

		const lastTick = await this.feedDayTicks(dayticks);
		if (lastTick) {
			this.onEndOfTick(lastTick);
		}
	}

	// 마지막 날짜가 휴일이면 최종가격으로 마지막 날짜의 DayTick을 추가한다.
	private ensure_end_day_tick(dayticks: DayTick[]): DayTick[] {
		const lastDayTick = last(dayticks);
		if (!lastDayTick) throw new Error("Empty dayticks");

		if (lastDayTick.date < this.term.end) {
			const endDayTick: DayTick = {
				date: this.term.end,
				ticksByCodeMap: reduce(lastDayTick.ticksByCodeMap, (ticksByCodeMap, ticks, code) => {
					const lastTick = last(ticks)!;
					ticksByCodeMap[code] = [lastTick, lastTick];
					return ticksByCodeMap;
				}, {} as DayTick['ticksByCodeMap'])
			};
			dayticks.push(endDayTick);
		}
		return dayticks;
	}

	private static readonly FEED_DAYTICKS_STEP = 20;

	abstract beforeFeedDayTicks(dayticks: DayTick[]): Promise<void>;

	private async feedDayTicks(dayticks: DayTick[]): Promise<SingleTick | null> {
		let lastClosing: SingleTick | null = null;
		for (const daytick of dayticks) {
			lastClosing = await this.onDayTick(daytick, lastClosing)
		}
		return lastClosing;
	}

	abstract onDayTick(daytick: DayTick, lastClosing: SingleTick | null): Promise<SingleTick>;

	private onEndOfTick(closing: SingleTick): void {
		this.update_oper_cost(closing);
	}

	update_oper_cost(closing: SingleTick): void {
		if (this.env.oper_cost_rate) {
			const oper_cost_base_amount = this.oper_cost_base_amount || this.initial_invest.value;
			const month_cost = (oper_cost_base_amount + this.post_invest.value) * this.env.oper_cost_rate / 12;
			const duration = closing.pos.date.monthly_duration_from(Date.from_ymd(this.term.begin));
			this.oper_cost = month_cost * (duration.months + (duration.days / duration.days_last_month!));
		}
	}

	abstract onSingleTick(single: SingleTick): Promise<void>;
	// abstract expect_first_deal(single: SingleTick): Deal;

	abstract get_dailies(): Daily[];

	abstract right_shares_price(): number;

	abstract avg_volume_rate(): number;
}
