import { isEmpty, isNumber, uniq } from 'lodash';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { CSSProperties, useEffect, useState } from 'react';
import { queryItems } from '../../lib/query-items';

import { getPlanTypeName, SimulationPlanSpec } from '../../lib/simulation/request/types';
import { PlanSpecCreateDialog, PlanSpecCreateFormValue } from './PlanSpecCreateDialog';
import { PlanSpecModifyDialog, PlanSpecModifyFormValue } from './PlanSpecModifyDialog';

interface Props {
	value: SimulationPlanSpec[] | null | undefined;
	onChange: (e: {value: SimulationPlanSpec[]}) => void;
	className?: string;
	style?: CSSProperties;
}
export function SimulationPlansForm({value, onChange, className, style}: Props) {
	const [selectedPlans, setSelectedPlans] = useState<SimulationPlanSpec[]>([]);
	const [confirmDeletePlans, setConfirmDeletePlans] = useState<SimulationPlanSpec[]>();
	const [createPlanDialog, setCreatePlanDialog] = useState<PlanSpecCreateFormValue | 'blank'>();
	const [modifyPlanDialog, setModifyPlanDialog] = useState<PlanSpecCreateFormValue | 'blank'>();
	const [, rerender] = useState<object>();

	useEffect(() => {
		if (isEmpty(value)) {
			setSelectedPlans([]);

		} else {
			const effectivePlans = selectedPlans.filter(plan => value!.includes(plan))
			setSelectedPlans(effectivePlans);

			const codes = uniq((value as SimulationPlanSpec[]).map(plan => plan.product_code));
			queryItems(codes)
				.then(items => {
					(value as SimulationPlanSpec[]).forEach(plan => {
						const item = items.find(item => item.code === plan.product_code);
						if (item) {
							plan.product_name = item.name;
							plan.product_market = item.market;
							plan.etf_tax_type = item.etf_tax_type;
						}
					});
					rerender({});
				})
				.catch(err => console.error(err));

		}

	}, [value]);

	const onClickAdd = () => {
		let p: SimulationPlanSpec | undefined;
		if (!isEmpty(selectedPlans)) {
			p = selectedPlans[0];
		} else if (!isEmpty(value)) {
			p = value![0];
		}

		if (p) {
			setCreatePlanDialog({
				type: p.type,
				product_codes: [],
				product_market: p.product_market || 'KOSPI',
				invest: (p as any).invest || null,
				first_rate_p: (p as any).first_rate_p || null,
				range_low_p: (p as any).range_low_p || null,
				range_high_p: (p as any).range_high_p || null,
				margin_p: (p as any).margin_p || null,
				regular_interval: (p as any).regular_interval || null,
				regular_invest: (p as any).regular_invest || null,
				autocha_type: (p as any).autocha_type || null,
			});
		} else {
			setCreatePlanDialog('blank');
		}
	}

	const addPlan = (newPlans: SimulationPlanSpec[]) => {
		if (value) {
			onChange({value: [...value, ...newPlans]});
		} else {
			onChange({value: [...newPlans]});
		}
		setCreatePlanDialog(undefined)
	}

	const onClickModify = (plans: SimulationPlanSpec[]) => {
		if (!isEmpty(plans)) {
			const p = plans[0];
			if (p) {
				setModifyPlanDialog({
					type: p.type,
					product_codes: [],
					product_market: p.product_market || 'KOSPI',
					invest: (p as any).invest || null,
					first_rate_p: (p as any).first_rate_p || null,
					range_low_p: (p as any).range_low_p || null,
					range_high_p: (p as any).range_high_p || null,
					margin_p: (p as any).margin_p || null,
					regular_invest: (p as any).regular_invest || null,
					regular_interval: (p as any).regular_interval || null,
					autocha_type: (p as any).autocha_type || null,
				});
				if (selectedPlans !== plans) {
					setSelectedPlans(plans);
				}
			}
		}
	}

	const modifyPlan = (m: PlanSpecModifyFormValue) => {
		if (!isEmpty(selectedPlans) && !isEmpty(value)) {
			const newValue = value!.map((plan): SimulationPlanSpec => {
				if (selectedPlans.includes(plan)) {
					switch (plan.type) {
					case 'hold':
						return {
							type: 'hold',
							product_code: plan.product_code,
							product_market: m.mod_product_market ? m.product_market : plan.product_market,
							invest: (m.mod_invest ? m.invest : plan.invest) || 0,
						};
					case 'money':
						return {
							type: 'money',
							product_code: 'v:KOSPI',
							product_market: 'KOSPI',
							invest: (m.mod_invest ? m.invest : plan.invest) || 0,
						}
					case 'wave':
						return {
							type: 'wave',
							product_code: plan.product_code,
							product_market: m.mod_product_market ? m.product_market : plan.product_market,
							invest: (m.mod_invest ? m.invest : plan.invest) || 0,
							first_rate_p: (m.mod_first_rate_p ? m.first_rate_p : plan.first_rate_p) || 0,
							range_low_p: (m.mod_range_low_p ? m.range_low_p : plan.range_low_p) || 0,
							range_high_p: (m.mod_range_high_p ? m.range_high_p : plan.range_high_p) || 0,
							margin_p: (m.mod_margin_p ? m.margin_p : plan.margin_p) || 0,
							extend_lower: true,
						};
					case 'autocha':
						return {
							type: 'autocha',
							autocha_type: plan.autocha_type,
							product_code: plan.product_code,
							product_market: m.mod_product_market ? m.product_market : plan.product_market,
							invest: (m.mod_invest ? m.invest : plan.invest) || 0,
						};
					case 'regular-hold':
						return {
							type: 'regular-hold',
							product_code: plan.product_code,
							product_market: m.mod_product_market ? m.product_market : plan.product_market,
							regular_interval: (m.mod_regular_interval ? m.regular_interval : plan.regular_interval) || 1,
							regular_invest: (m.mod_regular_invest ? m.regular_invest : plan.regular_invest) || 0,
						};
					case 'regular-wave':
						return {
							type: 'regular-wave',
							product_code: plan.product_code,
							product_market: m.mod_product_market ? m.product_market : plan.product_market,
							first_rate_p: (m.mod_first_rate_p ? m.first_rate_p : plan.first_rate_p) || 0,
							range_low_p: (m.mod_range_low_p ? m.range_low_p : plan.range_low_p) || 0,
							range_high_p: (m.mod_range_high_p ? m.range_high_p : plan.range_high_p) || 0,
							margin_p: (m.mod_margin_p ? m.margin_p : plan.margin_p) || 0,
							extend_lower: true,
							regular_interval: (m.mod_regular_interval ? m.regular_interval : plan.regular_interval) || 1,
							regular_invest: (m.mod_regular_invest ? m.regular_invest : plan.regular_invest) || 0,
						};
					case 'regular-autocha':
						return {
							type: 'regular-autocha',
							autocha_type: plan.autocha_type,
							product_code: plan.product_code,
							product_market: m.mod_product_market ? m.product_market : plan.product_market,
							regular_interval: (m.mod_regular_interval ? m.regular_interval : plan.regular_interval) || 1,
							regular_invest: (m.mod_regular_invest ? m.regular_invest : plan.regular_invest) || 0,
						};
					}
					throw new Error('This line cannot be reached');
				} else {
					return plan;
				}
			});
			onChange({value: newValue});
		}
		setModifyPlanDialog(undefined)
	}

	const deletePlans = (removingPlans: SimulationPlanSpec[] | undefined) => {
		if (value && removingPlans) {
			const remains = value.filter(plan => !removingPlans.includes(plan))
			onChange({value: remains});
		}
	}

	return (
		<div className={className} style={style}>
			<div>
				<DataTable value={value || []} selectionMode='checkbox'
					selection={selectedPlans} onSelectionChange={e => setSelectedPlans(e.value)}
					onRowDoubleClick={e => onClickModify([e.data as SimulationPlanSpec])}>
					<Column selectionMode="multiple" headerStyle={{ minWidth: '3em' }} />
					<Column field="type" header="조건 유형" body={(data: SimulationPlanSpec) => getPlanTypeName(data)} style={{ minWidth: '8rem' }} />
					<Column header="종목명" body={(data: SimulationPlanSpec) => data.type === 'money' ? '' : data.product_name} style={{ minWidth: '10rem' }} />
					<Column header="종목코드" body={(data: SimulationPlanSpec) => data.type === 'money' ? '' : data.product_code} style={{ minWidth: '6rem' }} />
					<Column header="시장구분" body={(data: SimulationPlanSpec) => data.type === 'money' ? '' : data.product_market} style={{ minWidth: '6rem' }} />
					<Column header="ETF 과세" body={(data: SimulationPlanSpec) => data.type === 'money' ? '' : data.etf_tax_type} style={{ minWidth: '10rem' }} />
					<Column field="invest" header="최초투자금액" body={data => data.invest?.toLocaleString()} style={{ minWidth: '10rem' }} />
					<Column field="first_rate" header="최초비율" body={data => formatPercent(data.first_rate_p)} style={{ minWidth: '6rem' }} />
					<Column header="매매범위" body={data => formatPercentRange([data.range_low_p, data.range_high_p])} style={{ minWidth: '10rem' }} />
					<Column field="margin_p" header="매매차익" body={data => formatPercent(data.margin_p)} style={{ minWidth: '6rem' }} />
					<Column header="적립간격" body={data => data.type.startsWith('regular-') ? `${(data.regular_interval || 1)}개월마다` : ''} style={{ minWidth: '6rem' }} />
					<Column header="1회적립금액" body={data => data.regular_invest?.toLocaleString()} style={{ minWidth: '10rem' }} />
				</DataTable>
			</div>
			<div className="mt-2 flex">
				<div>
					<Button className='p-button-sm p-button-outlined mr-2' label='수정' disabled={isEmpty(selectedPlans)} onClick={() => onClickModify(selectedPlans)} />
					<Button className='p-button-sm p-button-outlined mr-2' label='삭제' disabled={isEmpty(selectedPlans)} onClick={() => setConfirmDeletePlans(selectedPlans)} />
				</div>
				<div className='flex-grow-1 text-right'>
					<Button className='p-button-sm p-button-outlined mr-2' label='추가' onClick={onClickAdd} />
				</div>
			</div>
			<ConfirmDialog visible={!isEmpty(confirmDeletePlans)} onHide={() => setConfirmDeletePlans(undefined)}
				message={`${confirmDeletePlans?.length}개의 조건을 삭제합니다.`}
				header="조건 삭제" icon="pi pi-exclamation-triangle"
				accept={() => deletePlans(confirmDeletePlans)} />
			<PlanSpecCreateDialog style={{width: '40rem'}}
				visible={!!createPlanDialog} initialValue={createPlanDialog || 'blank'}
				onOk={e => addPlan(e.value)} onHide={() => setCreatePlanDialog(undefined)}/>
			<PlanSpecModifyDialog style={{width: '30rem'}}
				visible={!!modifyPlanDialog} initialValue={modifyPlanDialog || 'blank'}
				onOk={e => modifyPlan(e.value)} onHide={() => setModifyPlanDialog(undefined)}/>
		</div>
	);
}

function formatPercent(val: number | null | undefined): string {
	if (isNumber(val)) {
		return val.toString() + '%';
	}
	return '';
}

function formatPercentRange([begin, end]: [number | null | undefined, number | null | undefined]): string {
	if (begin && end) {
		return `${formatPercent(begin)} ~ ${formatPercent(end)}`;
	} else {
		return '';
	}
}
