import { Fill, Workbook, Worksheet } from 'exceljs';
import { flatten, isEmpty, isFunction } from 'lodash';
import { TermResult, TermSummary } from '../matrix';
import { SimulationEnv } from '../SimulationEnv';

import s from '../../xlsx-styles';


interface Description {
	name: string;
	text: string;
}

export class XlsxMultitermsInfoSheet {
	static write(wb: Workbook, term_results: TermResult[], env: SimulationEnv): any {
		const ws = wb.addWorksheet('설정정보');

		let r = 1;
		const descriptions: Description[] = [];

		const font = s.font;
		const right = s.right;

		const columns: {width: number, style: any}[] = [
			{ width:14, style: {font: font} },
			{ width: 7, style: {font: font} },

			{ width: 6, style: {font: font} },
			{ width: 6, style: {font: font, numFmt: '0%', alignment: right} },
			{ width: 6, style: {font: font, numFmt: '0%', alignment: right} },
			{ width: 6, style: {font: font, numFmt: '0%', alignment: right} },
			{ width: 6, style: {font: font, numFmt: '0.0%', alignment: right} },
			{ width: 6, style: {font: font, numFmt: '0%', alignment: right} },
			{ width: 6, style: {font: font, alignment: right} },
			{ width:10, style: {font: font, numFmt: '#,##0', alignment: right} },
		];
		columns.forEach((column, idx) => {
			const col = ws.getColumn(idx+1);
			col.width = column.width;
			col.style = column.style;
		});

		const add_row = (f: Fill[], values: any[]) => {
			const tb = s.border.tb;
			const row = ws.addRow(values);

			let i = 1, c;
			c = row.getCell(i++); c.fill = f[0]; c.border = tb;
			c = row.getCell(i++); c.fill = f[0]; c.border = tb;
			c = row.getCell(i++); c.fill = f[1]; c.border = tb;
			c = row.getCell(i++); c.fill = f[1]; c.border = tb;
			c = row.getCell(i++); c.fill = f[1]; c.border = tb;
			c = row.getCell(i++); c.fill = f[1]; c.border = tb;
			c = row.getCell(i++); c.fill = f[1]; c.border = tb;
			c = row.getCell(i++); c.fill = f[1]; c.border = tb;
			c = row.getCell(i++); c.fill = f[1]; c.border = tb;
			c = row.getCell(i++); c.fill = f[1]; c.border = tb;

			return row;
		};


		add_row(s.fills1, [
			'ID', '종목코드',
			'투자방식', '최초비율', '하한가격', '상한가격', '매매차익', '2차조건', '리밸런싱', '총투자금액']);
		r++;


		const write_plan_row = (summary: TermSummary, f: Fill[]) => {
			const d: any = {
				id: summary.id,
				invest: summary.invest_value,
				invest_way: summary.invest_way,
				first_rate: summary.first_rate,
				range_low: summary.range_low,
				range_high: summary.range_high,
				margin_rate: summary.margin_rate,
				cumul_rate: summary.cumul_rate,
			};

			const products = summary.products;
			if (products.length > 1) {
				d.product = products.length + '종목';
			} else {
				d.product = products[0].code;
			}

			// if (summary.rebalance_period_months) {
			// 	d.rebal_period = summary.rebalance_period_months + '개월'
			// } else if (summary.rebalance_period_custom) {
			// 	d.rebal_period = summary.rebalance_period_custom.name || 'CUSTOM';
			// }

			add_row(f, [
				d.id || '',
				d.product,
				d.invest_way || '',
				d.first_rate || '',
				d.range_low || '',
				d.range_high || '',
				d.margin_rate || '',
				d.cumul_rate || '',
				d.rebal_period || '',
				d.invest,
			]);
			r++;
		};

		flatten(term_results[0].summaries).forEach(summary => {
			write_plan_row(summary, s.fills0);
		});

		ws.addRow([]); r++;
		XlsxMultitermsInfoSheet.write_desciptions(ws, env, r, descriptions);
	}

	static write_desciptions(ws: Worksheet, env: SimulationEnv, desc_r: number, descriptions: Description[]) {
		const desc_font = {size: 8, italic: true, color: {argb: 'FF666666'}};

		let c;

		if (!isEmpty(descriptions)) {
			descriptions.forEach(desc => {
				ws.addRow([desc.name + ': ', desc.text]);
				c = ws.getCell('A'+desc_r);
				c.font = desc_font; c.alignment = s.right;
				c = ws.getCell('B'+desc_r);
				c.font = desc_font;
				desc_r++;
			});

			ws.addRow([]); desc_r++;
		}

		let r = desc_r;

		const interest_rate = env.interest_rate;
		let interest_rate_string = '없음';
		if (interest_rate) {
			interest_rate_string = isFunction(interest_rate) ? 'CD' : interest_rate;
		}
		ws.addRow(['금리', interest_rate_string]);
		c = ws.getCell('A'+r);
		c.font = desc_font; c.alignment = s.right;
		c = ws.getCell('B'+r);
		c.font = desc_font; c.alignment = s.right; c.numFmt = '0.00%';
		r++;

		ws.addRow(['매매수수료율', env.trade_fee_rate ? env.trade_fee_rate : '없음']);
		c = ws.getCell('A'+r);
		c.font = desc_font; c.alignment = s.right;
		c = ws.getCell('B'+r);
		c.font = desc_font; c.alignment = s.right; c.numFmt = '0.00%';
		r++;

		ws.addRow(['매도세율', .003, '(매매수수료에 합산, ETF 종목은 없음)']);
		c = ws.getCell('A'+r);
		c.font = desc_font; c.alignment = s.right;
		c = ws.getCell('B'+r);
		c.font = desc_font; c.alignment = s.right; c.numFmt = '0.00%';
		c = ws.getCell('C'+r);
		c.font = desc_font;
		r++;

		ws.addRow(['운영보수료율', env.oper_cost_rate ? env.oper_cost_rate : '없음']);
		c = ws.getCell('A'+r);
		c.font = desc_font; c.alignment = s.right;
		c = ws.getCell('B'+r);
		c.font = desc_font; c.alignment = s.right; c.numFmt = '0.00%';
		r++;
	}
}
