import React from 'react';
import { classNames } from 'primereact/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';

interface Props {
	visible?: boolean;
	onHide: () => void;
}

export function NavBar({visible, onHide}: Props) {
	const location = useLocation();
	const navigate = useNavigate();

	const menu = [
		{id: 'simulation', text: '시뮬레이션', icon: "pi pi-compass", url: '/'},
		{id: 'fund', text: '펀드 기준가', icon: "pi pi-database", url: '/fund'},
	];

	const links = menu.map(({id, text, icon, url}) => {
		const current = location.pathname === url || location.pathname.startsWith(url + '/');
		return (
			<li key={id}
				className={classNames(current && "active")}
				onClick={() => navigate(url)}
			>
				<span className='icon'><i className={icon} style={{fontSize: '1.25rem'}}></i></span>
				<span className='text'>{text}</span>
			</li>
		);
	});
	
	return (
		<Sidebar className='nav-sidebar' visible={visible} onHide={() => onHide()}>
			<ul className='menuitems'>
				{links}
			</ul>
		</Sidebar>
	);
}
