export enum Deal { NONE, BUY, SELL, DIVIDEND }

export interface Sheet {
	no: number;
	bprc: number;
	bvol: number;
	bvol_size: number;
	sprc: number;
	svol: number;
	svol_size: number;
	org_bvol_size?: number;
	org_svol_size?: number;
	cumul_occur?: number;
}

export interface Sheets {
	nearest_buy(): Sheet | undefined;
	nearest_sell(): Sheet | undefined;
	list(): Sheet[];
	on_matched(deal: Deal, cvol: number, sheet: Sheet): void;
}
