import { assign, first, isNumber, reduce, sumBy } from 'lodash';

import { DayTick, SingleTick } from '../../query-ticks';
import { Assets, Money } from '../assets';
import { Product } from '../product';
import { Daily } from '../reports';
import { SimulationEnv } from '../SimulationEnv';
import { Plan } from './Plan';
import { PlanComposite, PlanCompositeOptions } from './PlanComposite';
import { PlanSingle } from './PlanSingle';

export interface PlanCreator {
	(options: any): Plan;
}

export interface SingleProduct {
	first_tick(): SingleTick | undefined;
	last_tick(): SingleTick | undefined;
	sum_volume_amount(): number;
	sum_volume_cost(): number;
}

export interface PlanRegularSavingsOptions extends PlanCompositeOptions {
	regular_interval: number;
	regular_invest: number | Assets;
	regular_plan_creator: PlanCreator;
}

export class PlanRegularSavings extends PlanComposite {
	regular_interval: number;
	regular_invest: Assets;
	regular_plan_creator: PlanCreator;
	regular_begin_dates: Date[];
	regular_premade_subplans: Plan[] = [];
	protected _assumed_products: Product[];
	protected _sum_volume_rate: number;
	protected _num_of_days: number;

	constructor(o: PlanRegularSavingsOptions, env: SimulationEnv) {
		super(o, env);

		const regular_invest = o.regular_invest;
		if (isNumber(regular_invest)) {
			this.regular_invest = new Assets(new Money(regular_invest));
		} else if (regular_invest instanceof Assets) {
			this.regular_invest = regular_invest.clone();
		} else throw new Error("wrong type of regular_invest");

		this.regular_interval = o.regular_interval;
		this.regular_plan_creator = o.regular_plan_creator;
		this.regular_begin_dates = Date.range(Date.from_ymd(o.term.begin), Date.from_ymd(o.term.end), {months: o.regular_interval || 1, days: 0});
		this._assumed_products = this.assume_products();
		this._sum_volume_rate = 0;
		this._num_of_days = 0;
	}

	private assume_products() {
		const tried_plan = this.regular_plan_creator({
			invest: this.regular_invest,
			seq_as_children: 0,
			term: this.term
		});
		return tried_plan.products();
	}

	products(): Product[] {
		return this._assumed_products;
	}

	async beforeFeedDayTicks(dayticks: DayTick[]): Promise<void> {
		const subplans = this.createRegularSubplansForEachBeginDate(this.regular_begin_dates, dayticks);
		for (const subplan of subplans) {
			const dayticksForSubplan = dayticks.filter(daytick => daytick.date >= subplan.term.begin && daytick.date <= subplan.term.end);
			await subplan.beforeFeedDayTicks(dayticksForSubplan);
		}

		this.regular_premade_subplans = subplans;
		console.log('regular subplans 갯수:', this.regular_premade_subplans.length);
	}

	private createRegularSubplansForEachBeginDate(beginDates: Date[], dayticks: DayTick[]): Plan[] {
		const regular_begin_dates = [...beginDates];
		const subplans: Plan[] = [];
		for (const daytick of dayticks) {
			const begin_date = first(regular_begin_dates);
			if (begin_date && daytick.date >= begin_date.format_ymd()) {
				const newSubplan = this.createRegularSubplan(regular_begin_dates.shift()!);
				subplans.push(newSubplan);
			}
		}
		return subplans
	}

	private createRegularSubplan(begin_date: Date): Plan {
		const options:any = {
			seq_as_children: this.subplans.length + 1,
			invest: this.regular_invest,
			term: {begin: begin_date.format_ymd(), end: this.term.end}
		};

		if (options.seq_as_children == 1 && this.initial_invest) {
			options.invest = new Assets(options.invest, this.initial_invest);
			options.oper_cost_base_amount = this.regular_invest.value + (this.oper_cost_base_amount || 0);
		}
		return this.regular_plan_creator(options);
	}

	async onDayTick(daytick: DayTick, lastClosing: SingleTick | null): Promise<SingleTick> {
		const maybeNewSubplan = first(this.regular_premade_subplans);
		if (maybeNewSubplan && daytick.date >= maybeNewSubplan.term.begin) {
			const newSubplan = this.regular_premade_subplans.shift()!;
			this.addNewRegularPlan(newSubplan);
		}

		const closings = await Promise.all(this._subplans.map(subplan => {
			return subplan.onDayTick(daytick, lastClosing);
		}));

		this.onEndOfDay();
		return first(closings)!;
	}

	private addNewRegularPlan(newSubplan: Plan) {
		this.post_invest.push(this.regular_invest.clone()); // TODO newSubplan에 입력한 invest로 바꿔야 함
		this.right_shares_count += (this.regular_invest.value / this.right_shares_price());
		this.subplans.push(newSubplan);
	}

	get_dailies(): Daily[] {
		const array_of_dailies: Daily[][] = this.subplans.map(subplan => subplan.get_dailies());
		const dates = first(array_of_dailies)!.map(daily => daily.date);

		const initial_invest_value = this.initial_invest.value;
		const regular_invest_value = this.regular_invest.value;
		const single_product = this.products().length === 1;

		return dates.reduce((result, date) => {
			const date_dailies = array_of_dailies.reduce((date_dailies, dailies) => {
				const daily = first(dailies);
				if (daily?.date.getTime() === date.getTime()) date_dailies.push(dailies.shift()!);
				return date_dailies;
			}, []);
			const regular_subplan_count = date_dailies.length;
			const post_invest_value = regular_invest_value * regular_subplan_count;

			const d = date_dailies.reduce((d, date_daily) => {
				assign(d.ticks, date_daily.ticks);
				d.money += date_daily.money;
				d.sold_profit += date_daily.sold_profit;
				d.interest_ernings += date_daily.interest_ernings;
				d.dividend_ernings += date_daily.dividend_ernings;
				d.fee += date_daily.fee;
				d.tax += date_daily.tax;
				d.oper_cost += date_daily.oper_cost;
				d.applied_other_money += date_daily.applied_other_money;
				d.applied_estimated_profit += date_daily.applied_estimated_profit;
				d.volume_original_value += date_daily.volume_original_value;
				d.volume_estimated_value += date_daily.volume_estimated_value;

				d.volume_details = reduce(date_daily.volume_details, (detail_collection, detail, product_code) => {
					const already_detail = detail_collection[product_code];
					if (already_detail) {
						const volume = already_detail.volume + detail.volume;
						if (volume) {
							already_detail.avg_price = (already_detail.volume * already_detail.avg_price + detail.volume * detail.avg_price) / volume;
						} else {
							already_detail.avg_price = 0;
						}
						already_detail.volume = volume;
					} else {
						detail_collection[product_code] = {...detail};
					}
					return detail_collection;
				}, d.volume_details);

				if (single_product) {
					d.avg_price = (d.avg_price*d.volume + date_daily.avg_price*date_daily.volume) / (d.volume + date_daily.volume);
					d.volume += date_daily.volume;
				}
				return d;
			}, {
				date: date,
				invest: initial_invest_value + post_invest_value,
				ticks: {},
				money: 0,
				sold_profit: 0,
				interest_rate: first(date_dailies)!.interest_rate,
				interest_ernings: 0,
				dividend_ernings: 0,
				fee: 0,
				tax: 0,
				oper_cost: 0,
				applied_other_money: 0,
				applied_estimated_profit: 0,
				volume_details: {},
				volume_original_value: 0,
				volume_estimated_value: 0,
				tick: single_product ? first(date_dailies)!.tick : undefined,
				volume: single_product ? 0 : undefined,
				avg_price: single_product ? 0 : undefined,
			} as Daily);

			result.push(d);
			return result;
		}, [] as Daily[]);
	}

	for_single_product(): SingleProduct | null {
		const products = this.products();
		if (products.length === 1) {
			return {
				first_tick: (): SingleTick | undefined => {
					const subplan = this._subplans[0];
					if (subplan instanceof PlanSingle) {
						return subplan.first_tick;
					} else if (subplan instanceof PlanComposite){
						return subplan.for_single_product()?.first_tick();
					}
				},
				last_tick: (): SingleTick | undefined => {
					const subplan = this._subplans[0];
					if (subplan instanceof PlanSingle) {
						return subplan.last_tick;
					} else if (subplan instanceof PlanComposite){
						return subplan.for_single_product()?.last_tick();
					}
				},
				sum_volume_amount: (): number => {
					return sumBy(this._subplans, subplan => {
						if (subplan instanceof PlanSingle) {
							return subplan.volume.amount;
						} else if (subplan instanceof PlanComposite){
							return subplan.for_single_product()?.sum_volume_amount() || 0;
						} else throw new Error("Wrong type of subplan")
					});
				},
				sum_volume_cost: (): number => {
					return sumBy(this._subplans, subplan => {
						if (subplan instanceof PlanSingle) {
							return subplan.volume.cost;
						} else if (subplan instanceof PlanComposite){
							return subplan.for_single_product()?.sum_volume_cost() || 0;
						} else throw new Error("Wrong type of subplan")
					});
				},
			};
		} else {
			return null;
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private onEndOfDay(): void {
		const sum_volume_value = this.sum_volume_value();
		const sum_pure_money_value = this.sum_pure_money_value();
		this._sum_volume_rate += sum_volume_value / (sum_volume_value + sum_pure_money_value);
		this._num_of_days++;
	}

	right_shares_price(): number {
		if (this.right_shares_count > 0) {
			return this.sum_value() / this.right_shares_count;
		} else {
			return PlanComposite.INITIAL_RIGHT_SHARES_PRICE;
		}
	}

	avg_volume_rate(): number {
		return this._sum_volume_rate / this._num_of_days;
	}
}
