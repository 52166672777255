import { sumBy } from 'lodash';

import { DayTick, SingleTick } from '../../query-ticks';
import { Plan, PlanOptions } from './Plan';
import { SingleProduct } from './PlanRegularSavings';
import { PlanSingle } from './PlanSingle';


export type PlanCompositeOptions = PlanOptions;

export abstract class PlanComposite extends Plan {

	protected _subplans: Plan[] = [];

	get subplans(): Plan[] {
		return this._subplans;
	}

	async beforeFeedDayTicks(dayticks: DayTick[]): Promise<void> {
		this.subplans.forEach(subplan => subplan.beforeFeedDayTicks(dayticks));
	}

	async onSingleTick(single: SingleTick): Promise<void> {
		for (const subplan of this._subplans) {
			await subplan.onSingleTick(single);
		}
	}

	sum_sold_profit(): number {
		return sumBy(this._subplans, subplan => {
			if (subplan instanceof PlanSingle) {
				return subplan.sold_profit;
			} else if (subplan instanceof PlanComposite) {
				return subplan.sum_sold_profit();
			}
			throw new Error("wrong type of Plan");
		});
	}

	sum_volume_profit(): number {
		return sumBy(this._subplans, subplan => {
			if (subplan instanceof PlanSingle) {
				return subplan.volume.profit;
			} else if (subplan instanceof PlanComposite) {
				return subplan.sum_volume_profit();
			}
			throw new Error("wrong type of Plan");
		});
	}

	sum_volume_value(): number {
		return sumBy(this._subplans, subplan => {
			if (subplan instanceof PlanSingle) {
				return subplan.volume.value;
			} else if (subplan instanceof PlanComposite) {
				return subplan.sum_volume_value();
			}
			throw new Error("wrong type of Plan");
		});
	}

	sum_interest_ernings(): number {
		return sumBy(this._subplans, subplan => {
			if (subplan instanceof PlanSingle) {
				return subplan.interest_ernings;
			} else if (subplan instanceof PlanComposite) {
				return subplan.sum_interest_ernings();
			}
			throw new Error("wrong type of Plan");
		});
	}

	sum_dividend_ernings(): number {
		return sumBy(this._subplans, subplan => {
			if (subplan instanceof PlanSingle) {
				return subplan.dividend_ernings;
			} else if (subplan instanceof PlanComposite) {
				return subplan.sum_dividend_ernings();
			}
			throw new Error("wrong type of Plan");
		});
	}

	sum_fee(): number {
		return sumBy(this._subplans, subplan => {
			if (subplan instanceof PlanSingle) {
				return subplan.fee;
			} else if (subplan instanceof PlanComposite) {
				return subplan.sum_fee();
			}
			throw new Error("wrong type of Plan");
		});
	}

	sum_tax(): number {
		return sumBy(this._subplans, subplan => {
			if (subplan instanceof PlanSingle) {
				return subplan.tax;
			} else if (subplan instanceof PlanComposite) {
				return subplan.sum_tax();
			}
			throw new Error("wrong type of Plan");
		});
	}

	sum_oper_cost(): number {
		return sumBy(this._subplans, subplan => {
			if (subplan instanceof PlanSingle) {
				return subplan.oper_cost;
			} else if (subplan instanceof PlanComposite) {
				return subplan.sum_oper_cost();
			}
			throw new Error("wrong type of Plan");
		});
	}

	sum_sold_cost(): number {
		return sumBy(this._subplans, subplan => {
			if (subplan instanceof PlanSingle) {
				return subplan.sold_cost;
			} else if (subplan instanceof PlanComposite) {
				return subplan.sum_sold_cost();
			}
			throw new Error("wrong type of Plan");
		});
	}

	sum_trade_amount(): number {
		return sumBy(this._subplans, subplan => {
			if (subplan instanceof PlanSingle) {
				return subplan.trade_amount;
			} else if (subplan instanceof PlanComposite) {
				return subplan.sum_trade_amount();
			}
			throw new Error("wrong type of Plan");
		});
	}

	sum_pure_money_value(): number {
		return sumBy(this._subplans, subplan => {
			if (subplan instanceof PlanSingle) {
				return subplan.pure_money_value;
			} else if (subplan instanceof PlanComposite) {
				return subplan.sum_pure_money_value();
			}
			throw new Error("wrong type of Plan");
		});
	}

	sum_value(): number {
		return sumBy(this._subplans, subplan => {
			if (subplan instanceof PlanSingle) {
				return subplan.value;
			} else if (subplan instanceof PlanComposite) {
				return subplan.sum_value();
			}
			throw new Error("wrong type of Plan");
		});
	}

	sum_all_invest_value(): number {
		return sumBy(this._subplans, subplan => {
			if (subplan instanceof PlanSingle) {
				return subplan.all_invest_value;
			} else if (subplan instanceof PlanComposite) {
				return subplan.sum_all_invest_value();
			}
			throw new Error("wrong type of Plan");
		});
	}

	abstract for_single_product(): SingleProduct | null;
}
