import { classNames } from 'primereact/utils';
import './SelectList.scss';
import React, { useEffect } from "react";

export type SelectItem<T> = { id: T, label: string, data: any };

interface SelectListProps<T> {
	list: SelectItem<T>[];
	mode: 'single' | 'multiple';
	selection: T[];
	disabled?: boolean;
	appearance?: 'bar' | 'fadeout';
	horizontal?: boolean;
	showCheckIcon?: boolean;
	className?: string;
	onChange?: (event: {selection: T[]}) => void;
}

export function SelectList<T>({ list, mode, selection, disabled, appearance, horizontal, showCheckIcon, className, onChange }: SelectListProps<T>) {
	useEffect(() => {
		if (mode === 'single' && selection.length > 1) {
			console.log('single force', selection[0]);
			onChange?.({ selection: [selection[0]] });
		}
	}, [mode]);

	const handleItemClick = (item: SelectItem<T>) => {
		if (disabled) return;

		if (mode === 'single') {
			onChange?.({ selection: [item.id] });
		} else if (mode === 'multiple') {
			let newSelection: T[];
			if (selection.includes(item.id)) {
				newSelection = selection.filter(id => id !== item.id);
			} else {
				newSelection = [...selection, item.id];

				// 옵션 목록 순서대로 정렬
				newSelection = list.filter(item => newSelection.includes(item.id)).map(item => item.id);
			}
			onChange?.({ selection: newSelection });
		}
	};

	const children = list.map(item => {
		const selected = selection.includes(item.id);
		return <li key={item.id + ''} className={classNames({selected})} onClick={_ => handleItemClick(item)}>
			{showCheckIcon !== false && <span className="icon"><CheckIcon/></span>}
			<span className="label">{item.label}</span>
		</li>;
	});

	return <ul className={classNames("SelectList", `appearance-${appearance || 'bar'}`, horizontal && 'horizontal', className, disabled && 'disabled')}>
		{children}
	</ul>;
}

function CheckIcon() {
	return <i className="pi pi-check" />;
}
